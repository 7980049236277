import React from 'react';
import './midi-controller.scss';
import { IChildPanelInfo, IChildPanelInfoProps } from '../../../types/main';

interface SoundOption {
  value: string;
  name: string;
}

const soundOptionsArray: SoundOption[] = [
  { value: '/what-why.mp3', name: 'Sound 1' },
  { value: 'sound2.mp3', name: 'Sound 2' },
  { value: 'sound3.mp3', name: 'Sound 3' },
];

interface SoundButtonProps {
  childPanelInfoState: IChildPanelInfo;
  soundOption: SoundOption;
}

const SoundButton: React.FC<SoundButtonProps> = ({ childPanelInfoState, soundOption }) => {
  const playSound = () => {
    const audio = new Audio(soundOption.value);
    audio.play();
  };

  return (
    <button className="sound-button" onClick={playSound} style={{backgroundColor:childPanelInfoState.mainPanelInfo.styles.borderColor}}>
      {soundOption.name}
    </button>
  );
};

export const MidiController: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  return (
    <>
      <div className='spacer-10' />
      <div className="sound-board">
        {soundOptionsArray.map((soundOption) => (
          <SoundButton key={soundOption.value} soundOption={soundOption} childPanelInfoState={childPanelInfoState}/>
        ))}
      </div>
    </>
  );
};
