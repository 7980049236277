import { IErrorInfo, IErrorProps } from "../../types/main";

export const onError = ({ childPanelInfoState, setChildPanelInfoState, friendly, error }: IErrorProps) => {
    const errorInfo: IErrorInfo = {
      error: friendly,
      e: error,
    }
    // if (setErrorInfo) {
    //   setErrorInfo(prevItems => [...prevItems, errorInfo]);
    // }
    if (childPanelInfoState.errorInfo) {
      const newErrorArray = [...childPanelInfoState.errorInfo, errorInfo];
      setChildPanelInfoState((prevObject) => ({
        ...prevObject,
        errorInfo: newErrorArray,
      }));
    }
  };

  export const formatDateToTimezone = (dateInput: string, timeInput: string, timezoneOffset: number): string => {

    const combinedDateTimeString = `${dateInput}T${timeInput}:00`;
  
    const combinedDateTime = new Date(combinedDateTimeString);
  
    combinedDateTime.setMinutes(combinedDateTime.getMinutes() - timezoneOffset);
  
    const formattedDateString = combinedDateTime.toISOString()
    return formattedDateString;
  }
  
  export const formatDateTimeShort = (dateString: string) => {
    return new Date(dateString).toLocaleString();
  }
  
  export const formateDateTimeNoYear = (date: string | undefined): string | undefined =>
    date === undefined
      ? undefined
      : new Intl.DateTimeFormat('en-Us', {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
        // timeZoneName: 'short'
      }).format(new Date(date));
  
  export const formateDateTime = (date: string | undefined): string | undefined =>
    date === undefined
      ? undefined
      : new Intl.DateTimeFormat('en-Us', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        // timeZoneName: 'short'
      }).format(new Date(date));

export const formatDateForDay = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};