import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import GaugeChart from './charts/GaugeChart';  // Import the chart components
import RadialBarChart from './charts/RadialBarChart';
import TuningForkChart from './charts/TuningForkChart';
import { IChildPanelInfoProps } from '../../../types/main';

Chart.register(...registerables);

const GuitarTuner: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  const [frequency, setFrequency] = useState<number | null>(null);
  const [isTuning, setIsTuning] = useState<boolean>(false);
  const [frequencyData, setFrequencyData] = useState<number[]>([]);
  const [timeLabels, setTimeLabels] = useState<string[]>([]);
  const targetFreq = 440; // Target frequency for A4 (example)
  
  let audioContext: AudioContext | null = null;
  let analyser: AnalyserNode | null = null;
  let dataArray: Float32Array;
  let animationId: number;

  const detectPitch = () => {
    if (analyser) {
      const bufferLength = analyser.fftSize;
      dataArray = new Float32Array(bufferLength);
      analyser.getFloatTimeDomainData(dataArray);

      const detectedFrequency = autoCorrelate(dataArray, audioContext!.sampleRate);
      if (detectedFrequency !== -1) {
        setFrequency(detectedFrequency);
        updateChart(detectedFrequency);
      }

      animationId = requestAnimationFrame(detectPitch);
    }
  };

  const MAX_DATA_POINTS = 50;

  const updateChart = (detectedFrequency: number) => {
    setFrequencyData((prevData) => {
      const newData = [...prevData, detectedFrequency];
      return newData.length > MAX_DATA_POINTS ? newData.slice(-MAX_DATA_POINTS) : newData;
    });

    const currentTime = new Date().toLocaleTimeString();
    setTimeLabels((prevLabels) => {
      const newLabels = [...prevLabels, currentTime];
      return newLabels.length > MAX_DATA_POINTS ? newLabels.slice(-MAX_DATA_POINTS) : newLabels;
    });
  };

  const startTuning = async () => {
    try {
      const AudioContextClass = window.AudioContext || (window as any).webkitAudioContext;
      audioContext = new AudioContextClass();
      analyser = audioContext.createAnalyser();
      analyser.fftSize = 2048;

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);

      setIsTuning(true);
      detectPitch();
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopTuning = () => {
    if (animationId) {
      cancelAnimationFrame(animationId);
    }
    if (audioContext) {
      audioContext.close();
    }
    setIsTuning(false);
  };

  const autoCorrelate = (buffer: Float32Array, sampleRate: number) => {
    // Same auto-correlation logic as before
    let SIZE = buffer.length;
    let sumOfSquares = 0;
    for (let i = 0; i < SIZE; i++) {
      sumOfSquares += buffer[i] * buffer[i];
    }
    const rootMeanSquare = Math.sqrt(sumOfSquares / SIZE);
    if (rootMeanSquare < 0.01) return -1;

    let r1 = 0,
      r2 = SIZE - 1,
      threshold = 0.2;
    for (let i = 0; i < SIZE / 2; i++) {
      if (Math.abs(buffer[i]) < threshold) {
        r1 = i;
        break;
      }
    }
    for (let i = 1; i < SIZE / 2; i++) {
      if (Math.abs(buffer[SIZE - i]) < threshold) {
        r2 = SIZE - i;
        break;
      }
    }

    buffer = buffer.slice(r1, r2);
    SIZE = buffer.length;

    const c = new Array(SIZE).fill(0);
    for (let i = 0; i < SIZE; i++) {
      for (let j = 0; j < SIZE - i; j++) {
        c[i] = c[i] + buffer[j] * buffer[j + i];
      }
    }

    let d = 0;
    while (c[d] > c[d + 1]) d++;
    let maxVal = -1,
      maxPos = -1;
    for (let i = d; i < SIZE; i++) {
      if (c[i] > maxVal) {
        maxVal = c[i];
        maxPos = i;
      }
    }

    let T0 = maxPos;

    let x1 = c[T0 - 1],
      x2 = c[T0],
      x3 = c[T0 + 1];
    const a = (x1 + x3 - 2 * x2) / 2;
    const b = (x3 - x1) / 2;
    if (a) T0 = T0 - b / (2 * a);

    return sampleRate / T0;
  };

  useEffect(() => {
    return () => {
      stopTuning();
    };
  }, []);

  // Chart data setup
  const chartData = {
    labels: timeLabels,
    datasets: [
      {
        label: 'Frequency (Hz)',
        data: frequencyData,
        fill: false,
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(75,192,192,1)',
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: 'Frequency (Hz)',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Time',
        },
      },
    },
  };

  return (
    <div>
      <h1>Guitar Tuner</h1>
      <div>
        <button onClick={isTuning ? undefined : startTuning}>Start Tuning</button>
        <button style={{ float: "right", backgroundColor: childPanelInfoState.mainPanelInfo.styles.dangerButtonColor }} className='dangerButtonColor' onClick={isTuning ? stopTuning : undefined}>Stop Tuning</button>
      </div>
      {isTuning && (
        <div>
          <p>Note: {getNoteName(frequency)}</p>
          <p>Frequency: {frequency ? `${frequency.toFixed(2)} Hz` : 'N/A'}</p>
        </div>
      )}
      <Line data={chartData} options={chartOptions} />
      <GaugeChart currentFreq={frequency || 0} targetFreq={targetFreq} />
      <RadialBarChart currentFreq={frequency || 0} />
      <TuningForkChart currentFreq={frequency || 0} targetFreq={targetFreq} />
    </div>
  );
};

const getNoteName = (frequency: number | null) => {
  if (!frequency) return 'N/A';
  const noteStrings = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
  const noteIndex = Math.round(12 * (Math.log(frequency / 440) / Math.log(2))) + 69;
  return noteStrings[noteIndex % 12];
};

export default GuitarTuner;

// import React, { useEffect, useState } from 'react';
// import { Line } from 'react-chartjs-2';
// import { Chart, registerables } from 'chart.js';
// import { IChildPanelInfoProps } from '../../../types/main';

// Chart.register(...registerables);

// const GuitarTuner: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
//   const [frequency, setFrequency] = useState<number | null>(null);
//   const [isTuning, setIsTuning] = useState<boolean>(false);
//   const [frequencyData, setFrequencyData] = useState<number[]>([]);
//   const [timeLabels, setTimeLabels] = useState<string[]>([]);
//   let audioContext: AudioContext | null = null;
//   let analyser: AnalyserNode | null = null;
//   let dataArray: Float32Array;
//   let animationId: number;

//   const detectPitch = () => {
//     if (analyser) {
//       const bufferLength = analyser.fftSize;
//       dataArray = new Float32Array(bufferLength);
//       analyser.getFloatTimeDomainData(dataArray);

//       const detectedFrequency = autoCorrelate(dataArray, audioContext!.sampleRate);
//       if (detectedFrequency !== -1) {
//         setFrequency(detectedFrequency);
//         updateChart(detectedFrequency);
//       }

//       animationId = requestAnimationFrame(detectPitch);
//     }
//   };

 
//   const MAX_DATA_POINTS = 50;

//   const updateChart = (detectedFrequency: number) => {
//     setFrequencyData((prevData) => {
//       const newData = [...prevData, detectedFrequency];
//       return newData.length > MAX_DATA_POINTS ? newData.slice(-MAX_DATA_POINTS) : newData;
//     });

//     const currentTime = new Date().toLocaleTimeString();
//     setTimeLabels((prevLabels) => {
//       const newLabels = [...prevLabels, currentTime];
//       return newLabels.length > MAX_DATA_POINTS ? newLabels.slice(-MAX_DATA_POINTS) : newLabels;
//     });
//   };


//   const startTuning = async () => {
//     try {
//       const AudioContextClass = window.AudioContext || (window as any).webkitAudioContext;
//       audioContext = new AudioContextClass();
//       analyser = audioContext.createAnalyser();
//       analyser.fftSize = 2048;

//       const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//       const source = audioContext.createMediaStreamSource(stream);
//       source.connect(analyser);

//       setIsTuning(true);
//       detectPitch();
//     } catch (error) {
//       console.error('Error accessing microphone:', error);
//     }
//   };

//   const stopTuning = () => {
//     if (animationId) {
//       cancelAnimationFrame(animationId);
//     }
//     if (audioContext) {
//       audioContext.close();
//     }
//     setIsTuning(false);
//   };

//   const autoCorrelate = (buffer: Float32Array, sampleRate: number) => {
//     // Same auto-correlation logic as before
//     let SIZE = buffer.length;
//     let sumOfSquares = 0;
//     for (let i = 0; i < SIZE; i++) {
//       sumOfSquares += buffer[i] * buffer[i];
//     }
//     const rootMeanSquare = Math.sqrt(sumOfSquares / SIZE);
//     if (rootMeanSquare < 0.01) return -1;

//     let r1 = 0,
//       r2 = SIZE - 1,
//       threshold = 0.2;
//     for (let i = 0; i < SIZE / 2; i++) {
//       if (Math.abs(buffer[i]) < threshold) {
//         r1 = i;
//         break;
//       }
//     }
//     for (let i = 1; i < SIZE / 2; i++) {
//       if (Math.abs(buffer[SIZE - i]) < threshold) {
//         r2 = SIZE - i;
//         break;
//       }
//     }

//     buffer = buffer.slice(r1, r2);
//     SIZE = buffer.length;

//     const c = new Array(SIZE).fill(0);
//     for (let i = 0; i < SIZE; i++) {
//       for (let j = 0; j < SIZE - i; j++) {
//         c[i] = c[i] + buffer[j] * buffer[j + i];
//       }
//     }

//     let d = 0;
//     while (c[d] > c[d + 1]) d++;
//     let maxVal = -1,
//       maxPos = -1;
//     for (let i = d; i < SIZE; i++) {
//       if (c[i] > maxVal) {
//         maxVal = c[i];
//         maxPos = i;
//       }
//     }

//     let T0 = maxPos;

//     let x1 = c[T0 - 1],
//       x2 = c[T0],
//       x3 = c[T0 + 1];
//     const a = (x1 + x3 - 2 * x2) / 2;
//     const b = (x3 - x1) / 2;
//     if (a) T0 = T0 - b / (2 * a);

//     return sampleRate / T0;
//   };

//   useEffect(() => {
//     return () => {
//       stopTuning();
//     };
//   }, []);

//   // Chart data setup
//   const chartData = {
//     labels: timeLabels,
//     datasets: [
//       {
//         label: 'Frequency (Hz)',
//         data: frequencyData,
//         fill: false,
//         backgroundColor: 'rgba(75,192,192,1)',
//         borderColor: 'rgba(75,192,192,1)',
//       },
//     ],
//   };

//   const chartOptions = {
//     responsive: true,
//     scales: {
//       y: {
//         beginAtZero: false,
//         title: {
//           display: true,
//           text: 'Frequency (Hz)',
//         },
//       },
//       x: {
//         title: {
//           display: true,
//           text: 'Time',
//         },
//       },
//     },
//   };

//   return (
//     <div>
//       <h1>Guitar Tuner</h1>
//       <div>
//         <button onClick={isTuning ? undefined : startTuning}>Start Tuning</button>
//         <button style={{ float: "right", backgroundColor: childPanelInfoState.mainPanelInfo.styles.dangerButtonColor }} className='dangerButtonColor' onClick={isTuning ? stopTuning : undefined}>Stop Tuning</button>
//       </div>
//       {isTuning && (
//         <div>
//           <p>Note: {getNoteName(frequency)}</p>
//           <p>Frequency: {frequency ? `${frequency.toFixed(2)} Hz` : 'N/A'}</p>
//         </div>
//       )}
//       <Line data={chartData} options={chartOptions} />
//     </div>
//   );
// };

// const getNoteName = (frequency: number | null) => {
//   if (!frequency) return 'N/A';
//   const noteStrings = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
//   const noteIndex = Math.round(12 * (Math.log(frequency / 440) / Math.log(2))) + 69;
//   return noteStrings[noteIndex % 12];
// };

// export default GuitarTuner;










 //   const updateChart = (detectedFrequency: number) => {
  //     setFrequencyData((prevData) => [...prevData, detectedFrequency]);
  //     const currentTime = new Date().toLocaleTimeString();
  //     setTimeLabels((prevLabels) => [...prevLabels, currentTime]);
  //   };




// import React, { useEffect, useState } from 'react';
// import { IChildPanelInfoProps } from '../../../types/main';

// const GuitarTuner: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
//   const [frequency, setFrequency] = useState<number | null>(null);
//   const [isTuning, setIsTuning] = useState<boolean>(false);

//   let audioContext: AudioContext | null = null;
//   let analyser: AnalyserNode | null = null;
//   let dataArray: Float32Array;
//   let animationId: number;

//   const detectPitch = () => {
//     if (analyser) {
//       const bufferLength = analyser.fftSize;
//       dataArray = new Float32Array(bufferLength);
//       analyser.getFloatTimeDomainData(dataArray);

//       const detectedFrequency = autoCorrelate(dataArray, audioContext!.sampleRate);
//       if (detectedFrequency !== -1) {
//         setFrequency(detectedFrequency);
//       }

//       animationId = requestAnimationFrame(detectPitch);
//     }
//   };

//   const startTuning = async () => {
//     try {
//       const AudioContextClass = window.AudioContext || (window as any).webkitAudioContext;
//       audioContext = new AudioContextClass();
//       analyser = audioContext.createAnalyser();
//       analyser.fftSize = 2048;

//       const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//       const source = audioContext.createMediaStreamSource(stream);
//       source.connect(analyser);

//       setIsTuning(true);
//       detectPitch();
//     } catch (error) {
//       console.error('Error accessing microphone:', error);
//     }
//   };

//   const stopTuning = () => {
//     if (animationId) {
//       cancelAnimationFrame(animationId);
//     }
//     if (audioContext) {
//       audioContext.close();
//     }
//     setIsTuning(false);
//   };

//   const autoCorrelate = (buffer: Float32Array, sampleRate: number) => {
//     let SIZE = buffer.length;
//     let sumOfSquares = 0;
//     for (let i = 0; i < SIZE; i++) {
//       sumOfSquares += buffer[i] * buffer[i];
//     }
//     const rootMeanSquare = Math.sqrt(sumOfSquares / SIZE);
//     if (rootMeanSquare < 0.01) return -1;

//     let r1 = 0,
//       r2 = SIZE - 1,
//       threshold = 0.2;
//     for (let i = 0; i < SIZE / 2; i++) {
//       if (Math.abs(buffer[i]) < threshold) {
//         r1 = i;
//         break;
//       }
//     }
//     for (let i = 1; i < SIZE / 2; i++) {
//       if (Math.abs(buffer[SIZE - i]) < threshold) {
//         r2 = SIZE - i;
//         break;
//       }
//     }

//     buffer = buffer.slice(r1, r2);
//     SIZE = buffer.length;

//     const c = new Array(SIZE).fill(0);
//     for (let i = 0; i < SIZE; i++) {
//       for (let j = 0; j < SIZE - i; j++) {
//         c[i] = c[i] + buffer[j] * buffer[j + i];
//       }
//     }

//     let d = 0;
//     while (c[d] > c[d + 1]) d++;
//     let maxVal = -1,
//       maxPos = -1;
//     for (let i = d; i < SIZE; i++) {
//       if (c[i] > maxVal) {
//         maxVal = c[i];
//         maxPos = i;
//       }
//     }

//     let T0 = maxPos;

//     let x1 = c[T0 - 1],
//       x2 = c[T0],
//       x3 = c[T0 + 1];
//     const a = (x1 + x3 - 2 * x2) / 2;
//     const b = (x3 - x1) / 2;
//     if (a) T0 = T0 - b / (2 * a);

//     return sampleRate / T0;
//   };

//   useEffect(() => {
//     return () => {
//       stopTuning();
//     };
//   }, []);

//   return (
//     <div>
//       <h1>Guitar Tuner</h1>
//       <div>
//         <button onClick={isTuning ? undefined : startTuning}>Start Tuning</button>
//         <button style={{float:"right",backgroundColor: childPanelInfoState.mainPanelInfo.styles.dangerButtonColor }} className='dangerButtonColor' onClick={isTuning ? stopTuning : undefined}>Stop Tuning</button>
//       </div>
//       {isTuning && (
//         <div>
//           <p>Note: {getNoteName(frequency)}</p>
//           <p>Frequency: {frequency ? `${frequency.toFixed(2)} Hz` : 'N/A'}</p>
//         </div>
//       )}
//     </div>
//   );
// };

// const getNoteName = (frequency: number | null) => {
//   if (!frequency) return 'N/A';
//   const noteStrings = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
//   const noteIndex = Math.round(12 * (Math.log(frequency / 440) / Math.log(2))) + 69;
//   return noteStrings[noteIndex % 12];
// };

// export default GuitarTuner;
