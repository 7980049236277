import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IInvoice, calculateTotal, defaultInvoiceData } from './Invoice';
import './invoice-list.scss';
import { IChildPanelInfo } from '../../../types/main';

interface InvoiceItem {
  name: string;
  quantity: number;
  unit_cost: number;
}

export interface SelectedInvoiceProps {
  invoiceData: IInvoice | undefined;
  tableData: IInvoice[];
  setSelectedData: React.Dispatch<React.SetStateAction<IInvoice | undefined>>;
  // handleInvoiceDelete: Dispatch<SetStateAction<IInvoice>>;
  handleInvoiceDelete: (invoiceId: number) => Promise<void>;
  childPanelInfoState: IChildPanelInfo;
}

// const getLastInvoiceNumber = (tableData:IInvoice[]) => {
//   let largestNumber = 0;
//   for(let i = 0; i < tableData.length; i++) {
//     if (largestNumber<tableData[i].number){
//       largestNumber = tableData[i].number;
//     }
//   }
//   return largestNumber;
// };

const getLastInvoiceNumber = (tableData: IInvoice[], currentIndex = 0, largestNumber = 0): number => {
  if (currentIndex >= tableData.length) {
    return largestNumber;
  }
  const currentInvoiceNumber = tableData[currentIndex].number;
  const updatedLargestNumber = Math.max(largestNumber, currentInvoiceNumber);
  return getLastInvoiceNumber(tableData, currentIndex + 1, updatedLargestNumber);
};

export const InvoiceList: React.FC<SelectedInvoiceProps> = ({ setSelectedData, tableData, invoiceData, handleInvoiceDelete, childPanelInfoState }) => {
  const calculateTotalAmount = (items: InvoiceItem[]) => {
    // return items.reduce((total, item) => total + item.quantity * item.unit_cost, 0);
    return items;
  };

  const selectInvoice = (index: number) => () => {
    // Handle the click event for the selected invoice
    const selectedInvoice = tableData[index];
    setSelectedData(selectedInvoice as IInvoice);
  };

  const handleDelete = (index: number) => () => {
    handleInvoiceDelete(index);
  };

  const formatAmount = (amount: number): string => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount);
  };

  const createNew = () => {
    let newInvoiceInfo={...defaultInvoiceData};
    newInvoiceInfo.number = getLastInvoiceNumber(tableData)+1;
    setSelectedData(newInvoiceInfo);
  }

  const close = () => {
    setSelectedData(undefined);
  }

  return (
    <div className='invoice-list'>
      <div className='spacer-10' />
      {/* <h2>List of Invoices</h2> */}
      <table className='table' border={1}>
        {/* <thead>
          <tr>
            <th></th>
            <th>Number</th>
            <th>Client</th>
            <th>Date</th>
            <th>Amount</th>
            <th></th>
          </tr>
        </thead> */}

{/* formatAmount(invoice.total) */}
{/* .toFixed(2) */}
        <tbody>
          {tableData.map((invoice, index) => (
            <tr key={index}>
              <td className='column-button'>
                {/* <a onClick={selectInvoice(index)} >Select</a> */}
                <button onClick={selectInvoice(index)} title="Select" ></button>
              </td>
              <td className="column-center" >{invoice.number}</td>
              <td>{invoice.to}</td>
              <td className='text-right'>{invoice.date}</td>
              <td className='text-right'>{formatAmount(calculateTotal(invoice))}</td>
              <td className='column-button'>
                {/* <a onClick={handleDelete(index)} >Delete</a> */}
                <button style={{backgroundColor: childPanelInfoState.mainPanelInfo.styles.dangerButtonColor}} className='dangerButtonColor' onClick={handleDelete(index)} title="Delete"  ></button>
              </td>
              {/* <td>
                <button></button>
                <button onClick={handleDelete(index)}></button>
              </td> */}
              {/* <td>${calculateTotalAmount(invoice.items)}</td> */}
              {/* <td>${invoice.items}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
      <div className='spacer-10' />
      <button title="New" onClick={createNew}>New</button>
      {invoiceData !== undefined&&<button title="Close" onClick={close} className='right' style={{backgroundColor: childPanelInfoState.mainPanelInfo.styles.dangerButtonColor}} >New</button>}
    </div>
  );
};



// const handleDelete = (index: number) => {
//   try {
//     handleInvoiceDelete(index);
//     // Handle success if needed
//   } catch (error: any) {
//     // Handle error if needed
//     console.error(`Error in ChildComponent: ${error.message}`);
//   }
// };

// const handleClick: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
//   // Prevent the default behavior of the button click
//   event.preventDefault();

//   // Call the asynchronous function
//   await handleDelete2();
// };
