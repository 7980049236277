import React from 'react';
import { useEffect, useState } from 'react';
import './todos.scss';
import { IDataReturn, IDataForAPI, IPostData, fetchPostsByTags, handleAdd, handleDelete, handleUpdate } from '../../../radApi';
import { v4 as uuidv4 } from 'uuid';
import { updateOrderedPosts } from '../../../helpers/orderHelpers';
import { IChildPanelInfoProps } from '../../../../types/main';

interface Todo {
  id: number;
  text: string;
  priority: number;
  completed: boolean;
}

const Todos: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  const dataForAPI: IDataForAPI = {
    userId: childPanelInfoState.userInfo.id,
    key: childPanelInfoState.userInfo.key,
    tagIds: [3],
    status: 1,
    encryptData:childPanelInfoState.mainPanelInfo.system.encryptData
  }
  // const apiTags = [3];
  const [allPosts, setAllPosts] = useState<IPostData[]>([]);
  const [todos, setTodos] = useState<Todo[]>([]);
  const [sortedTodos, setSortedTodos] = useState<Todo[]>([]);
  const [inputText, setInputText] = useState('New Todo');
  const [inputPriority, setInputPriority] = useState(1);
  const [orderedIds, setOrderedIds] = useState<number[]>([]);

  const [editingRow, setChangePriorityClicked] = useState<boolean>(false);
  
  const addTodo = () => {
    const newTodo: Todo = {
      id: todos.length + 1,
      text: inputText,
      priority: inputPriority,
      completed: false,
    };
    const dataForAPIUpdated = { ...dataForAPI, title: "New Todo", formData: newTodo };
    handleAdd(dataForAPIUpdated).then((postData: IDataReturn) => {
      if (postData.success) {
        updateInfo();
      } else {
        console.error('Error Adding', postData.errors, postData.requestOptions, postData.variables);
      }
    })
      .catch((error) => {
        console.error('Error:', error);
      });
    setInputText('');
    setInputPriority(1);
  };

  const toggleTodo = (id: number) => {
    for (let index = 0; index < todos.length; index++) {
      const element = todos[index];
      if (element.id === id) {
        element.completed = !(element.completed);
        const dataForAPIUpdated = { ...dataForAPI, postId: allPosts[index].id, title: allPosts[index].title, formData: element };
        handleUpdate(dataForAPIUpdated).then((postData: IDataReturn) => {
          if (postData.success) {
            updateInfo();
          } else {
            console.error('Error Updating', postData.errors, postData.requestOptions, postData.variables);
          }
        })
          .catch((error) => {
            console.error('Error:', error);
          });;
      }
    }
  };

  const changePriorityClicked= (id: number) => {
    for (let index = 0; index < todos.length; index++) {
      const element = todos[index];
      if (element.id === id) {
        // element.priority = priority;
        const dataForAPIUpdated = { ...dataForAPI, postId: allPosts[index].id, title: allPosts[index].title, formData: element };
        handleUpdate(dataForAPIUpdated).then((postData: IDataReturn) => {
          if (postData.success) {
            updateInfo();
          } else {
            console.error('Error Updating', postData.errors, postData.requestOptions, postData.variables);
          }
        })
          .catch((error) => {
            console.error('Error:', error);
          });;
      }
    }
  }

  const changePriority= (id: number, priority: number) => {
    for (let index = 0; index < todos.length; index++) {
      const element = todos[index];
      if (element.id === id) {
        element.priority = priority;
        const dataForAPIUpdated = { ...dataForAPI, postId: allPosts[index].id, title: allPosts[index].title, formData: element };
        handleUpdate(dataForAPIUpdated).then((postData: IDataReturn) => {
          if (postData.success) {
            updateInfo();
          } else {
            console.error('Error Updating', postData.errors, postData.requestOptions, postData.variables);
          }
        })
          .catch((error) => {
            console.error('Error:', error);
          });;
      }
    }
  }

  const deleteTodo = (index: number) => {
    const dataForAPIUpdated = { ...dataForAPI, postId: allPosts[index].id };
    handleDelete(dataForAPIUpdated).then((postData: IDataReturn) => {
      // console.log('deleteTodo', postData);
      if (postData.success) {
        updateInfo();
      } else {
        console.error('Error Deleting', postData.errors, postData.requestOptions, postData.variables);
      }
    })
      .catch((error) => {
        console.error('Error Deleting:', error);
      });
  };

  const updateInfo = () => {
    const dataForAPIUpdated = { ...dataForAPI };
    fetchPostsByTags(dataForAPIUpdated).then((postData: IDataReturn) => {
      if (postData.success) {
        // console.log('for the win', postData.data);
        setAllPosts(postData.posts as IPostData[]);
        const todos = postData.data as Todo[];
        setTodos(todos);
        const sortedTodos = todos.slice().sort((a, b) => a.priority - b.priority);
        // console.log('sortedTodos', sortedTodos)
        setSortedTodos(sortedTodos);
      } else {
        console.error('Error Updating', postData.errors, postData.requestOptions, postData.variables);
      }
    })
      .catch((error) => {
        console.error('Error Updating:', error);
      });
  }

  // interface TableRow {
  //   id: number;
  //   text: string;
  // }

  // const [rows, setRows] = useState<TableRow[]>(initialRows);

  const handleDragStart = (e: React.DragEvent<HTMLTableRowElement>, dragIndex: number) => {
    e.dataTransfer.setData('dragIndex', dragIndex.toString());
  };

  const handleDragOver = (e: React.DragEvent<HTMLTableRowElement>, hoverIndex: number) => {
    e.preventDefault();
    const dragIndex = parseInt(e.dataTransfer.getData('dragIndex'));
    if (dragIndex !== hoverIndex) {
      const updatedRows = [...sortedTodos];
      const dragRow = updatedRows[dragIndex];
      updatedRows.splice(dragIndex, 1);
      updatedRows.splice(hoverIndex, 0, dragRow);
      setSortedTodos(updatedRows);
      // Add CSS class to rows based on hoverIndex
      const rows = document.querySelectorAll<HTMLTableRowElement>('.draggable-row-class'); // Replace 'your-row-class' with your actual row class name
  
      rows.forEach((row, index) => {
        if (index <= hoverIndex) {
          row.classList.add('transparent-row'); // Add class to make rows transparent
        } else {
          row.classList.remove('transparent-row'); // Remove class from other rows
        }
      });
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLTableRowElement>) => {
    e.preventDefault();
  };


  useEffect(() => {
    updateInfo();
  }, []);






  interface postOrderReturn {
    data: {
      postIds: number[];
    }
    postIds: number[];
  };

  return <div className='todos'>
    <table border={1} width="100%">
      <tbody>
        <tr className='no-padding' >
          <td width={50} className='column-priority'><input
            className='priority-column'
            type="number"
            id="priority"
            value={inputPriority}
            onChange={(e) => setInputPriority(parseInt(e.target.value))}
          /></td>
          <td ><input
            type="text"
            id="todoTitle"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
          /></td>
          <td className='column-button'><button title="Add" onClick={addTodo}>Add</button></td>
        </tr>
        {sortedTodos.map((todo, index) => (
          <tr key={index}
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={(e) => handleDragOver(e, index)}
          onDrop={handleDrop}
          className='draggable-row-class'
          >
            <td className="column-center">{index + 1}</td>
            <td>
              <span
                className="todo-text"
                style={{ textDecoration: todo.completed ? 'line-through' : 'none' }}
                onClick={() => toggleTodo(index)}
              >
                {todo.text}
              </span>
            </td>
            <td className='column-button'>
              <button title="Delete" style={{backgroundColor: childPanelInfoState.mainPanelInfo.styles.dangerButtonColor}} className='dangerButtonColor' onClick={() => deleteTodo(index)}>Delete</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>;
};

export default Todos;


// import { useEffect, useState } from 'react';
// import './todos.scss';
// import ApiService from '../serviceApi';

// interface Todo {
//   userId: number;
//   id: number;
//   title: string;
//   completed: boolean;
// }

// export const Todos = () => {
//   const [todos, setTodos] = useState<Todo[]>([]);
//   const [inputTitle, setInputTitle] = useState<string>('');
//   const [inputPriority, setInputPriority] = useState<number>(5);


//   const apiService = new ApiService();
//   const userId = 1;

//   const addTodo = () => {
//     const newTodo = { "todo": { "id": todos.length + 1, "title": inputTitle, "completed": false }, "id": 1 }
//     apiService.addObject('todos', newTodo)
//       .then(data => {
//         getAllTodos();
//       })
//       .catch(error => {
//         console.error('Error:', error);
//       });
//     setInputTitle('');

//   };

//   const toggleTodo = (id: number) => {
//     const updatedTodos = todos.map((todo) => {
//       if (todo.id === id) {
//         return { ...todo, completed: !todo.completed };
//       }
//       return todo;
//     });
//     setTodos(updatedTodos);
//   };

//   const deleteTodo = (id: number) => {
//     apiService.delete('todos', id)
//       .then(data => {
//         getAllTodos();
//       })
//       .catch(error => {
//         console.error('Error:', error);
//       });
//   };

//   const getAllTodos = () => {
//     apiService.getAll('todos')
//       .then(data => {
//         setTodos(data as Todo[]);
//       })
//       .catch(error => {
//         console.error('Error:', error);
//       });
//   }

//   useEffect(() => {
//     getAllTodos();
//   }, []);

//   // Add priorities
//   return (
//     // <div>
//     //   <div className="todo-form">
//     //     {/* <label htmlFor="todoTitle">Todo:</label> */}
//     //     <input
//     //       type="text"
//     //       id="todoTitle"
//     //       value={inputTitle}
//     //       onChange={(e) => setInputTitle(e.target.value)}
//     //       placeholder="Add a new todo"
//     //     />
//     //     {/* <label htmlFor="priority">Priority:</label> */}
//     //     <input
//     //       type="number"
//     //       id="priority"
//     //       value={inputPriority}
//     //       onChange={(e) => setInputPriority(parseInt(e.target.value))}
//     //     />
//     //     <button onClick={addTodo}></button>
//     //   </div>

//     //   <ul>
//     //     {todos.map((todo) => (
//     //       <li key={todo.id}>
//     //         <span className='todo-text'
//     //           style={{ textDecoration: todo.completed ? 'line-through' : 'none' }}
//     //           onClick={() => toggleTodo(todo.id)}
//     //         >
//     //           {todo.title}
//     //         </span>
//     //         <button onClick={() => deleteTodo(todo.id)}></button>
//     //       </li>
//     //     ))}
//     //   </ul>
//     // </div>
//     <div>
//       <div class="todo-form">
//         <input
//           type="text"
//           id="todoTitle"
//           value={inputTitle}
//           onChange={(e) => setInputTitle(e.target.value)}
//           placeholder="Add a new todo"
//         />
//         <input
//           type="number"
//           id="priority"
//           value={inputPriority}
//           onChange={(e) => setInputPriority(parseInt(e.target.value))}
//         />
//         <button onClick={addTodo}></button>
//       </div>

//       <table>
//         <thead>
//           <tr>
//             <th>Todo</th>
//             <th>Priority</th>
//             <th></th>
//           </tr>
//         </thead>
//         <tbody>
//           {todos.map((todo) => (
//             <tr key={todo.id}>
//               <td>
//                 <span
//                   className="todo-text"
//                   style={{ textDecoration: todo.completed ? "line-through" : "none" }}
//                   onClick={() => toggleTodo(todo.id)}
//                 >
//                   {todo.title}
//                 </span>
//               </td>
//               <td>{todo.priority}</td>
//               <td>
//                 <button onClick={() => deleteTodo(todo.id)}>Delete</button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };



  // const [editIndex, setEditIndex] = useState(null);
  // const [editedText, setEditedText] = useState('');
  // const [editedPriority, setEditedPriority] = useState('');

  // const handleEditClick = (index:number, text:string, priority:number) => {
  //   // setEditIndex(index);
  //   // setEditedText(text);
  //   // setEditedPriority(priority);
  // };

  // const handleSaveClick = (index:number) => {
  //   // updateTodoText(index, editedText);
  //   // updateTodoPriority(index, editedPriority);
  //   // setEditIndex(null);
  // };

  // const handleCancelClick = () => {
  //   setEditIndex(null);
  // };





  
    //   }
    // }
    // setTodos(todos.filter((todo) => todo.id !== id));

    {/* <pre>{JSON.stringify(sortedTodos,null,2)}</pre> */}

            {/* <td className="column-center">
              <span
                onClick={() => changePriorityClicked(index)}
              >
                {todo.priority}
              </span>
            </td> */}

      {/* {sortedTodos.map((todo, index) => (
        <tr key={index}>
          <td className="column-center">{index + 1}</td>
          <td>
            {editIndex === index ? (
              <input
                type="text"
                value={editedText}
                onChange={(e) => setEditedText(e.target.value)}
              />
            ) : (
              <span
                className="todo-text"
                style={{ textDecoration: todo.completed ? 'line-through' : 'none' }}
                onClick={() => toggleTodo(index)}
              >
                {todo.text}
              </span>
            )}
          </td>
          <td>
            {editIndex === index ? (
              <input
                type="text"
                value={editedPriority}
                onChange={(e) => setEditedPriority(e.target.value)}
              />
            ) : (
              <span>{todo.priority}</span>
            )}
          </td>
          <td className="column-center">
            {editIndex === index ? (
              <>
                <button onClick={() => handleSaveClick(index)}>Save</button>
                <button onClick={handleCancelClick}>Cancel</button>
              </>
            ) : (
              <button onClick={() => handleEditClick(index, todo.text, todo.priority)}>
                Edit
              </button>
            )}
            <button title="Delete" className="red" onClick={() => deleteTodo(index)}>
              Delete
            </button>
          </td>
        </tr>
      ))} */}