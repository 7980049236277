import React from 'react';
import { Chart, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

Chart.register(ArcElement, Tooltip);

const GaugeChart: React.FC<{ currentFreq: number; targetFreq: number }> = ({ currentFreq, targetFreq }) => {
  const calculatePercentage = () => {
    const difference = Math.abs(currentFreq - targetFreq);
    const percentage = Math.max(0, 100 - (difference / targetFreq) * 100);
    return percentage;
  };

  const data = {
    datasets: [
      {
        data: [calculatePercentage(), 100 - calculatePercentage()],
        backgroundColor: ['#4CAF50', '#ddd'],
        borderWidth: 0,
      },
    ],
  };

  return (
    <div>
      <h3>Gauge Chart</h3>
      <Doughnut
        data={data}
        options={{
          circumference: 180,
          rotation: 270,
          cutout: '80%',
        }}
      />
      <p>Current Frequency: {currentFreq} Hz</p>
      <p>Target Frequency: {targetFreq} Hz</p>
    </div>
  );
};

export default GaugeChart;
