import React, { useState, useEffect } from 'react';
import { calculateResultByYearMonth } from '../../helpers/moneyHelpers';
import { ResultByYearMonthItem } from '../../../types/money';

interface ResultsByMonth {
    moneyByMonth: Map<number, Map<number, number[]>>;
}

export const ResultsByMonth: React.FC<ResultsByMonth> = ({moneyByMonth}) => {
  const {averagePerMonth, resultByYearMonth} = calculateResultByYearMonth(moneyByMonth)

  return (
    <div>
      
      <div>Ave per day: <span className="highlight-section">${(averagePerMonth*12/365).toLocaleString()}</span> / <span className="highlight-section">${(((averagePerMonth*12)/365)/2).toLocaleString()}</span></div>
      <div>Ave per month: <span className="highlight-section">${averagePerMonth.toLocaleString()}</span> / <span className="highlight-section">${(averagePerMonth/2).toLocaleString()}</span></div>
      <div>Ave per year: <span className="highlight-section">${(averagePerMonth*12).toLocaleString()}</span> / <span className="highlight-section">${(averagePerMonth*12/2).toLocaleString()}</span></div>
      <h2>Results by Year and Month</h2>
      <table>
        <thead>
          <tr>
            <th>Month</th>
            <th>Year</th>
            <th>Total</th>
            <th>Number of Days</th>
            <th>Value Per Day</th>
          </tr>
        </thead>
        <tbody>
          {resultByYearMonth.map((item) => (
            <tr key={`${item.year}-${item.month}`}>
              <td>{item.month}</td>
              <td>{item.year}</td>
              <td className='highlight-section'>{item.value}</td>
              <td>{item.numberOfDays}</td>
              <td>{item.valuePerDay}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// import React, { useState, useEffect } from 'react';
// import { IMoney } from './Money';
// import { getByYear } from '../../helpers/moneyHelpers';

// interface IMoneyByMonthProps {
//     listOfMoney: IMoney[];
// }

// export const MoneyByMonth: React.FC<IMoneyByMonthProps> = ({listOfMoney}) => {
//   const [moneyList, setMoneyList] = useState<IMoney[]>(listOfMoney);
//   const [resultByYear, setResultByYear] = useState<Map<number, Map<number, number[]>> | null>(null);

// //   useEffect(() => {
// //     // Fetch your IMoney data here, for example:
// //     // const fetchData = async () => {
// //     //   const response = await fetch('your-api-endpoint');
// //     //   const data = await response.json();
// //     //   setMoneyList(data);
// //     // };
// //     // fetchData();

// //     // Mock data for demonstration
// //     const mockMoneyList: IMoney[] = [
// //       { created: '2022-01-01', amount: 100 },
// //       { created: '2022-02-01', amount: 200 },
// //       { created: '2022-03-01', amount: 300 },
// //     ];
// //     setMoneyList(mockMoneyList);
// //   }, []);

// // Results by month:
// // Month	Year	# of Days	Amount Per Month	Amount Per Day
// // June	2022	31	$15,653.87	$504.96
// // July	2022	30	$9,287.44	$309.58
// // August	2022	31	$7,664.57	$247.24
// // September	2022	31	$5,999.07	$193.52
// // October	2022	30	$7,243.39	$241.45
// // November	2022	31	$6,813.30	$219.78

// //   useEffect(() => {
// //     if (moneyList.length > 0) {
// //       const result = getByYear(moneyList);
// //       setResultByYear(result);
// //     }
// //   }, [moneyList]);

//   useEffect(() => {
//     if(listOfMoney.length) {
//       const result = getByYear(listOfMoney);
//       setResultByYear(result);
//     }
//   },[listOfMoney])

  

//   return (
//     <div>
//       <h2>Results by Year and Month</h2>
//       {resultByYear && (
//         <div>
//           {Array.from(resultByYear).map(([year, monthMap]) => (
//             <div key={year}>
//               <h3>{year}</h3>
//               <ul>
//                 {Array.from(monthMap).map(([month, amounts]) => (
//                   <li key={month}>
//                     {`Month: ${month + 1}, Amounts: ${amounts.join(', ')}`}
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };
