import React, { ForwardedRef, forwardRef, useRef } from 'react';
import { InvoiceView } from './InvoiceView';
import { IInvoice } from './Invoice';

interface IPDFProps {
  invoiceData: IInvoice;
}

const PrintArea = forwardRef((props: IPDFProps, ref: ForwardedRef<HTMLDivElement>) => {
  return (
    <div ref={ref} >
      <InvoiceView invoiceData={props.invoiceData} />
    </div>
  );
});

export const PrintPdf: React.FC<IPDFProps> = ({ invoiceData }) => {
  const componentRef = useRef<HTMLDivElement | null>(null);

  const styles = `
      /* General styles for the invoice container */
      .invoice-container {
        font-family: 'Arial', sans-serif;
        padding: 20px;
        border: 0px solid #ddd;
        position: relative;
        font-color: #000;
      }
      
      /* Styles for the header section */
      .invoice-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }
      
      /* Styles for the address section */
      .invoice-address {}
      
      .address-line {
        margin: 5px 0;
      }
      
      /* Styles for the details section */
      .invoice-details {
        width: 45%;
        text-align: right;
      }
      
      /* Styles for the items section */
      .invoice-items table {
        width: 100%;
        border-collapse: collapse;
      }
      
      .table-header {
        background-color: black;
        color: white;
        font-weight: bold;
      }
      
      .invoice-items th {
        border: 1px solid black;
        padding: 8px;
        text-align: left;
        background-color: black;
      }
      
      .invoice-items td {
        border: 0px solid #ddd;
        padding: 8px;
        text-align: left;
      }
      
      /* Styles for the summary section */
      .invoice-summary-container {
        position: relative;
        margin-top: 20px;
      }
      
      .invoice-summary {
        position: absolute;
        top: 0;
        right: 0;
      }
      
      .summary-item {
        margin-bottom: 5px;
      }
      
      .total {
        color: green;
      }
      
      .balance-due {
        font-weight: bold;
        border: 1px solid #ddd;
        padding: 8px;
        margin-top: 20px;
        background-color: #ddd;
      }
      
      /* Styles for the notes section */
      .invoice-notes {
        margin-top: 20px;
        clear: both;
      }
      
      /* Styles for the terms section */
      .invoice-terms {
        margin-top: 10px;
        clear: both;
      }
      
      /* Responsive styles */
      @media screen and (max-width: 600px) {
        .invoice-address,
        .invoice-details,
        .invoice-items,
        .invoice-summary,
        .invoice-notes,
        .invoice-terms {
          width: 100%;
        }
      
        .invoice-header {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      
      .lower-invoice {
        margin-top: 75px;
        clear: both;
      }
      
      .invoice-text {
        font-size: 40px;
        margin: 0px;
      }
      
      .invoice-number {
        margin-top: 0px;
        margin-bottom: 45px;
      }

      @media print {
        .table-header {
          background-color: #000000;
          color: #ffffff;
          font-weight: bold;
        }
      }
      `;

  const handlePrint = () => {
    if (componentRef.current) {
      const printContent = componentRef.current.innerHTML;
      let myWindow = window.open('https://themeterguy.com/dashboard', 'Invoice', 'height=400,width=600');
      if (myWindow) {
        myWindow.document.write('<html><head><title>Print Invoice</title>');
        /*optional stylesheet*/ //mywindow.document.write('<link rel="stylesheet" href="main.css" type="text/css" />');
        myWindow.document.write(`<style>${styles}</style></head><body >`);
        myWindow.document.write(printContent);
        myWindow.document.write('</body></html>');

        myWindow.print();
        myWindow.close();
      }
    } else {
      console.error('componentRef is undefined.');
    }
  };

  return (
    <div>
      <PrintArea invoiceData={invoiceData} ref={componentRef} />
      <div className='spacer-10' />
      <button onClick={handlePrint} title="Create PDF"></button>
    </div>
  );
};