import React, { useState } from 'react';
import "./alarms.scss";
import { IChildPanelInfoProps } from '../../../types/main';

interface Alarm {
  id: number;
  time: string;
  active: boolean;
  text: string;
}

const initialAlarms: Alarm[] = [
  { id: 1, time: '08:00', active: true, text: 'Wake up' },
  { id: 2, time: '12:00', active: false, text: 'Lunch break' },
  { id: 3, time: '18:00', active: true, text: 'Dinner time' },
];

const Alarms: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  const [alarms, setAlarms] = useState(initialAlarms);
  const [newAlarm, setNewAlarm] = useState({ id: 0, time: '', active: false, text: '' });

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewAlarm({ ...newAlarm, time: event.target.value });
  };

  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewAlarm({ ...newAlarm, active: event.target.checked });
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewAlarm({ ...newAlarm, text: event.target.value });
  };

  const handleAddAlarm = () => {
    setNewAlarm({ ...newAlarm, id: alarms.length + 1 });
    setAlarms([...alarms, newAlarm]);
  };

  const handleDeleteAlarm = (id: number) => {
    setAlarms(alarms.filter((alarm) => alarm.id !== id));
  };

  const handleAlarm = (time: string, text: string) => {
    const audio = new Audio('https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3');
    audio.play();
    alert(`Alarm for ${text} at ${time}`);
  };

  return (
    <div className='alarms'>
      <h2>Alarms</h2>
      <table>
  {/* <thead>
    <tr>
      <th></th>
      <th>Time</th>
      <th>Text</th>
      <th>Delete</th>
      <th>Test</th>
    </tr>
  </thead> */}
  <tbody>
    {alarms.map((alarm, index) => (
      <tr key={alarm.id}>
        <td className='checkbox'>
          <input type="checkbox" checked={alarm.active} onChange={() => {}} />
        </td>
        <td className='time'>
          <input type="time" value={alarm.time} onChange={() => {}} />
        </td>
        <td>
          <input type="text" value={alarm.text} onChange={() => {}} />
        </td>
        <td>
          <button onClick={() => handleDeleteAlarm(alarm.id)}>Delete</button>
        </td>
        <td>
          <button onClick={() => handleAlarm(alarm.time, alarm.text)}>Test</button>
        </td>
      </tr>
    ))}
  </tbody>
</table>

      {/* <ul>
        {alarms.map((alarm) => (
          <li key={alarm.id}>
            <input type="checkbox" checked={alarm.active} onChange={() => {}} />
            <input type="time" className='time' value={alarm.time} onChange={() => {}} />
            <input type="text" value={alarm.text} onChange={() => {}} />
            <button onClick={() => handleDeleteAlarm(alarm.id)}>Delete</button>
            <button onClick={() => handleAlarm(alarm.time, alarm.text)}>Test</button>
          </li>
        ))}
      </ul> */}
      {/* <h3>Add Alarm</h3>
      <input type="checkbox" checked={newAlarm.active} onChange={handleActiveChange} />
      <input type="time" value={newAlarm.time} onChange={handleTimeChange} />
      <input type="text" value={newAlarm.text} onChange={handleTextChange} />
      <button onClick={handleAddAlarm}>Add</button> */}
    </div>
  );
};

export default Alarms;
