import React from 'react';
import DrumBeat from "./DrumBeat";
import { DrumMachine } from "./DrumMachine";
import DrumTest from "./DrumTest";
import AudioControls from "./audio-controls/AudioControls";
import LFO from "./l-f-o/LFO";
import Piano from "./piano/Piano";
import SongList from "./old-song-list/SongListOld";
import { IChildPanelInfoProps } from "../../../types/main";

export const Music: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
    
    return (
      <div>
        <Piano />
        {/* <hr />
        <Piano />
        <hr />
        <DrumTest />
        <hr />
        <DrumBeat />
        <hr />
        <LFO />
        <hr />
        <AudioControls />
        <hr />
        <DrumMachine /> */}
      </div>
    );
  };
