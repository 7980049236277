import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
// import d3-force
import { ISensor } from '../Network';
import { IChildPanelInfo } from '../../../../types/main';

interface Node {
  name: string;
  icon: string;
  x: number; // Add the appropriate properties
  y: number; // Add the appropriate properties
}

interface Link {
  source: string;
  target: string;
}

interface SensorChartProps {
  sensorData: ISensor[];
  childPanelInfoState: IChildPanelInfo;
}

export const SensorChart: React.FC<SensorChartProps> = ({ sensorData, childPanelInfoState }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    const nodes: { [key: string]: Node } = {};
    const links: Link[] = [];

    // Populate nodes and links based on sensorData
    sensorData.forEach((sensor) => {
      nodes[sensor.friendly_name as string] = {
        name: sensor.friendly_name as string,
        icon: 'your_icon_url', // Set the actual icon URL or logic
        x: 0, // Add the appropriate initial x value
        y: 0, // Add the appropriate initial y value
        // Add other properties as needed
      };

      if (sensor.neighbors) {
        sensor.neighbors.split(',').forEach((neighbor: string | undefined) => {
          if (neighbor) {
            links.push({ source: sensor.friendly_name as string, target: neighbor });
          }
        });
      }
    });

    const svg = d3.select(svgRef.current);

    // D3 force simulation setup
    const force = d3
      .forceSimulation<Node, d3.SimulationLinkDatum<Node>>()
      .nodes(Object.values(nodes))
      .force('link', d3.forceLink<Node, d3.SimulationLinkDatum<Node>>(links).distance(180))
      .force('charge', d3.forceManyBody<Node>().strength(-300))
      .on('tick', tick);

    let link = svg.selectAll('.link').data(links).join('line');

    function tick() {
      link
        .attr('x1', (d: any) => (d.source as Node).x || 0)
        .attr('y1', (d: any) => (d.source as Node).y || 0)
        .attr('x2', (d: any) => (d.target as Node).x || 0)
        .attr('y2', (d: any) => (d.target as Node).y || 0);
    }
  }, [sensorData]);

  return (
    <svg ref={svgRef} width={childPanelInfoState.mainPanelInfo.center.width} height={childPanelInfoState.mainPanelInfo.center.height}>
      {/* Render other chart elements as needed */}
    </svg>
  );
};



// import React, { useEffect, useRef } from 'react';
// import * as d3 from 'd3';
// import { Sensor } from '../Network';



// interface SensorChartProps {
//   sensorData: Sensor[];
// }

// const SensorChart: React.FC<SensorChartProps> = ({ sensorData }) => {
//   const chartRef = useRef<SVGSVGElement>(null);

//   useEffect(() => {
//     if (chartRef.current && sensorData.length > 0) {
//       // Clear previous chart
//       d3.select(chartRef.current).selectAll('*').remove();

//       const width = 800;
//       const height = 600;

//       const svg = d3.select(chartRef.current).attr('width', width).attr('height', height);

//       const simulation = d3.forceSimulation<Sensor>()
//         .force('charge', d3.forceManyBody().strength(-200))
//         .force('link', d3.forceLink().distance(100).links(getLinks(sensorData)))
//         .force('center', d3.forceCenter(width / 2, height / 2));

//       const links = svg
//         .selectAll('line')
//         .data(getLinks(sensorData))
//         .enter()
//         .append('line')
//         .attr('stroke', '#ccc');

//       const nodes = svg
//         .selectAll('circle')
//         .data(sensorData)
//         .enter()
//         .append('circle')
//         .attr('r', 10)
//         .attr('fill', '#1f77b4')
//         .call(
//           d3
//             .drag<SVGCircleElement, Sensor>()
//             .on('start', (event, d) => {
//               if (!event.active) simulation.alphaTarget(0.3).restart();
//               d.fx = d.x!;
//               d.fy = d.y!;
//             })
//             .on('drag', (event, d) => {
//               d.fx = event.x;
//               d.fy = event.y;
//             })
//             .on('end', (event, d) => {
//               if (!event.active) simulation.alphaTarget(0);
//               d.fx = null;
//               d.fy = null;
//             })
//         );
          

//       simulation.on('tick', () => {
//         links
//           .attr('x1', (d) => (d.source as Sensor).x!)
//           .attr('y1', (d) => (d.source as Sensor).y!)
//           .attr('x2', (d) => (d.target as Sensor).x!)
//           .attr('y2', (d) => (d.target as Sensor).y!);

//         nodes.attr('cx', (d) => d.x!).attr('cy', (d) => d.y!);
//       });
//     }
//   }, [sensorData]);

//   function getLinks(sensorData: Sensor[]): d3.SimulationLinkDatum<Sensor>[] {
//     const links: d3.SimulationLinkDatum<Sensor>[] = [];
//     sensorData.forEach((sensor) => {
//       if (sensor.neighbors) {
//         const neighborIds = sensor.neighbors.split(',');
//         neighborIds.forEach((neighborId) => {
//           const neighbor = sensorData.find((s) => s.node_id === parseInt(neighborId));
//           if (neighbor) {
//             links.push({ source: sensor, target: neighbor });
//           }
//         });
//       }
//     });
//     return links;
//   }

//   return <svg ref={chartRef}></svg>;
// };

// export default SensorChart;





// import React, { useEffect, useRef } from 'react';
// import * as d3 from 'd3';

// interface Sensor {
//   friendly_name: string;
//   state: string;
//   node_id: number;
//   neighbors: string;
//   last_updated: string;
// }

// interface SimulationNode {
//   id: string;
//   state: string;
//   node_id: number;
//   neighbors: string;
//   last_updated: string;
// }

// const adaptSensorDataForSimulation = (sensorData: Sensor[]): SimulationNode[] => {
//   return sensorData.map((sensor) => ({
//     id: sensor.friendly_name,
//     state: sensor.state,
//     node_id: sensor.node_id,
//     neighbors: sensor.neighbors,
//     last_updated: sensor.last_updated,
//   }));
// };

// const SensorForceGraph: React.FC<{ sensorData: Sensor[] }> = ({ sensorData }) => {
//   const svgRef = useRef<SVGSVGElement | null>(null);

//   useEffect(() => {
//     const width = 800;
//     const height = 600;

//     const simulationNodes = adaptSensorDataForSimulation(sensorData);

//     const simulation = d3
//       .forceSimulation(simulationNodes)
//       .force('charge', d3.forceManyBody())
//       .force('center', d3.forceCenter(width / 2, height / 2))
//       // Add other forces as needed

//     const svg = d3.select(svgRef.current);

//     const node = svg
//       .selectAll('.node')
//       .data(simulationNodes)
//       .enter()
//       .append('circle')
//       .attr('r', 5)
//       .attr('fill', 'blue');

//     node.append('title').text((d) => d.id);

//     simulation.on('tick', () => {
//       node.attr('cx', (d) => d.x || 0).attr('cy', (d) => d.y || 0);
//     });
//   }, [sensorData]);

//   return <svg ref={svgRef} width="800" height="600"></svg>;
// };

// export default SensorForceGraph;




// import React, { useEffect, useRef } from 'react';
// import * as d3 from 'd3';

// type Sensor = {
//     friendly_name: string;
//     state: string;
//     node_id: number;
//     neighbors: string | undefined;
//     last_updated: string;
//   };
  

// interface SensorChartProps {
//   sensorData: Sensor[];
// }

// const SensorChart: React.FC<SensorChartProps> = ({ sensorData }) => {
//   const chartRef = useRef<SVGSVGElement>(null);

//   useEffect(() => {
//     if (chartRef.current && sensorData.length > 0) {
//       // Clear previous chart
//       d3.select(chartRef.current).selectAll('*').remove();

//       const width = 800;
//       const height = 600;

//       const svg = d3.select(chartRef.current).attr('width', width).attr('height', height);

//       const simulation = d3
//         .forceSimulation(sensorData)
//         .force('charge', d3.forceManyBody().strength(-200))
//         .force('link', d3.forceLink().distance(100).links(getLinks(sensorData)))
//         .force('center', d3.forceCenter(width / 2, height / 2));

//       const links = svg
//         .selectAll('line')
//         .data(getLinks(sensorData))
//         .enter()
//         .append('line')
//         .attr('stroke', '#ccc');

//       const nodes = svg
//         .selectAll('circle')
//         .data(sensorData)
//         .enter()
//         .append('circle')
//         .attr('r', 10)
//         .attr('fill', '#1f77b4')
//         .call(
//             d3
//               .drag<SVGCircleElement, Sensor>()
//               .on('start', (event, d) => {
//                 if (!event.active) simulation.alphaTarget(0.3).restart();
//                 d.fx = d.x!;
//                 d.fy = d.y!;
//               })
//               .on('drag', (event, d) => {
//                 d.fx = event.x;
//                 d.fy = event.y;
//               })
//               .on('end', (event, d) => {
//                 if (!event.active) simulation.alphaTarget(0);
//                 d.fx = null;
//                 d.fy = null;
//               })
//           );
          

//       simulation.on('tick', () => {
//         links
//           .attr('x1', (d) => d.source.x!)
//           .attr('y1', (d) => d.source.y!)
//           .attr('x2', (d) => d.target.x!)
//           .attr('y2', (d) => d.target.y!);

//         nodes.attr('cx', (d) => d.x!).attr('cy', (d) => d.y!);
//       });
//     }
//   }, [sensorData]);

//   const getLinks = (data: Sensor[]): { source: Sensor; target: Sensor }[] => {
//     const links: { source: Sensor; target: Sensor }[] = [];

//     data.forEach((sensor) => {
//       if (sensor.neighbors) {
//         const neighborIds = sensor.neighbors.split(',');
//         neighborIds.forEach((neighborId) => {
//           const targetSensor = data.find((s) => s.node_id === parseInt(neighborId));
//           if (targetSensor) {
//             links.push({ source: sensor, target: targetSensor });
//           }
//         });
//       }
//     });

//     return links;
//   };

//   return <svg ref={chartRef} />;
// };

// export default SensorChart;
