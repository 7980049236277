import React, { useEffect, useState } from 'react';
import { IChildPanelInfoProps } from '../../../types/main';
import { IDataForAPI, apiLink } from '../../radApi';

interface FileData {
  id: number;
  file: string;
  type: string;
  sendEmail: boolean;
  emailAddresses: string;
  description: string;
  cost: string;
  project: string;
  uploaded_at: string;
}

interface IFileListProps extends IChildPanelInfoProps {
  refresh: boolean;
}

const FileList2: React.FC<IFileListProps> = ({ childPanelInfoState, setChildPanelInfoState, refresh }) => {
  const dataForAPI: IDataForAPI = {
    userId: childPanelInfoState.userInfo.id,
    key: childPanelInfoState.userInfo.key,
    tagIds: [],
    status: 1,
    encryptData: childPanelInfoState.mainPanelInfo.system.encryptData
  }

  const [files, setFiles] = useState<FileData[]>([]);
  const [typeFilter, setTypeFilter] = useState<string>('');
  const [projectFilter, setProjectFilter] = useState<string>('');

  //   piLink + '/executive/images_api/'÷file_upload file_list file_delete

  const fetchFiles = async () => {
    try {
      // const response = await fetch(`${apiLink}/executive/file_list/?type=${typeFilter}&project=${projectFilter}`);
      const response = await fetch(`${apiLink}/executive/file_list/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        // credentials: 'include',  // Optional, include cookies for session-based authentication
        body: JSON.stringify({
          user_id: dataForAPI.userId,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('data---->', data);
      setFiles(data.files);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  const handleDelete = async (id: number) => {
    try {
      const response = await fetch(`${apiLink}/executive/file_delete/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        // credentials: 'include',  // Optional, include cookies for session-based authentication
        body: JSON.stringify({
          user_id: dataForAPI.userId,
          file_id: id
        })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setFiles(files.filter(file => file.id !== id));
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [typeFilter, projectFilter, refresh]);

  return (
    <div>
      <div className='spacer-10' />
      <div>
        <input
          type="text"
          placeholder="Filter by Project"
          value={projectFilter}
          onChange={(e) => setProjectFilter(e.target.value)}
        />
        <div className='spacer-10' />
        <input
          type="text"
          placeholder="Filter by Type"
          value={typeFilter}
          onChange={(e) => setTypeFilter(e.target.value)}
        />
      </div>
      <div className='spacer-10' />
      <table>
        {files.map((file, index) => (
          <tr key={file.id} style={{ position: "relative" }}>


            <td key={index} style={{ width: "200px", margin: 0, padding: 0 }}>
              <img src={apiLink + file.file} alt={`Uploaded ${index}`} style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
            </td>


            <td>
              File: <a href={apiLink + file.file} download={file.file}><span style={{ color: "#fff" }}>{file.file}</span></a><br />
              Project: {file.project}<br />
              Type: {file.type}<br />
              Description: {file.description}<br />
              Cost: {file.cost}<br />
              Uploaded at: {new Date(file.uploaded_at).toLocaleString()}
              <button style={{ position: "absolute", margin: "5px", right: 0, top: 0, backgroundColor: childPanelInfoState.mainPanelInfo.styles.dangerButtonColor }} onClick={() => handleDelete(file.id)}>Delete</button> </td>
            {/* <button style={{ position: "absolute", margin: "0 0 0 5px", backgroundColor: childPanelInfoState.mainPanelInfo.styles.dangerButtonColor }} onClick={() => handleDelete(file.id)}>Delete</button> */}

          </tr>
        ))}
      </table>
      <div className='spacer-10' />
    </div>
  );
};

export default FileList2;
