import React, { useEffect, useRef, useState } from 'react';
import ThreeDPlane from './ThreeDPlane';
import { Temp1 } from './Temp1';

import { useFrame } from 'react-three-fiber';
// import { OrbitControls } from '@react-three/drei';
import { Canvas } from 'react-three-fiber';
import { BufferAttribute, BufferGeometry, PlaneGeometry, Sphere } from 'three';
import { Visualization } from './Visualization';
import { D3 } from './D3';

import { IChildPanelInfoProps } from '../../../types/main';
import { DotLine } from './DotLine';
import { generateDataPoints, generateDaysData } from './dataGenerators';import { SelectionControls } from './selection-controls/SelectionControls';
import { ScoreCalculator } from './score-calculator/ScoreCalculator';
import { VisualizationSave } from './VisualizationSave';
import { ATest001 } from './ATest001';
import { ATest002 } from './ATest002';
import { ATest003 } from './ATest003';
import { ATest004 } from './ATest004';
import { formatDateToTimezone } from '../../helpers/mainHelpers';



export interface DataPoint {
  x: number;
  y: number;
  z: number;
  hourData: HourData;
}

export interface ISensorData {
  temperature: number;
  humidity: number;
  luminance: number;
  ultraviolet: number;
  vaporPressure: number;
  notes: string;
}

export interface ISensorInfo {
  showSensor: boolean;
  sensorName: string;
  colors: string[];
  sensorData: ISensorData;
}

export interface HourData {
  time: string;
  day: number;
  hour: number;
  //
  sensorInfo: ISensorInfo[];
}

export interface ISelectionData {
  startDate?: Date;
  startDateString?: string;
  date: string;
  time: string;
  timezoneOffset: number;
  //
  numberOfDays: number;
  maxDataPoints: number;
  filteredData?: HourData[];
  data?: HourData[];

  showCompare: boolean;
  showSensor1: boolean;
  showSensor2: boolean;
  showSensor3: boolean;
  showSensor4: boolean;

  showLight: boolean;
  showVaporPressure: boolean;
}

type TemperatureUnit = 'F' | 'C';

const calculateVaporPressure = (
  temperature: number,
  unit: TemperatureUnit = 'F'
): number => {
  if (unit === 'C') {
    // Convert Celsius to Fahrenheit
    temperature = (temperature * 9) / 5 + 32;
  }
  // Clausius-Clapeyron equation
  const vaporPressure = 4.5841 * Math.exp((18.678 - temperature / 234.5) * (temperature / (257.14 + temperature)));
  return vaporPressure;
};

const addVaporPressureToData =  (hoursData: HourData[]) : HourData[] => {
  //
  return hoursData;
}

export const Temperature: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  const [hoursDataState, setHoursDataState] = useState<HourData[]>([]);
  const [pointsDataState, setPointsDataState] = useState<DataPoint[]>([]);
  const [filteredData, setFilteredData] = useState<HourData[]>([]);
  const [selectionDataState, setSelectionDataState] = useState<ISelectionData>({
    date:'2024-01-01',
    time:'00:00',
    timezoneOffset:0,
    numberOfDays: 4,
    maxDataPoints: 24,
    showSensor1: true,
    showSensor2: false,
    showSensor3: false,
    showSensor4: false,
    showLight: false,
    showVaporPressure: false,
    showCompare: false
  });
  const [clickedButton, setClickedButton] = useState<number | null>(1);
  const handleButtonClick = (buttonNumber: number) => {
    setClickedButton(buttonNumber);
    // Add your custom logic here
  };
  const runOnceRef = useRef(false);

  // If selected data  changes
  useEffect(() => {
    // if (runOnceRef.current) {return;}
    // else {runOnceRef.current = true;}
    console.log('selectionDataState',selectionDataState);
    const startDate = formatDateToTimezone(selectionDataState.date, selectionDataState.time, selectionDataState.timezoneOffset);
    const hoursData = generateDaysData(startDate,
      4,
      [['red','darkred'],['blue','darkblue'],['green','darkgreen'],['yellow','darkkaki']],
      //
      ['Sensor z1','Sensor z2','Sensor z3','Sensor z4'],
      [65,36,45,60],
      [65,36,45,60]);
  ////selectionDataState.numberOfDays,
  
  const vaporPressureAddedData:HourData[] = addVaporPressureToData(hoursData);

  setHoursDataState(hoursData);
  const endDate = new Date(startDate);
  endDate.setDate(endDate.getDate() + selectionDataState.numberOfDays);
  const selectedDateObject = new Date(startDate).getTime();


  childPanelInfoState.mainPanelInfo.header.jsonMessage = hoursData;


  
const dataPoints: DataPoint[] = generateDataPoints(hoursData);

setPointsDataState(dataPoints);






}, [selectionDataState]);
  
  return (
    <div>
      <div className='spacer-10' />

      <div id="buttonRow">
        <button onClick={() => handleButtonClick(1)}>2D</button>
        <span  className='width-spacer-10' />
        <button onClick={() => handleButtonClick(2)}>3D</button>
      </div>
      
      {clickedButton === 1 &&
        <DotLine hoursData={hoursDataState} selectionDataState={selectionDataState} 
        childPanelInfoState={childPanelInfoState}
        setChildPanelInfoState={setChildPanelInfoState}
        width={childPanelInfoState.mainPanelInfo.center.width-110} />
      }
      {clickedButton === 2 && <>
      {/* <VisualizationSave dataPoints={pointsDataState}
        width={childPanelInfoState.mainPanelInfo.center.width-50} 
        height={200} /> */}
        {/* <ATest001 width={childPanelInfoState.mainPanelInfo.center.width-50} height={200} /> */}
        <ATest004 width={childPanelInfoState.mainPanelInfo.center.width-50} height={200} data={pointsDataState}/>
        </>
      }

      {hoursDataState.length.toString()} Records
      <div className='spacer-10'/>
      <SelectionControls selectionDataState={selectionDataState} setSelectionDataState={setSelectionDataState}/>
      <ScoreCalculator goalNumber={68}/>
      {true && <div>
        <div className='spacer-10'/>
        <div className="date-list">
        {/* {JSON.stringify(hoursDataState, null, 2)} */}
      </div>
        <div className='spacer-10'/>
        hoursDataState:
      <div className="data-points-list">
        {hoursDataState.toString()}
        <br />
        {pointsDataState.map((point, index) => (
          <div key={index} className="data-point">
            <span>X: {point.x}</span>
            <span>Y: {point.y}</span>
            <span>Z: {point.z}</span>
          </div> 
        ))}
      </div >
</div>
      }
      
      

      {/* <Canvas
        style={{ background: 'gray', height: '100vh', width: '700px' }}
        camera={{ position: [5, 5, 5], fov: 75 }}
      >
        <DataPoints dataPoints={dataPoints} />
        <axesHelper args={[5]} />
        <OrbitControls />
      </Canvas> */}
    </div>
  );
};




// const calculateActualVaporPressure = (temperature, unit) => {
//   const toCelsius = (temp) => (temp - 32) * (5 / 9);

//   const convertTemperature = (temp, currentUnit) => {
//     return currentUnit === 'C' ? temp : toCelsius(temp);
//   };

//   const toFahrenheit = (temp) => temp * (9 / 5) + 32;

//   const calculateVaporPressure = (temp) => {
//     const celsiusTemp = toCelsius(temp);
//     return 6.112 * Math.exp((17.67 * celsiusTemp) / (celsiusTemp + 243.5));
//   };

//   const temperatureCelsius = convertTemperature(temperature, unit);
//   const vaporPressure = calculateVaporPressure(temperatureCelsius);

//   return vaporPressure;
// };


// 

// const DataPoints: React.FC<{ dataPoints: DataPoint[] }> = ({ dataPoints }) => {
//   return (
//     <>
//       {dataPoints.map((point, index) => (
//         <mesh key={index} position={[point.x, point.y, point.z]}>
//           <sphereGeometry args={[0.1, 16, 16]} />
//           <meshBasicMaterial color={0x00ff00} />
//         </mesh>
//       ))}
//     </>
//   );
// };




  // const handleClick = (buttonNumber: number) => {
  //   alert(`Button ${buttonNumber} clicked!`);
  //   // Add your custom logic here
  // };


  

  // const dataPoints: DataPoint[] = [
  //   { x: .1, y: .1, z: .1 },
  //   { x: .1, y: .2, z: .2 },
  //   { x: .1, y: .3, z: .3 },
  //   { x: .2, y: .4, z: .1 },
  //   { x: .2, y: .5, z: .2 },
  //   { x: .2, y: .6, z: .3 },
  //   { x: .3, y: .7, z: .1 },
  //   { x: .3, y: .8, z: .2 },
  //   { x: .3, y: .9, z: .3 },
  // ];

// const showGeneratedDaysData = generateDaysData(5);

// const transformedData = showGeneratedDaysData.map((entry: HourData, index: number) => ({
//   x: Math.floor(index / 24), // x value increments every 24 hours (1 day)
//   y: entry.temperature,
//   z: index % 24, // z value is the remainder after dividing by 24 (hour within the day)
// }));


// console.log('showGeneratedDaysData', showGeneratedDaysData);
// console.log('transformedData', transformedData);

// // home update - Thu, 11 Feb 2021 16:59:36 GMT
// // {`green-tempature`:47.3,`green-humidity`:88.0,`green-light`:62.0,`weather-home`:`cloudy`,`sun-sun`:`above_horizon`,`dining-room-temperature`:68.4,`dining-room-humidity`:29.0,`office-temperature`:67.2,`office-humidity`:33.0,`outside-temp`:30.2,`outside-humidity`:70}



// // const arrayOfPoints: DataPoint[] = [
// //   { 0, 68, 0 },

// // ]

// const arrayOfPoints: DataPoint[] = [
//   { x: 0, y: 68, z: 0 },
//   { x: 0, y: 68, z: 1 },
//   { x: 0, y: 68, z: 2 },
//   { x: 0, y: 68, z: 3 },
//   { x: 0, y: 68, z: 4 },
//   { x: 0, y: 68, z: 5 },
//   { x: 0, y: 68, z: 6 },
//   { x: 0, y: 68, z: 7 },
//   { x: 0, y: 68, z: 8 },
//   { x: 0, y: 68, z: 9 },
//   { x: 0, y: 68, z: 10 },
//   { x: 0, y: 68, z: 11 },
//   { x: 0, y: 68, z: 12 },
//   { x: 0, y: 68, z: 13 },
//   { x: 0, y: 68, z: 14 },
//   { x: 0, y: 68, z: 15 },
//   { x: 0, y: 68, z: 16 },
//   { x: 0, y: 68, z: 17 },
//   { x: 0, y: 68, z: 18 },
//   { x: 0, y: 68, z: 19 },
//   { x: 0, y: 68, z: 20 },
//   { x: 0, y: 68, z: 21 },
//   { x: 0, y: 68, z: 22 },
//   { x: 0, y: 68, z: 23 },

//   { x: 1, y: 68, z: 0 },
//   { x: 1, y: 68, z: 1 },
//   { x: 1, y: 68, z: 2 },
//   { x: 1, y: 68, z: 3 },
//   { x: 1, y: 68, z: 4 },
//   { x: 1, y: 68, z: 5 },
//   { x: 1, y: 68, z: 6 },
//   { x: 1, y: 68, z: 7 },
//   { x: 1, y: 68, z: 8 },
//   { x: 1, y: 68, z: 9 },
//   { x: 1, y: 68, z: 10 },
//   { x: 1, y: 68, z: 11 },
//   { x: 1, y: 68, z: 12 },
//   { x: 1, y: 68, z: 13 },
//   { x: 1, y: 68, z: 14 },
//   { x: 1, y: 68, z: 15 },
//   { x: 1, y: 68, z: 16 },
//   { x: 1, y: 68, z: 17 },
//   { x: 1, y: 68, z: 18 },
//   { x: 1, y: 68, z: 19 },
//   { x: 1, y: 68, z: 20 },
//   { x: 1, y: 68, z: 21 },
//   { x: 1, y: 68, z: 22 },
//   { x: 1, y: 68, z: 23 },
// ];


// const hoursData = [
//   { time: "Mon, 1 Jan 2024 00:00:00 GMT", temperature: 60, humidity: 60 },
//   { time: "Mon, 1 Jan 2024 01:00:00 GMT", temperature: 58, humidity: 61 },
//   { time: "Mon, 1 Jan 2024 02:00:00 GMT", temperature: 57, humidity: 62 },
//   { time: "Mon, 1 Jan 2024 03:00:00 GMT", temperature: 59, humidity: 60 },
//   { time: "Mon, 1 Jan 2024 04:00:00 GMT", temperature: 60, humidity: 62 },
//   { time: "Mon, 1 Jan 2024 05:00:00 GMT", temperature: 58, humidity: 63 },
//   { time: "Mon, 1 Jan 2024 06:00:00 GMT", temperature: 60, humidity: 65 },
//   { time: "Mon, 1 Jan 2024 07:00:00 GMT", temperature: 61, humidity: 64 },
//   { time: "Mon, 1 Jan 2024 08:00:00 GMT", temperature: 60, humidity: 63 },
//   { time: "Mon, 1 Jan 2024 09:00:00 GMT", temperature: 62, humidity: 65 },
//   { time: "Mon, 1 Jan 2024 10:00:00 GMT", temperature: 60, humidity: 63 },
//   { time: "Mon, 1 Jan 2024 11:00:00 GMT", temperature: 59, humidity: 61 },
//   { time: "Mon, 1 Jan 2024 12:00:00 GMT", temperature: 57, humidity: 62 },
//   { time: "Mon, 1 Jan 2024 13:00:00 GMT", temperature: 56, humidity: 60 },
//   { time: "Mon, 1 Jan 2024 14:00:00 GMT", temperature: 58, humidity: 61 },
//   { time: "Mon, 1 Jan 2024 15:00:00 GMT", temperature: 56, humidity: 60 },
//   { time: "Mon, 1 Jan 2024 16:00:00 GMT", temperature: 58, humidity: 62 },
//   { time: "Mon, 1 Jan 2024 17:00:00 GMT", temperature: 60, humidity: 61 },
//   { time: "Mon, 1 Jan 2024 18:00:00 GMT", temperature: 59, humidity: 60 },
//   { time: "Mon, 1 Jan 2024 19:00:00 GMT", temperature: 61, humidity: 62 },
//   { time: "Mon, 1 Jan 2024 20:00:00 GMT", temperature: 59, humidity: 61 },
//   { time: "Mon, 1 Jan 2024 21:00:00 GMT", temperature: 57, humidity: 63 },
//   { time: "Mon, 1 Jan 2024 22:00:00 GMT", temperature: 55, humidity: 60 },
//   { time: "Mon, 1 Jan 2024 23:00:00 GMT", temperature: 56, humidity: 62 }
// ];

// interface DataPoint {
//   x: number;
//   y: number;
//   z: number;
// }

// const PlaneWithDataPoints: React.FC<{ dataPoints: DataPoint[] }> = ({ dataPoints }) => {
//   // Extract x, y, z coordinates from data points
//   const vertices = new Float32Array(dataPoints.flatMap(({ x, y, z }) => [x, y, z]));

//   // Create BufferGeometry with vertices
//   const geometry = new BufferGeometry();
//   geometry.setAttribute('position', new BufferAttribute(vertices, 3));

//   return (
//     <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]}>
//       <primitive object={geometry} />
//       <meshBasicMaterial color={0x00ff00} side={2} />
//     </mesh>
//   );
// };

// export const Temperature: React.FC = () => {
//   const dataPoints: DataPoint[] = [
//     { x: 1, y: 17, z: 1 },
//     { x: 1, y: 19, z: 2 },
//     { x: 1, y: 17, z: 3 },
//     { x: 2, y: 19, z: 1 },
//     { x: 2, y: 17, z: 2 },
//     { x: 2, y: 20, z: 3 },
//     { x: 3, y: 21, z: 1 },
//     { x: 3, y: 19, z: 2 },
//     { x: 3, y: 18, z: 3 },
//   ];

//   return (
//     <div>
//       <h1>3D Plane Example with Data Points</h1>
//       <Canvas
//         style={{ background: 'gray', height: '100vh', width: '700px' }}
//         camera={{ position: [5, 5, 5], fov: 75 }}
//       >
//         <PlaneWithDataPoints dataPoints={dataPoints} />
//         <axesHelper args={[5]} />
//         <OrbitControls />
//       </Canvas>
//     </div>
//   );
// };

// const Plane: React.FC = () => {
//   const planeGeometry = new PlaneGeometry(10, 10, 10, 10);

//   return (
//     <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]}>
//       <primitive object={planeGeometry} />
//       <meshBasicMaterial color={0x00ff00} side={2} />
//     </mesh>
//   );
// };

// const CameraInfo: React.FC = () => {
//   const camera = useRef<THREE.PerspectiveCamera>();

//   useFrame(() => {
//     if (camera.current) {
//       console.log('Camera Position:', camera.current.position.toArray());
//       console.log('Camera Rotation:', camera.current.rotation.toArray());
//     }
//   });

//   return null;
// };

// export const Temperature: React.FC = () => {
//   return (
//     <div>
//       <h1>3D Plane Example</h1>
//       <Canvas
//         style={{ background: 'gray', height: '600px', width: '600px' }}
//         camera={{ position: [5, 5, 5], fov: 75 }}
//       >
//         <CameraInfo />
//         <Plane />
//         <axesHelper args={[5]} />
//         <OrbitControls />
//       </Canvas>
//     </div>
//   );
// };

// const Plane: React.FC = () => {
//   const planeGeometry = new PlaneGeometry(10, 10, 10, 10);

//   return (
//     <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]}>
//       <primitive object={planeGeometry} />
//       <meshBasicMaterial color={0x00ff00} side={2} />
//     </mesh>
//   );
// };

// const CameraInfo: React.FC = () => {
//   const camera = useRef<THREE.PerspectiveCamera>();

//   useFrame(() => {
//     if (camera.current) {
//       console.log('Camera Position:', camera.current.position.toArray());
//     }
//   });

//   return null;
// };

// export const Temperature: React.FC = () => {
//   return (
//     <div>
//       <h1>3D Plane Example</h1>
//       <Canvas
//         style={{ background: 'gray', height: '100vh', width: '700px' }}
//         camera={{ position: [0, 5, 0], fov: 75, rotation: [-Math.PI / 2, 0, 0] }}
//       >
//         <CameraInfo />
//         <Plane />
//         <axesHelper args={[5]} />
//         <OrbitControls />
//       </Canvas>
//     </div>
//   );
// };

// import { BufferGeometry, BufferAttribute, PlaneGeometry } from 'three';

// interface DataPoint {
//   x: number;
//   y: number;
//   z: number;
// }

// interface PlaneProps {
//   data: DataPoint[];
// }

// const Plane: React.FC<PlaneProps> = ({ data }) => {
//   const planeGeometry = new PlaneGeometry(10, 10, 10, 10);
//   const position = new Float32Array(planeGeometry.attributes.position.array.length);

//   data.forEach((point) => {
//     const index = (point.x + 5) * 10 + (point.z + 5);
//     position[index * 3 + 1] = point.y;
//   });

//   planeGeometry.setAttribute('position', new BufferAttribute(position, 3));

//   return (
//     <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]}>
//       <primitive object={planeGeometry} />
//       <meshBasicMaterial color={0x00ff00} side={2} />
//     </mesh>
//   );
// };

// export const Temperature: React.FC = () => {
//   const dataPoints: DataPoint[] = [
//     { x: 1, y: 17, z: 1 },
//     { x: 1, y: 19, z: 2 },
//     { x: 1, y: 17, z: 3 },
//     { x: 2, y: 19, z: 1 },
//     { x: 2, y: 17, z: 2 },
//     { x: 2, y: 20, z: 3 },
//     { x: 3, y: 21, z: 1 },
//     { x: 3, y: 19, z: 2 },
//     { x: 3, y: 18, z: 3 },
//     // Add more data points as needed
//   ];

//   return (
//     <div>
//       <h1>3D Plane Example</h1>
//       <Canvas style={{ background: 'gray' }}>
//         <Plane data={dataPoints} />
//         {/* <axesHelper args={[5]} />
//         <OrbitControls /> */}
//       </Canvas>
//     </div>
//   );
// };

