import React from 'react';
import { useEffect, useRef, useState } from 'react';
import './money.scss';
import { IDataReturn, IDataForAPI, IPostData, fetchPostsByTags, handleAdd, handleDelete, handleUpdate } from '../../radApi';
import { AddMoney } from './AddMoney';
import { IChildPanelInfoProps } from '../../../types/main';
import { convertLineToObject, getByYear, moneyOptionsLookUp, peopleLookUp } from '../../helpers/moneyHelpers';
import { ResultsByItem } from './ResultsByItem';
import { ResultsByMonth } from './ResultsByMonth';
import { IMoney } from '../../../types/money';
import { ResultsByPerson } from './ResultsByPerson';
import { ResultList } from './ResultList';

export interface ISelectedItem {
  itemSelected: string;
  averageSpent: number;
}

export const Money: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  const dataForAPI: IDataForAPI = {
    userId: childPanelInfoState.userInfo.id,
    key: childPanelInfoState.userInfo.key,
    tagIds: [4],
    status: 1,
    encryptData: childPanelInfoState.mainPanelInfo.system.encryptData
  }

  const [allPosts, setAllPosts] = useState<IPostData[]>([]);
  const [moneyList, setMoneyList] = useState<IMoney[]>([]);
  const [oldMoneyList, setOldMoneyList] = useState<IMoney[]>([]);
  const [sortedTableData, setSortedTableData] = useState<IMoney[]>([]);
  const [moneyByMonth, setMoneyByMonth] = useState<Map<number, Map<number, number[]>>>(new Map());

  const inputData2 = `Who	What	Option	How Much	When	Actions
  nic	adt 5 2024	half	63.14	2024-05-14T15:10:14.000-04:00	Delete
  nic	car ins 5 2024	half	335.51	2024-05-14T15:10:00.000-04:00	Delete
  nic	car ins 4 2024	half	335.51	2024-05-14T15:09:45.000-04:00	Delete
  wil	clair sitter	half	80.00	2024-05-14T15:08:11.000-04:00	Delete
  nic	chesterbrook (5/12)	half	309.00	2024-05-14T15:07:49.000-04:00	Delete
  nic	chesterbrook (5/5)	half	309.00	2024-05-08T09:47:29.000-04:00	Delete
  nic	chesterbrook (fees 3/19-4/9)	half	29.04	2024-05-03T17:04:20.000-04:00	Delete
  nic	adt 4 2024	half	63.14	2024-05-03T17:03:15.000-04:00	Delete
  nic	portrait frame	half	837.00	2024-05-03T08:10:22.000-04:00	Delete
  nic	chesterbrook (4/28)	half	309.00	2024-05-01T12:39:59.000-04:00	Delete
  wil	clair sitter	half	10.00	2024-05-01T12:34:55.000-04:00	Delete
  nic	clair sitter	half	60.00	2024-05-01T12:34:37.000-04:00	Delete
  nic	sewer	half	50.20	2024-04-27T19:24:42.000-04:00	Delete
  nic	peco	half	227.21	2024-04-27T19:24:28.000-04:00	Delete
  nic	mortgage	half	788.59	2024-04-27T19:24:04.000-04:00	Delete
  nic	sitter	half	100.00	2024-04-26T08:30:51.000-04:00	Delete
  nic	chesterbrook (4/21)	half	309.00	2024-04-23T15:00:40.000-04:00	Delete
  nic	clair sitter	half	70.00	2024-04-23T15:00:26.000-04:00	Delete
  wil	Mulch	half	25.00	2024-04-20T10:41:05.000-04:00	Delete`

  const inputData = `wil	Mulch	half	25.00	2024-04-20T10:41:05.000-04:00	Delete
  wil	Sitter	half	100.00	2024-04-20T10:40:04.000-04:00	Delete
  nic	clair sitter	half	60.00	2024-04-16T10:44:20.000-04:00	Delete
  nic	chesterbrook (4/14)	half	309.00	2024-04-16T10:40:13.000-04:00	Delete
  wil	Fireside	half	98.00	2024-04-16T10:04:13.000-04:00	Delete
  wil	Founders card	half	4990.00	2024-04-16T10:02:10.000-04:00	Delete
  nic	costco	half	490.25	2024-04-16T09:35:19.000-04:00	Delete
  nic	advance auto parts on our credit card	all	352.70	2024-04-11T13:55:06.000-04:00	Delete
  nic	chesterbrook (4/7)	half	309.00	2024-04-09T14:59:48.000-04:00	Delete
  wil	Sitter	half	100.00	2024-04-08T19:07:34.000-04:00	Delete
  nic	chesterbrook (3/31)	half	309.00	2024-04-03T10:01:42.000-04:00	Delete
  nic	DJ flight to Florida for baptism	half	444.94	2024-04-01T19:51:05.000-04:00	Delete
  nic	jaime dogs	half	660.00	2024-04-01T19:49:51.000-04:00	Delete
  nic	local tax	half	107.00	2024-03-30T16:01:01.000-04:00	Delete
  nic	uncle jimmy flight for baptism	half	326.20	2024-03-30T15:50:30.000-04:00	Delete
  nic	real estate taxes	half	1861.50	2024-03-30T15:40:59.000-04:00	Delete
  nic	mortgage	half	788.59	2024-03-30T15:36:51.000-04:00	Delete
  nic	oil 	half	727.27	2024-03-30T15:31:02.000-04:00	Delete
  nic	peco	half	257.31	2024-03-30T15:27:12.000-04:00	Delete
  nic	sewer	half	116.67	2024-03-30T15:21:24.000-04:00	Delete
  nic	car ins 3 2024	half	335.51	2024-03-29T17:16:28.000-04:00	Delete
  nic	adt 3 2024	half	63.14	2024-03-29T17:14:46.000-04:00	Delete
  nic	chesterbrook (fees 2/13-3/12)	half	37.24	2024-03-29T17:14:06.000-04:00	Delete
  nic	chesterbrook (3/24)	half	309.00	2024-03-27T08:52:46.000-04:00	Delete
  nic	clair sitter	half	160.00	2024-03-27T06:50:56.000-04:00	Delete
  nic	kids dinner	half	17.00	2024-03-27T06:50:28.000-04:00	Delete
  wil	Founders card	half	4420.00	2024-03-20T12:42:11.000-04:00	Delete
  wil	Sitter	half	60.00	2024-03-20T12:16:29.000-04:00	Delete
  nic	chesterbrook (3/17)	half	309.00	2024-03-19T14:19:35.000-04:00	Delete
  nic	sitter	half	90.00	2024-03-15T13:13:04.000-04:00	Delete
  wil	clair sitter	half	100.00	2024-03-15T13:12:43.000-04:00	Delete
  nic	chesterbrook (3/10)	half	309.00	2024-03-13T11:09:59.000-04:00	Delete
  wil	Sitter	half	60.00	2024-03-11T18:22:13.000-04:00	Delete
  nic	clair sitter	half	100.00	2024-03-07T15:11:11.000-05:00	Delete
  wil	Peco	half	423.43	2024-03-06T13:43:57.000-05:00	Delete
  wil	Water	half	106.88	2024-03-06T13:42:54.000-05:00	Delete
  nic	mortgage	half	788.59	2024-03-06T13:22:04.000-05:00	Delete
  nic	sitter	half	110.00	2024-03-06T09:54:15.000-05:00	Delete
  nic	clair sitter	half	60.00	2024-03-06T09:53:56.000-05:00	Delete
  nic	chesterbrook (3/3)	half	309.00	2024-03-06T09:53:27.000-05:00	Delete
  wil	Allie	half	300.00	2024-03-05T16:52:08.000-05:00	Delete
  nic	meatballs and lemon soda	half	18.00	2024-03-04T09:07:08.000-05:00	Delete
  nic	jaime dogs	half	5.00	2024-03-04T09:06:21.000-05:00	Delete
  nic	clair sitter	half	60.00	2024-02-27T12:27:14.000-05:00	Delete
  nic	chesterbrook (2/25)	half	309.00	2024-02-27T12:26:52.000-05:00	Delete
  nic	adt 2 2024	half	63.14	2024-02-23T14:17:52.000-05:00	Delete
  nic	car ins 2 2024	half	335.51	2024-02-23T14:17:30.000-05:00	Delete
  nic	olm arts class	half	150.00	2024-02-23T14:15:59.000-05:00	Delete
  nic	clair sitter	half	100.00	2024-02-23T07:45:11.000-05:00	Delete
  nic	wills salad	all	20.00	2024-02-23T07:44:58.000-05:00	Delete
  nic	chesterbrook (2/18) plus reenroll	half	349.00	2024-02-21T08:33:55.000-05:00	Delete
  nic	sitter	half	120.00	2024-02-21T08:00:09.000-05:00	Delete
  nic	chesterbrook cc fees 1/23-2/14 $7.26/week	half	29.04	2024-02-20T15:16:56.000-05:00	Delete
  nic	mt house cleaners	half	85.00	2024-02-20T14:01:21.000-05:00	Delete
  nic	laundry baskets	half	20.00	2024-02-20T14:00:58.000-05:00	Delete
  nic	drinks club house	half	12.00	2024-02-20T14:00:46.000-05:00	Delete
  nic	baby wipes	half	42.00	2024-02-16T10:07:11.000-05:00	Delete
  nic	clair sitter	half	100.00	2024-02-15T14:11:46.000-05:00	Delete
  nic	chesterbrook (2/11)	half	309.00	2024-02-13T17:36:48.000-05:00	Delete
  wil	Founders card	half	3822.09	2024-02-13T13:14:41.000-05:00	Delete
  nic	clair sitter	half	70.00	2024-02-07T08:13:35.000-05:00	Delete
  nic	chesterbrook (2/4)	half	309.00	2024-02-07T08:13:24.000-05:00	Delete
  nic	clair sitter	half	60.00	2024-02-05T16:27:54.000-05:00	Delete
  nic	duffy	half	693.03	2024-02-02T16:56:16.000-05:00	Delete
  nic	old navy kids shirts	half	24.73	2024-02-02T16:47:35.000-05:00	Delete
  nic	peco	half	411.70	2024-02-02T16:43:44.000-05:00	Delete
  nic	sewer	half	50.20	2024-02-02T16:42:42.000-05:00	Delete
  nic	mortgage	half	788.59	2024-02-02T16:38:02.000-05:00	Delete
  nic	trampoline park	half	68.00	2024-01-30T16:50:38.000-05:00	Delete
  nic	allie	half	50.00	2024-01-30T16:50:08.000-05:00	Delete
  nic	chesterbrook (1/28)	half	309.00	2024-01-30T16:49:29.000-05:00	Delete
  wil	Allie	half	300.00	2024-01-29T17:30:44.000-05:00	Delete
  nic	pay off our credit card	half	2000.00	2024-01-26T12:24:02.000-05:00	Delete
  nic	ned stevens gutter cleaning	half	199.78	2024-01-25T18:13:41.000-05:00	Delete
  nic	adt 1 2024	half	63.14	2024-01-25T18:05:12.000-05:00	Delete
  nic	car ins 1 2024	half	335.51	2024-01-25T18:04:46.000-05:00	Delete
  nic	chesterbrook (cc fees 12/17-1/21 $7.26/week)	half	43.56	2024-01-25T18:03:21.000-05:00	Delete
  nic	chesterbrook (1/21)	half	309.00	2024-01-24T09:38:29.000-05:00	Delete
  nic	clair sitter	half	80.00	2024-01-24T06:40:23.000-05:00	Delete
  wil	Founders card	half	2050.00	2024-01-22T12:18:10.000-05:00	Delete
  nic	ups return dj gift from allie	half	15.00	2024-01-20T17:24:11.000-05:00	Delete
  nic	vac repair	half	132.48	2024-01-20T17:23:41.000-05:00	Delete
  nic	whole foods	half	229.00	2024-01-20T17:23:20.000-05:00	Delete
  nic	fireside	half	141.00	2024-01-20T17:22:51.000-05:00	Delete
  nic	chesterbrook (1/14)	half	309.00	2024-01-17T12:29:07.000-05:00	Delete
  nic	clair sitter	half	70.00	2024-01-15T18:00:52.000-05:00	Delete
  wil	Comcast (Jan)	half	85.00	2024-01-12T18:48:33.000-05:00	Delete
  wil	Phone (Jan)	half	182.77	2024-01-12T18:47:32.000-05:00	Delete
  wil	Dj haircut	half	27.00	2024-01-12T18:46:31.000-05:00	Delete
  nic	chesterbrook (1/9)	half	309.00	2024-01-10T07:51:51.000-05:00	Delete
  wil	Allie	half	150.00	2024-01-04T16:06:42.000-05:00	Delete
  nic	chesterbrook cc fees 11/16-12/12	half	44.74	2024-01-01T16:31:55.000-05:00	Delete
  nic	wills dentist apt	all	37.20	2024-01-01T16:28:33.000-05:00	Delete
  nic	cvs diapers	half	61.25	2024-01-01T16:03:03.000-05:00	Delete
  nic	weavers way	half	62.61	2024-01-01T16:02:43.000-05:00	Delete
  wil	Phone (Dec)	half	182.77	2023-12-29T10:29:42.000-05:00	Delete
  wil	Comcast (Dec)	half	85.00	2023-12-29T10:28:12.000-05:00	Delete
  nic	chop ER vivia 11 2	half	500.00	2023-12-28T16:31:37.000-05:00	Delete
  nic	chesterbrook (12/31)	half	309.00	2023-12-28T16:26:55.000-05:00	Delete
  nic	chesterbrook 12/24	half	309.00	2023-12-28T16:26:39.000-05:00	Delete
  nic	peco	half	269.26	2023-12-24T15:35:56.000-05:00	Delete
  nic	mortgage	half	788.59	2023-12-24T15:35:41.000-05:00	Delete
  nic	sewer	half	50.20	2023-12-24T15:35:26.000-05:00	Delete
  nic	water	half	155.50	2023-12-24T15:35:12.000-05:00	Delete
  nic	oil yearly contract	half	479.50	2023-12-24T15:34:54.000-05:00	Delete
  nic	car ins 12	half	335.51	2023-12-21T19:52:13.000-05:00	Delete
  nic	adt 12	half	63.14	2023-12-21T19:51:45.000-05:00	Delete
  wil	Allie	half	110.00	2023-12-21T18:53:45.000-05:00	Delete
  nic	clair sitter	half	80.00	2023-12-19T16:29:58.000-05:00	Delete
  nic	chesterbrook 12/17	half	309.00	2023-12-19T16:28:47.000-05:00	Delete
  nic	dinner pizza	half	55.00	2023-12-15T12:38:50.000-05:00	Delete
  nic	clair sitter	half	70.00	2023-12-15T05:59:41.000-05:00	Delete
  nic	chesterbrook 12/10	half	309.00	2023-12-15T05:59:07.000-05:00	Delete
  wil	Founders card	half	1460.53	2023-12-14T18:18:03.000-05:00	Delete
  wil	Founders card	half	2000.00	2023-12-06T12:19:54.000-05:00	Delete
  nic	chesterbrook 12/3	half	309.00	2023-12-06T11:38:09.000-05:00	Delete
  nic	clair sitter	half	110.00	2023-12-04T17:32:49.000-05:00	Delete
  nic	gap xmas	half	292.00	2023-12-04T16:52:00.000-05:00	Delete
  nic	adidas shoes all 3 kids xmas	half	105.81	2023-12-04T16:50:09.000-05:00	Delete
  nic	niya markers xmas	half	8.00	2023-12-04T16:48:38.000-05:00	Delete
  nic	lunch 	all	8.00	2023-12-04T16:48:00.000-05:00	Delete
  nic	clair sitter	half	10.00	2023-12-04T16:47:27.000-05:00	Delete
  wil	clair sitter	half	80.00	2023-12-04T16:47:13.000-05:00	Delete
  nic	jaime dogs groom	half	150.00	2023-11-27T08:50:20.000-05:00	Delete
  nic	go toys	half	92.17	2023-11-25T12:50:09.000-05:00	Delete
  nic	carters clothes	half	68.31	2023-11-25T12:49:55.000-05:00	Delete
  nic	childrens place clothes	half	100.70	2023-11-25T12:49:41.000-05:00	Delete
  nic	MARY RUTHS VITAMINS FOR KIDS	half	114.63	2023-11-25T12:49:10.000-05:00	Delete
  wil	Dj haircut	half	30.00	2023-11-25T11:50:44.000-05:00	Delete
  wil	Founders card	half	2433.62	2023-11-25T11:50:02.000-05:00	Delete
  nic	chesterbrook 11/26	half	309.00	2023-11-23T14:07:53.000-05:00	Delete
  nic	oil yearly contract	half	479.50	2023-11-23T14:06:07.000-05:00	Delete
  nic	jaime dogs	half	900.00	2023-11-23T14:04:49.000-05:00	Delete
  nic	duffy	half	161.21	2023-11-23T13:54:51.000-05:00	Delete
  nic	mortgage	half	788.59	2023-11-23T13:50:18.000-05:00	Delete
  nic	adt 11	half	63.14	2023-11-23T13:50:04.000-05:00	Delete
  nic	car ins 11	half	335.51	2023-11-23T13:49:13.000-05:00	Delete
  nic	sewer	half	50.20	2023-11-23T13:45:54.000-05:00	Delete
  nic	peco	half	181.65	2023-11-23T13:45:29.000-05:00	Delete
  nic	sprouts	half	58.39	2023-11-22T15:21:57.000-05:00	Delete
  nic	chesterbrook 11/19	half	309.00	2023-11-22T13:39:11.000-05:00	Delete
  nic	clair sitter	half	70.00	2023-11-22T13:22:37.000-05:00	Delete
  nic	clair sitter	half	70.00	2023-11-15T05:58:36.000-05:00	Delete
  nic	chesterbrook 11/5 &amp; 11/12 +2 late fees	half	668.00	2023-11-15T05:58:03.000-05:00	Delete
  nic	clair sitter	half	70.00	2023-11-09T15:52:10.000-05:00	Delete
  wil	Founders card	half	2000.00	2023-11-08T13:51:41.000-05:00	Delete
  wil	Driveway Sealed	half	250.00	2023-11-03T17:16:04.000-04:00	Delete
  nic	formula	half	80.00	2023-11-02T16:28:36.000-04:00	Delete
  nic	plumber RO	half	260.00	2023-10-31T15:00:37.000-04:00	Delete
  nic	costco renewal	half	120.00	2023-10-31T15:00:21.000-04:00	Delete
  nic	penn	half	30.00	2023-10-31T14:58:46.000-04:00	Delete
  nic	peco	half	165.62	2023-10-31T14:57:56.000-04:00	Delete
  nic	sewer	half	64.69	2023-10-31T14:57:40.000-04:00	Delete
  nic	mortgage 	half	788.59	2023-10-31T14:57:23.000-04:00	Delete
  nic	adt 10	half	63.14	2023-10-31T14:21:29.000-04:00	Delete
  nic	car ins 10	half	335.51	2023-10-31T14:20:59.000-04:00	Delete
  nic	clair sitter	half	90.00	2023-10-31T13:45:04.000-04:00	Delete
  nic	clair sitter	half	55.00	2023-10-23T17:08:34.000-04:00	Delete
  nic	clair sitter	half	50.00	2023-10-23T17:07:05.000-04:00	Delete
  wil	Founders card	half	3546.27	2023-10-16T12:24:15.000-04:00	Delete
  nic	sprouts	half	296.35	2023-10-16T06:51:13.000-04:00	Delete
  nic	costco	half	352.95	2023-10-16T06:50:37.000-04:00	Delete
  nic	chesterbrook 10/8	half	309.00	2023-10-09T17:23:50.000-04:00	Delete
  nic	chesterbrook 10/1	half	309.00	2023-10-09T17:23:37.000-04:00	Delete
  nic	chesterbrook 9/17	half	309.00	2023-10-09T17:23:18.000-04:00	Delete
  nic	chesterbrook 9/10	half	309.00	2023-10-09T17:22:55.000-04:00	Delete
  wil	Sitter	half	40.00	2023-10-02T16:35:22.000-04:00	Delete
  nic	mortgage	half	788.59	2023-10-02T12:42:45.000-04:00	Delete
  nic	sewer	half	71.18	2023-10-02T12:37:34.000-04:00	Delete
  nic	chesterbrook 9 27	half	309.00	2023-10-02T12:29:29.000-04:00	Delete
  nic	chesterbrook	half	309.00	2023-10-02T12:29:06.000-04:00	Delete
  nic	cvs diapers formula	half	96.82	2023-10-01T08:54:08.000-04:00	Delete
  wil	Dj haircut	half	28.00	2023-09-30T11:58:59.000-04:00	Delete
  wil	Weavers way	half	41.87	2023-09-20T13:09:39.000-04:00	Delete
  wil	Founders card	half	2609.00	2023-09-20T12:52:46.000-04:00	Delete
  nic	pet smart	half	86.88	2023-09-20T11:31:51.000-04:00	Delete
  nic	dj uniform	half	59.75	2023-09-19T15:37:25.000-04:00	Delete
  nic	rite aid	half	44.46	2023-09-19T14:54:55.000-04:00	Delete
  nic	water	half	126.62	2023-09-15T17:02:34.000-04:00	Delete
  wil	Allie	half	450.00	2023-09-14T17:15:30.000-04:00	Delete
  nic	allie	all	40.00	2023-09-12T15:15:34.000-04:00	Delete
  nic	sitter	all	110.00	2023-09-12T15:15:15.000-04:00	Delete
  nic	adt 9	half	63.14	2023-09-07T15:31:39.000-04:00	Delete
  nic	adt 8	half	63.14	2023-09-07T15:31:25.000-04:00	Delete
  nic	car ins 9	half	310.00	2023-09-07T15:31:11.000-04:00	Delete
  nic	car ins 8	half	310.00	2023-09-07T15:30:58.000-04:00	Delete
  nic	sewer	half	59.86	2023-09-07T15:25:26.000-04:00	Delete
  nic	peco	half	192.97	2023-09-07T15:25:11.000-04:00	Delete
  nic	mortgage	half	788.59	2023-09-07T15:24:32.000-04:00	Delete
  wil	Sitter	half	20.00	2023-08-24T11:06:10.000-04:00	Delete
  wil	Founders card	half	1810.23	2023-08-24T11:05:33.000-04:00	Delete
  wil	Sitter	half	20.00	2023-08-18T11:29:18.000-04:00	Delete
  nic	target niya bday	half	323.97	2023-08-16T12:20:52.000-04:00	Delete
  wil	sitter	all	70.00	2023-08-16T12:18:47.000-04:00	Delete
  wil	school tax	all	2853.64	2023-08-16T12:15:50.000-04:00	Delete
  nic	school tax	all	2853.64	2023-08-16T12:15:37.000-04:00	Delete
  nic	sewer	half	59.86	2023-08-16T12:14:14.000-04:00	Delete
  nic	peco	half	245.33	2023-08-16T12:14:00.000-04:00	Delete
  nic	mortgage	half	788.79	2023-08-16T12:13:46.000-04:00	Delete
  nic	gutter cleaning	half	193.42	2023-08-16T12:13:20.000-04:00	Delete
  nic	car ins 7	half	310.00	2023-08-16T12:12:36.000-04:00	Delete
  nic	car ins 6	half	310.00	2023-08-16T12:12:27.000-04:00	Delete
  nic	weavers 7/8	half	149.82	2023-08-16T12:11:50.000-04:00	Delete
  nic	soap	half	82.46	2023-08-16T12:11:14.000-04:00	Delete
  nic	adt 7	half	63.14	2023-08-16T12:10:25.000-04:00	Delete
  wil	Allie	half	150.00	2023-08-14T12:46:10.000-04:00	Delete
  wil	Sitter	half	300.00	2023-07-20T09:52:57.000-04:00	Delete
  wil	Weavers way	half	48.00	2023-07-11T10:14:17.000-04:00	Delete
  wil	Founders card	half	4773.33	2023-07-10T19:42:43.000-04:00	Delete
  wil	Allie	half	150.00	2023-07-10T19:38:13.000-04:00	Delete
  nic	adt 6	half	63.14	2023-06-27T16:01:24.000-04:00	Delete
  nic	penn 	half	150.00	2023-06-27T15:56:16.000-04:00	Delete
  nic	sewer	half	59.86	2023-06-27T15:54:10.000-04:00	Delete
  nic	peco	half	201.90	2023-06-27T15:51:59.000-04:00	Delete
  nic	mortgage	half	788.59	2023-06-27T15:49:32.000-04:00	Delete
  nic	water	half	100.80	2023-06-27T15:44:56.000-04:00	Delete
  wil	tree	half	2000.00	2023-06-27T15:06:51.000-04:00	Delete
  nic	cf w&amp;D lego/choc	half	270.00	2023-06-21T20:12:48.000-04:00	Delete
  nic	costco	half	287.36	2023-06-21T20:11:58.000-04:00	Delete
  nic	babysitter	half	80.00	2023-06-21T20:11:35.000-04:00	Delete
  nic	lego land hotel	half	606.75	2023-06-21T20:11:01.000-04:00	Delete
  wil	Founders card	half	2453.77	2023-06-21T11:26:43.000-04:00	Delete
  wil	Allie	half	250.00	2023-06-09T16:24:29.000-04:00	Delete
  wil	Pictures	half	695.00	2023-06-03T10:31:38.000-04:00	Delete
  wil	mortgage	half	788.59	2023-05-31T18:04:33.000-04:00	Delete
  wil	penn	half	30.00	2023-05-31T18:03:37.000-04:00	Delete
  wil	sewer	half	45.37	2023-05-31T18:03:15.000-04:00	Delete
  wil	peco	half	203.43	2023-05-31T18:02:59.000-04:00	Delete
  nic	adt 5	half	63.14	2023-05-31T18:02:24.000-04:00	Delete
  nic	car ins 5	half	310.00	2023-05-31T18:01:45.000-04:00	Delete
  nic	target kids bday	half	15.09	2023-05-31T18:01:03.000-04:00	Delete
  nic	Weavers way	half	56.12	2023-05-31T18:00:47.000-04:00	Delete
  nic	penn parking	half	22.00	2023-05-31T18:00:28.000-04:00	Delete
  nic	flowers moms birthday	half	75.00	2023-05-31T18:00:11.000-04:00	Delete
  wil	Weavers way	half	38.09	2023-05-24T11:49:02.000-04:00	Delete
  wil	Chesterbrook (for Vivia)	half	485.00	2023-05-21T22:26:03.000-04:00	Delete
  wil	Founders card	half	3131.38	2023-05-20T14:34:06.000-04:00	Delete
  wil	Fireside	half	96.20	2023-05-20T14:31:25.000-04:00	Delete
  wil	Allie	half	300.00	2023-05-19T08:00:08.000-04:00	Delete
  wil	Lowe's	half	86.65	2023-05-19T07:43:17.000-04:00	Delete
  nic	balloons	half	57.31	2023-05-17T18:00:28.000-04:00	Delete
  nic	dj dentist	half	364.00	2023-05-17T17:57:59.000-04:00	Delete
  nic	babysitter	all	36.25	2023-05-06T07:07:41.000-04:00	Delete
  nic	peco	half	289.56	2023-05-02T16:50:56.000-04:00	Delete
  nic	oil	half	392.30	2023-04-29T15:42:04.000-04:00	Delete
  nic	sewer	half	50.20	2023-04-29T15:41:46.000-04:00	Delete
  nic	olm trip	half	17.00	2023-04-29T15:41:23.000-04:00	Delete
  nic	adt 4	half	63.14	2023-04-29T15:40:55.000-04:00	Delete
  nic	mortgage	half	788.59	2023-04-29T15:40:10.000-04:00	Delete
  nic	car ins 4	half	310.00	2023-04-29T15:39:42.000-04:00	Delete
  nic	car ins 3	half	310.00	2023-04-29T15:39:32.000-04:00	Delete
  wil	Founders card	half	2638.79	2023-04-25T13:54:03.000-04:00	Delete
  nic	taxes	all	2016.00	2023-04-24T19:08:38.000-04:00	Delete
  nic	weavers way	half	270.61	2023-04-24T19:07:52.000-04:00	Delete
  nic	dinner jarretown	half	202.00	2023-04-24T19:07:28.000-04:00	Delete
  wil	Sitter	half	130.00	2023-04-05T16:32:57.000-04:00	Delete
  nic	peco	half	341.22	2023-03-25T17:27:56.000-04:00	Delete
  nic	adt 3	half	63.14	2023-03-25T17:27:42.000-04:00	Delete
  nic	taxes real estate	half	1756.48	2023-03-25T17:26:59.000-04:00	Delete
  nic	karate	half	25.00	2023-03-25T17:26:35.000-04:00	Delete
  nic	mortgage	half	788.59	2023-03-25T17:26:12.000-04:00	Delete
  nic	water	half	103.00	2023-03-25T17:25:38.000-04:00	Delete
  nic	oil	half	722.71	2023-03-25T17:25:20.000-04:00	Delete
  nic	sewer	half	50.20	2023-03-25T17:25:07.000-04:00	Delete
  wil	Dj hair cut	half	27.00	2023-03-25T13:04:26.000-04:00	Delete
  wil	Founders card	half	2171.18	2023-03-20T13:54:31.000-04:00	Delete
  wil	Allie	half	300.00	2023-03-20T10:58:54.000-04:00	Delete
  wil	Weavers way	half	172.72	2023-03-05T15:52:03.000-05:00	Delete
  nic	penn med copay obgyn	half	30.00	2023-03-01T11:31:55.000-05:00	Delete
  nic	mortgage	half	788.59	2023-03-01T11:31:38.000-05:00	Delete
  nic	sewer	half	41.97	2023-03-01T11:31:25.000-05:00	Delete
  nic	adt 2	half	63.14	2023-03-01T11:31:06.000-05:00	Delete
  nic	peco	half	369.48	2023-03-01T11:30:37.000-05:00	Delete
  wil	Founders card	half	1941.67	2023-02-22T17:51:44.000-05:00	Delete
  wil	Allie	half	300.00	2023-02-22T17:51:07.000-05:00	Delete
  nic	dinner tip	all	20.00	2023-02-08T09:10:21.000-05:00	Delete
  nic	car ins 1	half	310.00	2023-02-07T16:08:08.000-05:00	Delete
  nic	adt 1	half	58.75	2023-02-07T16:07:52.000-05:00	Delete
  nic	peco	half	358.22	2023-02-07T16:07:32.000-05:00	Delete
  nic	sewer	half	41.24	2023-02-07T16:07:17.000-05:00	Delete
  nic	mortgage	half	788.59	2023-02-07T16:07:05.000-05:00	Delete
  wil	Founders card	half	1833.42	2023-01-25T10:06:16.000-05:00	Delete
  wil	Allie	half	150.00	2023-01-25T07:52:44.000-05:00	Delete
  nic	oil	half	713.31	2023-01-20T07:39:25.000-05:00	Delete
  nic	car ins 1	half	310.00	2023-01-20T07:38:58.000-05:00	Delete
  wil	Sitter	half	20.00	2023-01-18T13:06:13.000-05:00	Delete
  nic	sitter	all	78.75	2023-01-12T15:22:23.000-05:00	Delete
  nic	rite aid diapers	half	43.00	2023-01-06T14:34:06.000-05:00	Delete
  nic	adt 12	half	61.22	2023-01-05T17:53:01.000-05:00	Delete
  nic	car ins 12	half	310.00	2023-01-05T17:52:15.000-05:00	Delete
  nic	jaime	half	100.00	2022-12-31T10:34:46.000-05:00	Delete
  wil	mortgage	half	788.59	2022-12-31T10:34:05.000-05:00	Delete
  wil	oil	half	753.37	2022-12-31T10:33:37.000-05:00	Delete
  nic	peco	half	316.19	2022-12-31T10:32:56.000-05:00	Delete
  nic	aqua water bill	half	146.52	2022-12-31T10:32:42.000-05:00	Delete
  nic	sewer	half	45.63	2022-12-31T10:32:20.000-05:00	Delete
  nic	chop niya admission	half	100.00	2022-12-31T10:31:52.000-05:00	Delete
  nic	pediatrician copay	half	60.00	2022-12-31T10:31:30.000-05:00	Delete
  nic	kids clothes xmas	half	178.00	2022-12-31T10:31:03.000-05:00	Delete
  wil	DJ Gift	half	105.97	2022-12-28T09:36:21.000-05:00	Delete
  wil	Founders card	half	3852.90	2022-12-28T09:33:12.000-05:00	Delete
  nic	gutter cleaning	half	192.78	2022-12-23T15:32:58.000-05:00	Delete
  nic	Chesterbrook (12/28)	half	283.00	2022-12-22T20:23:53.000-05:00	Delete
  nic	Chesterbrook (12/21)	half	283.00	2022-12-22T20:23:00.000-05:00	Delete
  nic	Chesterbrook (12/14)	half	283.00	2022-12-22T20:22:12.000-05:00	Delete
  nic	Chesterbrook (12/7)	half	283.00	2022-12-22T20:21:09.000-05:00	Delete
  nic	Chesterbrook (11/30)	half	283.00	2022-12-22T20:20:32.000-05:00	Delete
  nic	T&amp;F Food	half	46.00	2022-12-22T20:19:34.000-05:00	Delete
  nic	Overdraft 35x3	half	105.00	2022-12-22T20:18:43.000-05:00	Delete
  nic	Niya Vitamins	half	38.92	2022-12-22T20:17:19.000-05:00	Delete
  nic	Costco	half	330.78	2022-12-22T20:15:32.000-05:00	Delete
  nic	DJ Train Store	half	30.00	2022-12-22T20:14:14.000-05:00	Delete
  nic	Mortgage (Nov)	half	788.59	2022-12-22T20:06:24.000-05:00	Delete
  nic	Car ins (Nov)	half	310.00	2022-12-22T20:05:00.000-05:00	Delete
  nic	Adt (Nov)	half	61.22	2022-12-22T20:00:16.000-05:00	Delete
  wil	Allie	half	100.00	2022-12-01T09:15:21.000-05:00	Delete
  wil	Dad gas	half	20.00	2022-12-01T09:14:41.000-05:00	Delete
  nic	old navy	half	54.42	2022-11-28T08:52:11.000-05:00	Delete
  nic	sketchers boots	half	23.49	2022-11-28T08:51:58.000-05:00	Delete
  nic	as seen on tv	half	39.20	2022-11-28T08:51:38.000-05:00	Delete
  nic	holiday treats	half	30.00	2022-11-28T08:51:21.000-05:00	Delete
  nic	childrens place	half	178.55	2022-11-28T08:51:00.000-05:00	Delete
  nic	target	half	115.15	2022-11-28T08:50:45.000-05:00	Delete
  wil	Dj haircut	half	27.00	2022-11-21T14:45:00.000-05:00	Delete
  wil	Founders card	half	2455.52	2022-11-21T14:43:40.000-05:00	Delete
  nic	peco	half	204.08	2022-11-21T14:35:34.000-05:00	Delete
  nic	oil	half	303.87	2022-11-21T14:35:12.000-05:00	Delete
  nic	dr lipton niya copay	half	30.00	2022-11-21T14:34:46.000-05:00	Delete
  nic	costco	half	120.00	2022-11-21T14:34:27.000-05:00	Delete
  nic	sewer	half	53.00	2022-11-21T14:34:15.000-05:00	Delete
  nic	weavers way	half	71.00	2022-11-15T07:48:19.000-05:00	Delete
  nic	kids club 	half	80.00	2022-11-15T07:48:06.000-05:00	Delete
  nic	chesterbrook (11/23)	half	283.00	2022-11-15T07:47:37.000-05:00	Delete
  nic	chesterbrook (11/16)	half	283.00	2022-11-15T07:47:20.000-05:00	Delete
  nic	chesterbrook (11/9)	half	283.00	2022-11-15T07:47:01.000-05:00	Delete
  wil	Dad gas	half	30.00	2022-11-11T11:18:20.000-05:00	Delete
  wil	Allie	half	200.00	2022-11-09T11:57:45.000-05:00	Delete
  nic	car ins 10 	half	310.00	2022-11-03T20:31:33.000-04:00	Delete
  nic	adt 10	half	61.22	2022-11-03T20:31:18.000-04:00	Delete
  nic	mortgage	half	788.59	2022-11-03T20:31:01.000-04:00	Delete
  nic	sewer	half	54.41	2022-11-03T20:30:46.000-04:00	Delete
  nic	peco	half	168.80	2022-11-03T20:30:29.000-04:00	Delete
  nic	chesterbrook (11/2)	half	283.00	2022-11-03T20:30:10.000-04:00	Delete
  nic	chesterbrook (10/26)	half	283.00	2022-11-03T20:29:49.000-04:00	Delete
  wil	Dad gas	half	60.00	2022-10-28T11:30:57.000-04:00	Delete
  wil	Founders card	half	2135.94	2022-10-24T13:37:00.000-04:00	Delete
  nic	chesterbrook (10/19)	half	283.00	2022-10-23T19:53:42.000-04:00	Delete
  wil	Jaime	half	180.00	2022-10-18T13:50:11.000-04:00	Delete
  wil	Allie	half	100.00	2022-10-18T13:47:16.000-04:00	Delete
  nic	jaime	half	180.00	2022-10-16T12:37:55.000-04:00	Delete
  nic	sewer	half	71.97	2022-10-13T21:06:01.000-04:00	Delete
  nic	peco	half	236.48	2022-10-13T21:05:15.000-04:00	Delete
  nic	chesterbrook (10/12)	half	376.00	2022-10-13T21:04:50.000-04:00	Delete
  nic	mortgage	half	788.59	2022-10-07T13:45:29.000-04:00	Delete
  wil	Founders card	half	2000.00	2022-10-04T14:14:18.000-04:00	Delete
  wil	Weavers	half	79.41	2022-10-04T12:25:19.000-04:00	Delete
  nic	chesterbrook (10/2)	half	376.00	2022-10-03T10:07:11.000-04:00	Delete
  nic	chesterbrook (9/26)	half	376.00	2022-10-03T10:06:40.000-04:00	Delete
  nic	chesterbrook (9/26	half	0.00	2022-10-03T10:03:59.000-04:00	Delete
  wil	Dad gas	half	100.00	2022-09-27T19:54:29.000-04:00	Delete
  nic	Car ins	half	310.00	2022-09-20T21:55:18.000-04:00	Delete
  nic	Car ins 8	half	310.00	2022-09-20T21:53:42.000-04:00	Delete
  nic	adt sept	half	61.22	2022-09-20T21:51:35.000-04:00	Delete
  nic	water	half	132.67	2022-09-20T17:41:27.000-04:00	Delete
  nic	chesterbrook (9/19)	half	376.00	2022-09-20T17:41:05.000-04:00	Delete
  wil	Founders card	half	2066.65	2022-09-20T13:29:47.000-04:00	Delete
  nic	chesterbrook (9/12)	half	396.00	2022-09-14T17:17:17.000-04:00	Delete
  wil	Dad gas	half	30.00	2022-09-12T14:07:51.000-04:00	Delete
  wil	DJ Haircut	half	30.00	2022-09-09T13:17:36.000-04:00	Delete
  nic	9/8 week chesterbrook tuition	half	396.00	2022-09-08T10:16:09.000-04:00	Delete
  wil	Amazon	half	156.97	2022-09-07T10:57:47.000-04:00	Delete
  nic	adt	half	61.22	2022-09-06T19:26:20.000-04:00	Delete
  nic	clothes for kids	half	40.25	2022-09-06T11:48:01.000-04:00	Delete
  nic	camping	half	372.00	2022-09-05T17:20:38.000-04:00	Delete
  nic	from the boot	half	123.00	2022-09-05T16:47:11.000-04:00	Delete
  nic	magerks	half	174.21	2022-09-04T08:16:54.000-04:00	Delete
  nic	alcohol	half	95.65	2022-09-04T08:16:22.000-04:00	Delete
  nic	weavers	half	144.21	2022-09-04T08:15:03.000-04:00	Delete
  wil	Dad gas	half	60.00	2022-09-02T10:15:21.000-04:00	Delete
  wil	Weavers way	half	83.02	2022-09-02T10:14:48.000-04:00	Delete
  wil	Sitter	half	480.00	2022-09-01T15:27:03.000-04:00	Delete
  nic	tuition dj july	half	603.50	2022-08-30T17:38:04.000-04:00	Delete
  nic	mortgage	half	788.59	2022-08-30T17:36:51.000-04:00	Delete
  nic	sewer	half	71.97	2022-08-30T17:36:33.000-04:00	Delete
  nic	peco	half	210.71	2022-08-30T17:36:18.000-04:00	Delete
  nic	upper dublin tax	all	300.35	2022-08-30T17:35:58.000-04:00	Delete
  nic	dog food	half	58.29	2022-08-30T17:35:33.000-04:00	Delete
  nic	UNIFORM	half	99.75	2022-08-29T14:30:18.000-04:00	Delete
  nic	weavers way	half	169.70	2022-08-29T11:14:00.000-04:00	Delete
  wil	Sitter	half	435.00	2022-08-25T13:58:44.000-04:00	Delete
  nic	diapers	half	54.00	2022-08-23T19:01:51.000-04:00	Delete
  wil	Sitter	half	648.00	2022-08-19T13:29:41.000-04:00	Delete
  nic	soaps	half	44.80	2022-08-15T17:28:26.000-04:00	Delete
  wil	Test	all	0.00	2022-08-15T15:20:21.000-04:00	Delete
  wil	Founders card	half	2984.54	2022-08-15T14:44:34.000-04:00	Delete
  wil	dj hair cut	half	27.00	2022-08-13T15:31:38.000-04:00	Delete
  nic	peco	half	199.03	2022-08-13T11:58:21.000-04:00	Delete
  nic	sewer	half	80.75	2022-08-13T11:58:03.000-04:00	Delete
  nic	mortgage	half	788.59	2022-08-13T11:57:09.000-04:00	Delete
  nic	Jaime	all	100.00	2022-08-12T15:12:47.000-04:00	Delete
  wil	Sitter	half	440.00	2022-07-29T14:16:09.000-04:00	Delete
  wil	Allie	half	270.00	2022-07-26T15:13:56.000-04:00	Delete
  nic	uncle jim flight	half	343.20	2022-07-25T16:51:37.000-04:00	Delete
  nic	chesterbrook	half	363.00	2022-07-22T13:08:38.000-04:00	Delete
  wil	Sitter	half	350.00	2022-07-21T14:22:53.000-04:00	Delete
  wil	Founders card	half	2537.15	2022-07-20T16:22:20.000-04:00	Delete
  nic	ymca camp	half	230.40	2022-07-19T20:47:37.000-04:00	Delete
  nic	gutter cleaning	half	186.42	2022-07-19T20:47:18.000-04:00	Delete
  nic	adt	half	61.22	2022-07-19T20:46:01.000-04:00	Delete
  nic	car ins	half	295.92	2022-07-19T20:44:47.000-04:00	Delete
  nic	car ins	half	295.92	2022-07-19T20:44:31.000-04:00	Delete
  nic	tdori party	half	190.00	2022-07-18T18:12:26.000-04:00	Delete
  wil	Plummer	half	150.00	2022-07-18T10:23:55.000-04:00	Delete
  nic	tj max niya bday	half	146.00	2022-07-15T14:57:04.000-04:00	Delete
  nic	cookies	half	45.00	2022-07-15T12:03:01.000-04:00	Delete
  wil	Allie	half	202.00	2022-07-10T09:30:32.000-04:00	Delete
  wil	Sitter	half	330.00	2022-07-08T13:56:46.000-04:00	Delete
  nic	back yard eats	half	240.00	2022-07-08T10:54:32.000-04:00	Delete
  nic	adt	half	61.22	2022-07-08T10:54:12.000-04:00	Delete
  nic	tuition	half	597.63	2022-07-08T10:53:56.000-04:00	Delete
  nic	tuition	half	597.63	2022-07-08T10:53:41.000-04:00	Delete
  nic	mortgage	half	788.59	2022-07-08T10:53:07.000-04:00	Delete
  nic	sewer	half	67.58	2022-07-08T10:52:52.000-04:00	Delete
  nic	peco	half	198.56	2022-07-08T10:52:38.000-04:00	Delete
  wil	Jaime	half	300.00	2022-07-04T13:59:26.000-04:00	Delete
  wil	Sitter	half	440.00	2022-06-30T13:34:46.000-04:00	Delete
  nic	one outfit	half	16.98	2022-06-26T17:18:26.000-04:00	Delete
  nic	baptism amazon	half	216.29	2022-06-26T17:17:48.000-04:00	Delete
  wil	Founders card	half	3418.79	2022-06-22T13:21:32.000-04:00	Delete
  wil	Weavers way	half	59.03	2022-06-22T12:33:46.000-04:00	Delete
  wil	list	all	4101.65	2022-06-20T10:38:42.000-04:00	Delete
  nic	list	all	6546.13	2022-06-20T10:38:15.000-04:00	Delete
  nic	jaime	all	20.00	2022-06-20T10:37:04.000-04:00	Delete
  wil	Sitter	half	405.00	2022-06-17T12:46:21.000-04:00	Delete
  wil	Allie	half	430.00	2022-06-15T12:32:47.000-04:00	Delete`;//543-109
  const importMoneyList = convertLineToObject(inputData);

  const showWhoOwes = (whoOwes: string, owesHowMuch: string) => {
    setChildPanelInfoState((prevState) => ({
      ...prevState, whoOwes: whoOwes, owesHowMuch: owesHowMuch
    }));
  }

  const onAdd = (title: string, newEntry: IMoney) => {
    const dataForAPIUpdated = { ...dataForAPI, title: title, formData: newEntry };
    handleAdd(dataForAPIUpdated).then((postData: IDataReturn) => {
      if (postData.success) {
        onGetData();
      } else {
        console.error('Error Adding', postData.errors, postData.requestOptions, postData.variables);
      }
    })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const onToggle = (id: number) => {
    for (let index = 0; index < moneyList.length; index++) {
      const element = moneyList[index];
      if (element.id === id) {
        element.exclude = !(element.exclude);
        const dataForAPIUpdated = { ...dataForAPI, postId: allPosts[id].id, title: allPosts[index].title, formData: element };
        handleUpdate(dataForAPIUpdated).then((postData: IDataReturn) => {
          if (postData.success) {
            onGetData();
          } else {
            console.error('Error Updating', postData.errors, postData.requestOptions, postData.variables);
          }
        })
          .catch((error) => {
            console.error('Error:', error);
          });;
      }
    }
  };

  const onDelete = (id: number) => {
    const dataForAPIUpdated = { ...dataForAPI, postId: allPosts[id].id };
    handleDelete(dataForAPIUpdated).then((postData: IDataReturn) => {
      if (postData.success) {
        if (setChildPanelInfoState) {
          if (childPanelInfoState.errorInfo) {
            const newErrorArray = [...childPanelInfoState.errorInfo, {
              id: 1,
              error: "This is a test error",
            }];
            setChildPanelInfoState((prevObject) => ({
              ...prevObject,
              errorInfo: newErrorArray,
            }));
          }
        }
        onGetData();
      } else {
        console.error('Error Deleting', postData.errors, postData.requestOptions, postData.variables);
      }
    })
      .catch((error) => {
        console.error('Error Deleting:', error);
      });
  };

  const onGetData = () => {
    const dataForAPIUpdated = { ...dataForAPI };
    fetchPostsByTags(dataForAPIUpdated).then((postData: IDataReturn) => {
      if (postData.success) {
        setAllPosts(postData.posts as IPostData[]);
        const data = postData.data as IMoney[];

        // Custom comparison function to sort by date
        const compareDates = (a: IMoney, b: IMoney) => {
          // Check if a.created and b.created are defined before comparing
          if (a.created && b.created) {
            const dateA: Date = new Date(a.created); // Explicitly specify the type as Date
            const dateB: Date = new Date(b.created); // Explicitly specify the type as Date
            return dateB.getTime() - dateA.getTime(); // Use getTime() for numerical comparison
          } else {
            return 0; // Return 0 if either date is undefined
          }
        };

        // Sort the items array using the compareDates function
        const sortedItems = data.sort(compareDates);

        // console.log('**** data', data)
        setMoneyList(sortedItems);
        setMoneyByMonth(getByYear(sortedItems));
      } else {
        console.error('Error Updating', postData.errors, postData.requestOptions, postData.variables);
      }
    })
      .catch((error) => {
        console.error('Error Updating:', error);
      });
  }

  useEffect(() => {
    onGetData();
    // console.log('convertLineToObject',returnValue);
    // onAddAll('Add All',returnValue);
    // setOldMoneyList(returnValue);
  }, []);

  const onAddList = (index: number, importMoneyList: IMoney[]) => {
    if (index >= 0) {
      let formData = importMoneyList[index];
      const { who, what, amount, option } = formData;
      const howMuch = Number(amount.toString().split(',').join('')).toFixed(2).toString();
      const updatedFormData = { ...formData, amount: Number(amount) }

      const dataForAPIUpdated = {
        ...dataForAPI,
        title: `${peopleLookUp(who)}-${what}-${moneyOptionsLookUp(option)}-${howMuch}`,
        formData: updatedFormData
      };
      handleAdd(dataForAPIUpdated).then((postData: IDataReturn) => {
        if (postData.success) {
          onAddList(index - 1, importMoneyList);
        } else {
          console.error('Error Adding', postData.errors, postData.requestOptions, postData.variables);
        }
      })
        .catch((error) => {
          console.error('Error:', error);
        });
    } else {
      return;
    }
  };


  const [selectedItem, setSelectedItem] = useState<ISelectedItem | undefined>();

  const onItemSelected = (itemSelected: string, averageSpent: number) => {
    console.log('onItemSelected',onItemSelected);
    setSelectedItem({ itemSelected: itemSelected, averageSpent: averageSpent })
  }

  const contentRef = useRef<HTMLDivElement>(null);

  // const ScrollToContentButton: React.FC = (thisRef:HTMLDivElement) => {

  //   const scrollToContent = () => {
  //     contentRef.current?.scrollIntoView({ behavior: 'smooth' });
  //   };

  //   return (
  //     <button onClick={scrollToContent}>
  //       Scroll to Content
  //     </button>
  //   );
  // };

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return <div>
    {/* <button title="Run Import" onClick={() => { onAddList(importMoneyList.length - 1, importMoneyList) }}>Run Import</button> */}
    
    <div id="section1" className="section">
      <div className="spacer-10" />
      <button onClick={() => scrollToSection('section2')}>Section 2</button>
      <span className='width-spacer-10' />
      <button onClick={() => scrollToSection('section3')}>Section 3</button>
      <div className='spacer-10' />
      <AddMoney onAdd={onAdd} selectedItem={selectedItem} />
      <ResultsByPerson moneyList={moneyList} />
    </div>

    {/* <ScrollToContentButton /> */}
    <ResultsByMonth moneyByMonth={moneyByMonth} />
    <div id="section2" className="section">
      <div className='spacer-10' />
      <button onClick={() => scrollToSection('section1')}>Section 1</button>
      <span className='width-spacer-10' />
      <button onClick={() => scrollToSection('section3')}>Section 3</button>
      <ResultsByItem moneyList={moneyList} onItemSelected={onItemSelected} />
    </div>
    <div id="section3" className="section">
      <div className='spacer-10' />
      <button onClick={() => scrollToSection('section1')}>Section 1</button>
      <span className='width-spacer-10' />
      <button onClick={() => scrollToSection('section2')}>Section 2</button>
      <ResultList moneyList={moneyList} onDelete={onDelete} childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
    </div>
    {JSON.stringify(oldMoneyList, null, 2)}
  </div>;
};



// const mockMoneyList = [
//   { who: 0, what:'t1', option:0, created: '2022-01-01', amount: 100 },
//   { who: 1, what:'t2', option:1, created: '2022-02-01', amount: 200 },
//   { who: 0, what:'t3', option:2, created: '2022-03-01', amount: 300 },
// ];
// const sortedTableData = tableData.slice().sort((a, b) => a.priority - b.priority);
// setSortedTableData(sortedTableData);

// '{"who":"1","what":"rrr","amount":4.44,"option":"1","id":"c935473e-9b10-478a-acb8-fac2f441f70e","created":"4/22/2024, 3:47:21 PM","updated":"","title":"Nic-rrr-All (Other person owes all)-4.44"}'



// Who	What	Option	How Much	When	Actions

// export interface IMoney {
//   id: number;
//   who: number;
//   what: string;
//   amount: number;
//   option: number;
//   exclude: boolean;
//   show: boolean;
// }


{/* 
      
      // Results by month:
// Month	Year	# of Days	Amount Per Month	Amount Per Day
// June	2022	31	$15,653.87	$504.96
// July	2022	30	$9,287.44	$309.58
// August	2022	31	$7,664.57	$247.24
// September	2022	31	$5,999.07	$193.52
// October	2022	30	$7,243.39	$241.45
// November	2022	31	$6,813.30	$219.78

      Results by item totaled and sorted:
      What	Total	Count	Avg
      Select	Founders card	$71,536.77	26	2,751.41
      Select	Mortgage	$16,560.59	21	788.60
      Select	Chesterbrook	$10,885.80	36	302.38
      Select	List	$10,647.78	2	5,323.89
      Select	School tax	$5,707.28	2	2,853.64

      Results by person


      Line Items
      Who	What	Option	How Much	When	Actions
      nic	clair sitter	half	60.00	2024-04-16T10:44:20.000-04:00	Delete
      nic	chesterbrook (4/14)	half	309.00	2024-04-16T10:40:13.000-04:00	Delete
      wil	Fireside	half	98.00	2024-04-16T10:04:13.000-04:00	Delete
      wil	Founders card	half	4990.00	2024-04-16T10:02:10.000-04:00	Delete
      nic	costco	half	490.25	2024-04-16T09:35:19.000-04:00	Delete
      nic	advance auto parts on our credit card	all	352.70	2024-04-11T13:55:06.000-04:00	Delete
      nic	chesterbrook (4/7)	half	309.00	2024-04-09T14:59:48.000-04:00	Delete */}