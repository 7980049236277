import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { InvoiceGenerator } from './InvoiceGenerator';
import { InvoiceView } from './InvoiceView';
import { PrintPdf } from './PrintPdf';
import { InvoiceList } from './InvoiceList';
import { IDataReturn, IDataForAPI, IPostData, fetchPostsByTags, handleAdd, handleDelete, handleUpdate } from '../../radApi';
import { IChildPanelInfoProps, IErrorInfo } from '../../../types/main';

export interface IInvoice {
  index?: number;
  postId?: number;
  from: string;
  fromAddressLineOne: string;
  fromAddressLineTwo: string;
  to: string;
  toAddressLineOne: string;
  toAddressLineTwo: string;
  logo: string;
  number: number;
  date: string;
  items: Array<{
    name: string;
    quantity: number;
    unit_cost: number;
  }>;
  notes: string;
  terms: string;
  taxPercent: number;
  total: number;
  created?: string;
  updated?: string;
  completed?: boolean;
}

const formattedDate = () => {
  const currentDate = new Date();
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(currentDate);
}

export const defaultInvoiceData: IInvoice = {
  from: 'Artificial Business LLC',
  fromAddressLineOne: '',
  fromAddressLineTwo: '',
  to: '',
  toAddressLineOne: '',
  toAddressLineTwo: '',
  logo: 'https://artificialbusiness.com/static/images/NewHome/abclogo.png',
  number: 0,
  date: formattedDate(),
  items: [],
  notes: 'Thank you for your business!',
  terms: 'Payment due in 30 days',
  taxPercent: 0,
  total: 0,
};

// Function to calculate subtotal
export const calculateSubtotal = (invoiceData:IInvoice) => {
  return invoiceData.items.reduce((total, item) => total + item.quantity * item.unit_cost, 0);
};

// Function to calculate tax (assuming 10% tax rate)
export const calculateTax = (invoiceData:IInvoice) => {
  const subtotal = calculateSubtotal(invoiceData);
  const taxRate = invoiceData.taxPercent/100; // 10% tax rate, adjust as needed
  return subtotal * taxRate;
};

// Function to calculate total (subtotal + tax)
export const calculateTotal = (invoiceData:IInvoice) => {
  const subtotal = calculateSubtotal(invoiceData);
  const tax = calculateTax(invoiceData);
  return subtotal + tax;
};

// Function to calculate balance due
export const calculateBalanceDue = (invoiceData:IInvoice) => {
  const total = calculateTotal(invoiceData);
  // You can subtract any previous payments or adjustments here
  return total;
};

export const Invoice: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {

  const dataForAPI: IDataForAPI = {
    userId: childPanelInfoState.userInfo.id,
    key: childPanelInfoState.userInfo.key,
    tagIds: [1],
    status: 1,
    encryptData:childPanelInfoState.mainPanelInfo.system.encryptData
  }
  const [allPosts, setAllPosts] = useState<IPostData[]>([]);

  const [postData, setPostData] = useState<IPostData>({
    id: 0,
    tags: [],
    html: '',
    by: 0, // user ID
    status: 0, // status ID
    title: '',
    featured: false,
    data: {},
  });

  const [tableData, setTableData] = useState<IInvoice[]>([]);
  const [selectedData, setSelectedData] = useState<IInvoice | undefined>(undefined);

  const onError = (friendly: string, e: any) => {
    const errorInfo: IErrorInfo = {
      error: friendly,
      e: e,
    }
    // if (setErrorInfo) {
    //   setErrorInfo(prevItems => [...prevItems, errorInfo]);
    // }
    if (childPanelInfoState.errorInfo) {
      const newErrorArray = [...childPanelInfoState.errorInfo, errorInfo];
      setChildPanelInfoState((prevObject) => ({
        ...prevObject,
        errorInfo: newErrorArray,
      }));
    }
  };

  const onSave = (title: string, updatedInfo: any) => {
    if (updatedInfo.index === undefined) {
      console.log('Adding',updatedInfo);
      onAdd(title, updatedInfo);
    } else {
      console.log('Updating',updatedInfo);
      onUpdate(title, updatedInfo);
    }
  };

  const onAdd = (title: string, updatedInfo: any) => {
    const dataForAPIUpdated = { ...dataForAPI, title: title, formData: updatedInfo };
    console.log('add dataForAPIUpdated', dataForAPIUpdated);
    handleAdd(dataForAPIUpdated).then((postData: IDataReturn) => {
      if (postData.success) {
        onGetData();
        // setFormData(prevState => ({
        //   ...prevState,
        //   title: "",
        //   note: ""
        // }));
        setSelectedData(undefined);
      } else {
        onError('Error Adding', { errors: postData.errors, requestOptions: postData.requestOptions, variables: postData.variables })
      }
    })
      .catch((error) => {
        onError('Error Adding', error);
      });
  };

  const onUpdate = (title: string, updatedInfo: any) => {
    if (selectedData !== undefined) {
      const updatedForm = {
        ...updatedInfo,
        updated: new Date().toLocaleString()
      };
      const dataForAPIUpdated = { ...dataForAPI, postId: selectedData.postId as number, title: title, formData: updatedForm };
      console.log('update dataForAPIUpdated', dataForAPIUpdated);
      handleUpdate(dataForAPIUpdated).then((postData: IDataReturn) => {
        if (postData.success) {
          onGetData();
          setSelectedData(undefined);
          setPostData(prevState => ({
            ...prevState,
            title: "",
            note: ""
          }));
        } else {
          onError('Error Updating', { errors: postData.errors, requestOptions: postData.requestOptions, variables: postData.variables })
        }
      })
        .catch((error) => {
          onError('Error Updating', error);
        });
    }
  };

  const onDelete = async (index: number) => {
    const dataForAPIUpdated = { ...dataForAPI, postId: allPosts[index].id };
    handleDelete(dataForAPIUpdated).then((postData: IDataReturn) => {
      console.log('delete', postData);
      if (postData.success) {
        onGetData();
      } else {
        onError('Error Deleting', { errors: postData.errors, requestOptions: postData.requestOptions, variables: postData.variables })
      }
    })
      .catch((error) => {
        onError('Error Deleting', error);
      });
  };

  const onGetData = () => {
    const dataForAPIUpdated = { ...dataForAPI };
    fetchPostsByTags(dataForAPIUpdated).then((postData: IDataReturn) => {
      if (postData.success) {
        const posts = postData.posts as IPostData[];
        setAllPosts(posts);
        const data = postData.data as IInvoice[];
        for (let index = 0; index < posts.length; index++) {
          const element = posts[index];
          // new Date(dateString).toLocaleString();
          if (posts[index].created) {
            // data[index].created = new Date(posts[index].created.toString()).toLocaleString();
            // const formattedDate = posts[index]?.created ? new Date(posts[index].created).toLocaleString() : 'N/A';
            // const formattedDate = posts[index]?.created
            // ? new Date(posts[index].created).toLocaleString()
            // : 'N/A';
          }
        }

        setTableData(data);
        // const sortedTableData = tableData.slice().sort((a, b) => a.priority - b.priority);
        // setSortedTableData(sortedTableData);
      } else {
        onError('Error Updating', { errors: postData.errors, requestOptions: postData.requestOptions, variables: postData.variables })
      }
    })
      .catch((error) => {
        onError('Error Updating', error);
      });
  }

  useEffect(() => {
    onGetData();
  }, []);

  return (
    <div>
      <InvoiceList tableData={tableData} invoiceData={selectedData} handleInvoiceDelete={onDelete} setSelectedData={setSelectedData} childPanelInfoState={childPanelInfoState}/>
      {selectedData !== undefined && <div><div className='spacer-10' />
        {selectedData !== undefined &&
          <div>
            <PrintPdf invoiceData={selectedData} />
            <div className='spacer-10' />
            <InvoiceGenerator invoiceData={selectedData} onSave={onSave} setSelectedData={setSelectedData} />
          </div>
        }
      </div>}
    </div>
  );
};