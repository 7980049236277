import { FormEventHandler, useEffect, useState } from 'react';
import './settings.scss';
import { IDataReturn, IDataForAPI, IPostData, fetchPostsByTags, generateKey } from '../../radApi';
import { IMusicData } from '../music/old-song-list/SongListOld';
import ColorPicker from './color-picker/ColorPicker';
import { IChildPanelInfoProps } from '../../../types/main';
import React from 'react';

// export const Settings: React.FC<IChildPanelInfoProps> = ({ setErrorInfo }) => {
//     //print out all the json so that it can be saved
//     return <></>
// }

// export interface IExportData {
//   songList: IMusicData[],

// }

export interface ISettings {
  exportData: any,
}


export const Settings: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {

  // const apiTags = [6];
  const [allPosts, setAllPosts] = useState<IPostData[]>([]);
  const [settingExportData, setSettingExportData] = useState<ISettings>({ exportData: [] });
  const [encryptionKey, setEncryptionKey] = useState<Promise<CryptoKey>>();


  const onError = (friendly: string, e: any) => {
    // if (setErrorInfo) {
    //   // setErrorInfo(prevItems => [...prevItems, {
    //   //   id: prevItems.length,
    //   //   error: friendly,
    //   //   e: e,
    //   // }]);
    // }
    if (childPanelInfoState.errorInfo) {
      const newErrorArray = [...childPanelInfoState.errorInfo, {
        id: childPanelInfoState.errorInfo.length,
        error: friendly,
        e: e,
      }];
      setChildPanelInfoState((prevObject) => ({
        ...prevObject,
        errorInfo: newErrorArray,
      }));
    }
  };

  // const onGetData = (name: string, apiTags: number[]) => {
  //   fetchPostsByTags(apiTags).then((postData: IDataReturn) => {
  //     if (postData.success) {
  //       const data = postData.data as IMusicData[];
  //       const exportData: IExportData = {
  //         ...settingExportData.exportData,
  //         [name]: data,
  //       }
  //       // setSettingExportData({
  //       //   ...settingExportData,
  //       //   exportData: exportData,
  //       // });
  //       setSettingExportData((prevObject) => ({ ...prevObject, exportData: exportData }));
  //     } else {
  //       onError('Error Updating', { errors: postData.errors, requestOptions: postData.requestOptions, variables: postData.variables })
  //     }
  //   })
  //     .catch((error) => {
  //       onError('Error Updating', error);
  //     });
  // }

  const onGetDataList = (exportDataObject: any, name: string, apiTags: number[]): any => {
    const dataForAPIUpdated: IDataForAPI = { 
      tagIds: apiTags,
      encryptData:childPanelInfoState.mainPanelInfo.system.encryptData
     };
    fetchPostsByTags(dataForAPIUpdated).then((postData: IDataReturn) => {
      if (postData.success) {
        const data = postData.data;
        const exportData: any = {
          ...exportDataObject.exportData,
          [name]: data,
        }
        return exportData;
      } else {
        onError('Error Updating', { errors: postData.errors, requestOptions: postData.requestOptions, variables: postData.variables })
      }
    })
      .catch((error) => {
        onError('Error Updating', error);
      });
  }

  const downloadData = () => {
    const jsonData: any = settingExportData.exportData;
    const jsonString = JSON.stringify(jsonData, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    const timestamp = new Date().toISOString().replace(/[-:.TZ]/g, '');
    link.download = `your_data_${timestamp}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // // Example usage
  // async function example() {
  //   // Generate encryption key
  //   const encryptionKey = await generateKey();

  //   // String to encrypt
  //   const originalText = 'This is a secret message.';

  //   // Encrypt the string
  //   const ciphertext = await encryptText(originalText, encryptionKey);

  //   // Decrypt the string
  //   const decryptedText = await decryptText(ciphertext, encryptionKey);

  //   console.log('Original Text:', originalText);
  //   console.log('Decrypted Text:', decryptedText);
  // }

  // example();

  // export const fetchPostsByTags = async (tagIds: number[]): Promise<IDataReturn> => {
  const generateKeyUpdate = async (): Promise<CryptoKey> => {
    const generateKeyValue = await generateKey();
    console.log('generateKeyValue', generateKeyValue);
    return await generateKey();
  }

  // const updateColors = (whatToUpdate: string, event: React.FormEvent<HTMLFormElement>) => {
  //   // Prevent the default form submission behavior
  //   event.preventDefault();
  //   if (childPanelInfoState !== undefined) {
  //     // const newObject = {...childPanelInfoState.mainPanelInfo.styles, 
  //     //   fontColor: fontColor as string,
  //     // };
  //     // setChildPanelInfoState((prevObject) => ({
  //     //   ...prevObject,
  //     //    mainPanelInfo: {...prevObject.mainPanelInfo, styles: newObject }
  //     // }));
  //     const infoState = { ...childPanelInfoState };
  //     if (whatToUpdate === 'fontColor')
  //       infoState.mainPanelInfo.styles.fontColor = fontColor;
  //     if (whatToUpdate === 'backgroundColor')
  //       infoState.mainPanelInfo.styles.backgroundColor = fontColor;
  //     if (whatToUpdate === 'borderColor')
  //       infoState.mainPanelInfo.styles.borderColor = fontColor;
  //     console.log('infoState', infoState);
  //     setChildPanelInfoState(infoState);
  //   }
  // }

  // updateColors('fontColor')

  useEffect(() => {
    if (childPanelInfoState.tagList) {
      let exportData: any = {};
      const settingsData = { ...settingExportData };
      childPanelInfoState.tagList.forEach(tag => {
        if (tag.formattedName && tag.id) {
          // exportData = onGetDataList(exportData, tag.formattedName, [tag.id]);
          const dataForAPIUpdated = { 
            tagIds: [tag.id],
            encryptData:childPanelInfoState.mainPanelInfo.system.encryptData
          };
          fetchPostsByTags(dataForAPIUpdated).then((postData: IDataReturn) => {
            if (postData.success) {
              const data = postData.data;
              console.log("here", JSON.stringify({ formattedName: tag.formattedName, data: data, exportData: exportData }, null, 2));
              const exportDataUpdated: any = {
                ...settingsData.exportData,
                [tag.formattedName as string]: data,
              }
              // exportData=exportDataUpdated

              settingsData.exportData = exportDataUpdated;

              setSettingExportData({
                ...settingsData,
                exportData: exportDataUpdated,
              });
            } else {
              onError('Error Updating', { errors: postData.errors, requestOptions: postData.requestOptions, variables: postData.variables })
            }
          })
            .catch((error) => {
              onError('Error Updating', error);
            });
        }
        // const words = tag.name.split(' ');
        // const formattedName = words[0].toLowerCase() + words.slice(1).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');
        // tag.formattedName = formattedName;
        // if (tag.formattedName && tag.id) {
        //   onGetData(tag.formattedName, [tag.id]);
        //   const updatePart1 = (obj: MyObject, newValue: string): MyObject => {
        //     return { ...obj, part1: newValue };
        //   };

        // }
      });

      setEncryptionKey(generateKeyUpdate());

      // setSettingExportData((prevObject) => ({ ...prevObject, exportData: exportData }));
    }
    //     const numericValue = MyEnum.Invoice; // Assume you have the numeric value, e.g., from an API or user input
    // const stringValue = MyEnum[numericValue as keyof MyEnum];
    //     onGetData(DataPoints.SongList, [6]);

    // // Get the length of the enum
    // const enumLength = Object.keys(DataPoints).length / 2; // Divide by 2 to exclude string keys

    // // // Loop through enum values
    // // for (let index = 0; index < enumLength; index++) {
    // //   const enumValue = DataPoints[index];
    // //   console.log(`Enum Value: ${enumValue}`);
    // // }

    // // for (const numericValue in DataPoints) {
    // //   const stringValue = DataPoints[numericValue as keyof DataPoints];
    // //   console.log(`Numeric: ${numericValue}, String: ${stringValue}`);
    // // }

    // for (const value of Object.values(DataPoints)) {
    //   console.log(`Enum Value: ${value}`);
    // }

    // for (const [key, value] of Object.entries(DataPoints)) {
    //   // const numericValue = parseInt(value, 10);
    //   console.log(`Enum Value: ${value}`,key, value);
    //   // if (!isNaN(numericValue)) {
    //   //   console.log(`Enum key: ${key}, Enum numeric value: ${numericValue}`);
    //   // }
    // }

    // for (const key in DataPoints) {
    //   if (isNaN(Number(key))) {
    //     const numericValue = DataPoints[key as keyof typeof DataPoints];
    //     console.log(`Enum key: ${key}, Enum numeric value: ${numericValue}`);
    //   }
    // }
  }, []);
  // const colorStyle = {
  //   backgroundColor: backgroundColor,
  //   width: '100px',
  //   height: '100px',
  // };

  // <div style={colorStyle}></div>


  //"songList"
  return (
    <div className="settings">
      <div className='spacer-10' />
      
      
      <ColorPicker childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState}/>

      {/* backgroundColor, setBackgroundColor] = useState<string>(childPanelInfoState.mainPanelInfo.styles.backgroundColor);
  const [borderColor, setBorderColor */}
      {/* <button onClick={updateFontColor}>Next</button> */}
      <div className='spacer-10' />
      <button title="Download JSON" onClick={downloadData}>Download JSON</button>
      {settingExportData && <pre>{JSON.stringify(settingExportData.exportData, null, 2)}</pre>}

    </div>
  );
};