import React from 'react';

interface TuningForkChartProps {
  currentFreq: number;
  targetFreq: number;
}

const TuningForkChart: React.FC<TuningForkChartProps> = ({ currentFreq, targetFreq }) => {
//   const noteFrequencies: { [note: string]: number } = {
//     C: 261.63,
//     'C#': 277.18,
//     D: 293.66,
//     'D#': 311.13,
//     E: 329.63,
//     F: 349.23,
//     'F#': 369.99,
//     G: 392.00,
//     'G#': 415.30,
//     A: 440.00,
//     'A#': 466.16,
//     B: 493.88,
//     'B#': 523.25,
//   };

const noteFrequencies: { [note: string]: number } = {
    E: 82.41,   // Lower octave
    F: 87.31,
    'F#': 92.50,
    G: 98.00,
    'G#': 103.83,
    A: 110.00,
    'A#': 116.54,
    B: 123.47,
    C: 130.81,
    'C#': 138.59,
    D: 146.83,
    'D#': 155.56,
  
    // Higher octave starting from e = 329.63 Hz
    e: 329.63,
    'F-e': 349.23,
    'F#-e': 369.99,
    'G-e': 392.00,
    'G#-e': 415.30,
    'A-e': 440.00,
    'A#-e': 466.16,
    'B-e': 493.88,
    'C-e': 523.25,
    'C#-e': 554.37,
    'D-e': 587.33,
    'D#-e': 622.25
  };
  

// const noteFrequencies: { [note: string]: number } = {
//     C: 130.81,  // Adjusted to match the lower octave range
//     'C#': 138.59,
//     D: 146.83,
//     'D#': 155.56,
//     E: 82.41,   // Lower octave 'E'
//     F: 87.31,
//     'F#': 92.50,
//     G: 98.00,
//     'G#': 103.83,
//     A: 110.00,
//     'A#': 116.54,
//     B: 123.47,
//     'B#': 130.81, // Same as C in lower octave
  
//     // Higher octave starting from 'e' = 329.63 Hz
//     'C-e': 261.63,
//     'C#-e': 277.18,
//     'D-e': 293.66,
//     'D#-e': 311.13,
//     e: 329.63,   // Higher octave 'e'
//     'F-e': 349.23,
//     'F#-e': 369.99,
//     'G-e': 392.00,
//     'G#-e': 415.30,
//     'A-e': 440.00,
//     'A#-e': 466.16,
//     'B-e': 493.88,
//     'B#-e': 523.25  // Higher octave 'B#'
//   };
  

//   const notes = ['D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'B#', 'C', 'C#', 'D', 'D#','e','f'];

// // Frequencies based on E = 82.41 Hz for lower octave and e = 329.63 Hz for higher octave
// const frequencies = [
//   73.42,  // D
//   77.78,  // D#
//   82.41,  // E
//   87.31,  // F
//   92.50,  // F#
//   98.00,  // G
//   103.83, // G#
//   110.00, // A
//   116.54, // A#
//   123.47, // B
//   130.81, // B#
//   138.59, // C
//   146.83, // C#
//   155.56, // D (next octave)
//   164.81, // D#
//   329.63, // e
//   349.23  // f (F in higher octave)
// ];

  const getClosestNote = () => {
    let closestNote = 'N/A';
    let smallestDiff = Infinity;

    for (const note in noteFrequencies) {
      const diff = Math.abs(currentFreq - noteFrequencies[note]);
      if (diff < smallestDiff) {
        smallestDiff = diff;
        closestNote = note;
      }
    }

    return closestNote;
  };

  const getDeviation = () => {
    return (currentFreq - targetFreq).toFixed(2);
  };

  const closestNote = getClosestNote();
  const isNearNote = Math.abs(currentFreq - noteFrequencies[closestNote]) <= 1.0; // Tolerance of 1 Hz

  return (
    <div
      style={{
        backgroundColor: '#1a1a1a', // Dark background
        color: '#ffffff', // Light text color for visibility
        padding: '20px',
        borderRadius: '10px',
        textAlign: 'center',
      }}
    >
      <h3>Tuning Fork Style Chart</h3>
      <div
        style={{
          width: '100%',
          height: '50px',
          position: 'relative',
          backgroundColor: '#333', // Slightly lighter dark for contrast
          border: '2px solid #888', // Light gray border for contrast
        }}
      >
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: 0,
            bottom: 0,
            width: '2px',
            backgroundColor: isNearNote ? '#00FF00' : '#FFD700', // Green if near a note, gold otherwise
            transform: `translateX(${parseFloat(getDeviation()) * 20}px)`,
          }}
        />
      </div>
      <p>Current Frequency: {currentFreq.toFixed(2)} Hz</p>
      <p>Deviation from Target: {getDeviation()} Hz</p>
      <p>Closest Note: {closestNote} {isNearNote && '(In Tune)'}</p>
    </div>
  );
};

export default TuningForkChart;

// import React from 'react';

// interface TuningForkChartProps {
//   currentFreq: number;
//   targetFreq: number;
// }

// const TuningForkChart: React.FC<TuningForkChartProps> = ({ currentFreq, targetFreq }) => {
//   const getDeviation = () => {
//     return (currentFreq - targetFreq).toFixed(2);
//   };

//   return (
//     <div
//       style={{
//         backgroundColor: '#1a1a1a', // Dark background
//         color: '#ffffff', // Light text color for visibility
//         padding: '20px',
//         borderRadius: '10px',
//         textAlign: 'center',
//       }}
//     >
//       <h3>Tuning Fork Style Chart</h3>
//       <div
//         style={{
//           width: '100%',
//           height: '50px',
//           position: 'relative',
//           backgroundColor: '#333', // Slightly lighter dark for contrast
//           border: '2px solid #888', // Light gray border for contrast
//         }}
//       >
//         <div
//           style={{
//             position: 'absolute',
//             left: '50%',
//             top: 0,
//             bottom: 0,
//             width: '2px',
//             backgroundColor: '#FFD700', // Bright gold color for visibility
//             transform: `translateX(${parseFloat(getDeviation()) * 5}px)`,
//           }}
//         />
//       </div>
//       <p>Current Frequency: {currentFreq} Hz</p>
//       <p>Deviation from Target: {getDeviation()} Hz</p>
//     </div>
//   );
// };

// export default TuningForkChart;

















// import React from 'react';

// interface TuningForkChartProps {
//   currentFreq: number;
//   targetFreq: number;
// }

// const TuningForkChart: React.FC<TuningForkChartProps> = ({ currentFreq, targetFreq }) => {
//   const getDeviation = () => {
//     return (currentFreq - targetFreq).toFixed(2);
//   };

//   return (
//     <div>
//       <h3>Tuning Fork Style Chart</h3>
//       <div
//         style={{
//           width: '100%',
//           height: '50px',
//           position: 'relative',
//           border: '1px solid #ddd',
//         }}
//       >
//         <div
//           style={{
//             position: 'absolute',
//             left: '50%',
//             top: 0,
//             bottom: 0,
//             width: '2px',
//             backgroundColor: 'black',
//             transform: `translateX(${parseFloat(getDeviation()) * 5}px)`,
//           }}
//         />
//       </div>
//       <p>Current Frequency: {currentFreq} Hz</p>
//       <p>Deviation from Target: {getDeviation()} Hz</p>
//     </div>
//   );
// };

// export default TuningForkChart;
