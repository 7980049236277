import React, { useState } from 'react';
import { ISelectionData } from '../Temperature';

interface ISelectionControlsProps {
  selectionDataState: ISelectionData;
  setSelectionDataState: React.Dispatch<React.SetStateAction<ISelectionData>>;


  // childPanelInfoState: IChildPanelInfo;
  // setChildPanelInfoState: React.Dispatch<React.SetStateAction<IChildPanelInfo>>;
}

export const SelectionControls: React.FC<ISelectionControlsProps> = ({ selectionDataState, setSelectionDataState }) => {
  const [selectedDate, setSelectedDate] = useState<string>(selectionDataState.date);
  const [selectedTime, setSelectedTime] = useState<string>(selectionDataState.time);
  const [numberOfDays, setNumberOfDays] = useState<number>(selectionDataState.numberOfDays);
  const [maxDataPoints, setMaxDataPoints] = useState<number>(selectionDataState.maxDataPoints);
  const [showCompare, setShowCompare] = useState<boolean>(selectionDataState.showCompare);

  const handleInputChange = (fieldName: string, value: string | number | boolean) => {
    switch (fieldName) {
      case 'selectedDate':
        setSelectedDate(value as string);
        break;
      case 'selectedTime':
        setSelectedTime(value as string);
        break;
      case 'numberOfDays':
        setNumberOfDays(value as number);
        break;
      case 'maxDataPoints':
        setMaxDataPoints(value as number);
        break;
      case 'showCompare':
        setShowCompare(value as boolean);
        break;
      default:
        break;
    }
  };

  const items = [
    'Luminance',
    'Ultraviolet',
    'Sensor 1',
    'Sensor 3',
    'Vaper Pressure',
    'Ultraviolet',
    'Sensor 2',
    'Sensor 4',
  ];



  return (
    <div>
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <th style={{ width: '30%' }}>Date</th>
            <th style={{ width: '20%' }}>Time</th>
            <th style={{ width: '20%' }}>Days</th>
            <th style={{ width: '20%' }}>Max</th>
            <th style={{ width: '10%' }}>Comp</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input type="date" value={selectedDate} onChange={(e) => handleInputChange('selectedDate', e.target.value)} />
            </td>
            <td>
              <input type="time" value={selectedTime} onChange={(e) => handleInputChange('selectedTime', e.target.value)} />
            </td>
            <td>
              <input
                type="number"
                value={numberOfDays}
                onChange={(e) => handleInputChange('numberOfDays', parseInt(e.target.value, 10))}
              />
            </td>
            <td>
              <input
                type="number"
                value={maxDataPoints}
                onChange={(e) => handleInputChange('maxDataPoints', parseInt(e.target.value, 10))}
              />
            </td>
            <td>
              <input
                type="checkbox"
                checked={showCompare}
                onChange={(e) => handleInputChange('showCompare', e.target.checked)}
              />
            </td>
          </tr>
          {/* 
        <Luminance><Ultraviolet>Sensor 1
          Sensor 3
          
          </Ultraviolet></Luminance> */}
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            {items.slice(0, 4).map((item, index) => (
              <React.Fragment key={index}>
                <td>{item}</td>
                <td>
                  <input type="checkbox" />
                </td>
              </React.Fragment>
            ))}
          </tr>
          <tr>
            {items.slice(4).map((item, index) => (
              <React.Fragment key={index}>
                <td>{item}</td>
                <td>
                  <input type="checkbox" />
                </td>
              </React.Fragment>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
// Luminance
// Ultraviolet
// Sensor 1
// Sensor 3

// Vaper Pressure
// Ultraviolet
// Sensor 2
// Sensor 4

//   const [selectedDate, setSelectedDate] = useState<string>(selectionDataState.date);
//   const [selectedTime, setSelectedTime] = useState<string>(selectionDataState.time);
//   const [numberOfDays, setNumberOfDays] = useState<number>(selectionDataState.numberOfDays);
//   const [maxDataPoints, setMaxDataPoints] = useState<number>(selectionDataState.maxDataPoints);
//   const [showCompare, setShowCompare] = useState<boolean>(false);

//   const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setSelectedDate(e.target.value);
//     submit();
//   };

//   const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     setSelectedTime(e.target.value);
//     submit();
//   };

//   const handleDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const days = parseInt(e.target.value, 10);
//     setNumberOfDays(isNaN(days) ? 1 : days);
//     submit();
//   };

//   const handleMaxDataPointsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const hours = parseInt(e.target.value, 10);
//     setMaxDataPoints(isNaN(hours) ? 0 : hours);
//     submit();
//   };

//   const handleSubmit = (e: React.FormEvent) => {
//     e.preventDefault();
//     submit();
//   };

//   const submit = ()  => {
//     setSelectionDataState({ ...  selectionDataState, date: selectedDate, time: selectedTime, numberOfDays:numberOfDays, maxDataPoints:maxDataPoints});
//   }

//   const handleShowCompareChange = (e:  React.ChangeEvent<HTMLInputElement>): void =>  {
//     setShowCompare((e.target as HTMLInputElement).checked);
//   }









//   return (<form onSubmit={handleSubmit}>
//       <label>Select Date: </label>
//       <input
//         type="date"
//         value={selectedDate}
//         onChange={handleDateChange}
//       />

//       <label>Number of Days: </label>
//       <input
//         type="number"
//         value={numberOfDays}
//         onChange={handleDaysChange}
//       />

//       <label>Skip Hours: </label>
//       <input
//         type="number"
//         value={skipHours}
//         onChange={handleSkipHoursChange}
//       />

//       <button type="submit">Submit</button>
//     </form>
//   );
// };

// export default YourComponent;





// import React, { useState, useEffect } from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { HourData } from './yourHourDataFilePath'; // Import the correct path to your HourData interface

// interface DataPoint extends HourData {}

// interface DataGeneratorProps {
//   data: HourData[];
// }

// const DataGenerator: React.FC<DataGeneratorProps> = ({ data }) => {
//   const [startDate, setStartDate] = useState<Date | null>(new Date());
//   const [numberOfDays, setNumberOfDays] = useState<number>(1);
//   const [skipHours, setSkipHours] = useState<number>(0);
//   const [filteredData, setFilteredData] = useState<HourData[]>([]);

//   useEffect(() => {
//     if (!startDate) return;

//     const selectedDate = startDate.getTime();
//     const endDate = new Date(selectedDate);
//     endDate.setDate(endDate.getDate() + numberOfDays);

//     const filtered = data.filter((point) => {
//       const pointDate = new Date(point.time).getTime();
//       return pointDate >= selectedDate && pointDate < endDate.getTime();
//     });

//     const finalData = filtered.filter((_, index) => index % (skipHours + 1) === 0);

//     setFilteredData(finalData);
//   }, [startDate, numberOfDays, skipHours, data]);

//   return (
//     <div>
//       <div>
//         <label>Select a Start Date: </label>
//         <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
//       </div>
//       <div>
//         <label>Number of Days: </label>
//         <input
//           type="number"
//           value={numberOfDays}
//           onChange={(e) => setNumberOfDays(parseInt(e.target.value))}
//         />
//       </div>
//       <div>
//         <label>Skip Hours: </label>
//         <input
//           type="number"
//           value={skipHours}
//           onChange={(e) => setSkipHours(parseInt(e.target.value))}
//         />
//       </div>
//       <div>
//         <h3>Filtered Data:</h3>
//         <pre>{JSON.stringify(filteredData, null, 2)}</pre>
//       </div>
//     </div>
//   );
// };

// export default DataGenerator;
