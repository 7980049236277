import React from 'react';
import './invoice-view.scss';
import { IInvoice, calculateBalanceDue, calculateSubtotal, calculateTax, calculateTotal } from './Invoice';

interface IInvoiceViewProps {
  invoiceData: IInvoice;
}

export const InvoiceView: React.FC<IInvoiceViewProps> = ({ invoiceData }) => {
  const headerStyle = {
    backgroundColor: '#000000',
    color: '#ffffff',
    fontWeight: 'bold',
    border: '0px solid #ddd',
  };

  return (
    <div className="invoice-container">
      <div className="invoice-header">
        <div>
          <img src={invoiceData.logo} alt="Logo" />
          <br />
          <div className="invoice-address">
            <div>
              <div>{invoiceData.from}</div>
              <div>{invoiceData.fromAddressLineOne}</div>
              <div>{invoiceData.fromAddressLineTwo}</div>
            </div>
            <br />
            <div>Bill To:</div>
            <div>
              <div>{invoiceData.to}</div>
              <div>{invoiceData.toAddressLineOne}</div>
              <div>{invoiceData.toAddressLineTwo}</div>
            </div>
          </div>
        </div>
        <div className="invoice-details">
          <p className='invoice-text'>INVOICE</p>
          <p className='invoice-number'># {invoiceData.number}</p>
          <p>Date: {invoiceData.date}</p>
          <div className="balance-due">Balance Due: ${calculateBalanceDue(invoiceData).toFixed(2)}</div>
        </div>
      </div>
      <div className="invoice-items">
        <table>
          <thead className="table-header">
            <tr>
              <th style={headerStyle}>Item</th>
              <th style={headerStyle}>Quantity</th>
              <th style={headerStyle}>Rate</th>
              <th style={headerStyle}>Amount</th>
            </tr>
          </thead>
          <tbody>
            {invoiceData.items.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td>${parseFloat(item.unit_cost.toString()).toFixed(2)}</td>
                <td>${(item.quantity * parseFloat(item.unit_cost.toString())).toFixed(2)}</td>
              </tr>
            ))}
            <tr>
              <td colSpan={4} ></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td className='right-align'>Subtotal:</td>
              <td className='right-align'>${calculateSubtotal(invoiceData).toFixed(2)}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td className='right-align'>Tax:</td>
              <td className='right-align'>${calculateTax(invoiceData).toFixed(2)}</td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td className='right-align'>Total:</td>
              <td className='right-align'>${calculateTotal(invoiceData).toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <br />
        <div className='lower-invoice'>
          <div className="invoice-notes">
            <p>Notes: {invoiceData.notes}</p>
          </div>
          <div className="invoice-terms">
            <p>Terms: {invoiceData.terms}</p>
          </div>
        </div>
      </div>
    </div>
  );
};