import React, { useEffect, useState } from 'react';
import { IChildPanelInfoProps } from '../../../types/main';

const Freq: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  const [frequency, setFrequency] = useState<number | null>(null);
  const [isTuning, setIsTuning] = useState<boolean>(false);

  let audioContext: AudioContext | null = null;
  let analyser: AnalyserNode | null = null;
  let dataArray: Uint8Array;
  let animationId: number;

  const detectPitch = () => {
    if (analyser) {
      analyser.getByteFrequencyData(dataArray);

      let maxIndex = 0;
      let maxValue = 0;

      for (let i = 0; i < dataArray.length; i++) {
        if (dataArray[i] > maxValue) {
          maxValue = dataArray[i];
          maxIndex = i;
        }
      }

      const detectedFrequency = maxIndex * (audioContext!.sampleRate / analyser.fftSize);
      setFrequency(detectedFrequency);

      animationId = requestAnimationFrame(detectPitch);
    }
  };

  const startTuning = async () => {
    try {
      const AudioContextClass = window.AudioContext || (window as any).webkitAudioContext;
      audioContext = new AudioContextClass();
      analyser = audioContext.createAnalyser();
      analyser.fftSize = 2048;

      dataArray = new Uint8Array(analyser.frequencyBinCount);

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);

      setIsTuning(true);
      detectPitch();
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopTuning = () => {
    if (animationId) {
      cancelAnimationFrame(animationId);
    }
    if (audioContext) {
      audioContext.close();
    }
    setIsTuning(false);
  };

  useEffect(() => {
    return () => {
      stopTuning();
    };
  }, []);

  return (
    <div>
      <h1>Guitar Tuner</h1>
      <div>
        <button onClick={isTuning ? undefined : startTuning}>Start Tuning</button>
        <button style={{float:"right", backgroundColor: childPanelInfoState.mainPanelInfo.styles.dangerButtonColor }} className='dangerButtonColor' onClick={isTuning ? stopTuning : undefined}>Stop Tuning</button>
      </div>
      {isTuning && (
        <div>
          <p>Current Frequency: {frequency ? `${frequency.toFixed(2)} Hz` : 'N/A'}</p>
        </div>
      )}
    </div>
  );
};

export default Freq;
