import { AnyCnameRecord } from "dns";
import { IDataReturn, IDataForAPI, fetchPostsByTags, handleAdd, handleUpdate } from "../radApi";
import { onError } from "./mainHelpers";

export interface postOrderReturn {
  data: {
    postIds: number[];
  }
  postIds: number[];
};

export interface listOrderReturn { 
  portId: number | undefined; 
  orderedId: number[] 
}

export const updateOrderedPosts = (listFromTheOrderPost: (number | string)[], listOfAllPostIds: number[]): number[] => {
  // Convert strings to numbers in arr1
  const updatedListFromTheOrderPost = listFromTheOrderPost.map(item => typeof item === 'string' ? parseInt(item, 10) : item);
  const updatedListOfAllPostIds = listOfAllPostIds.map(item => typeof item === 'string' ? parseInt(item, 10) : item);

  // Convert arr1 to a Set for efficient membership checking
  const set1 = new Set(updatedListFromTheOrderPost);

  // Iterate through arr2 and add missing numbers to arr1
  for (const num of updatedListOfAllPostIds) {
    if (!set1.has(num)) {
      set1.add(num); // Add missing number to arr1
    }
  }
  // Convert the Set back to an array using Array.from or the spread operator
  const updatedArray = Array.from(set1); // Using Array.from
  // Alternatively:
  // const updatedArray = [...set1]; // Using the spread operator

  // Return the updated array as numbers
  return updatedArray;
};

export const onGetOrderData = async (dataForAPI: any, listOfAllPostIds?: number[]): Promise<listOrderReturn> => {
  const dataForAPIUpdated = { ...dataForAPI };
  if (dataForAPIUpdated.tagIds !== undefined) {
    dataForAPIUpdated.tagIds = [6, 8];
  }
  let portId: number | undefined = undefined;
  let orderedId: number[] = [];
  
  try {
    const postData: IDataReturn = await fetchPostsByTags(dataForAPIUpdated);
    if (postData.success && postData.posts !== undefined) {
      const dataFromTheOrderPost = postData.data as postOrderReturn[] | undefined;
      let listFromTheOrderPost: number[] = [];
      let listOfAllPostIdsOrUndefined: number[] = [];

      console.log('3 dataFromTheOrderPost',dataFromTheOrderPost);

      if (dataFromTheOrderPost !== undefined && dataFromTheOrderPost.length > 0) {
        listFromTheOrderPost = dataFromTheOrderPost[0].postIds as number[] | [];
      }

      if (listOfAllPostIds !== undefined) {
        listOfAllPostIdsOrUndefined = listOfAllPostIds;
      }


      console.log('4 listFromTheOrderPost',listFromTheOrderPost);
      console.log('5 listOfAllPostIdsOrUndefined',listOfAllPostIdsOrUndefined);

      const updatedListOfOrderedIds = updateOrderedPosts(listFromTheOrderPost, listOfAllPostIdsOrUndefined);
      orderedId = updatedListOfOrderedIds;

      let orderPostId: number | undefined = undefined;
      if (postData.posts.length > 0) {
        const postId = postData.posts[0].id;
        orderPostId = typeof postId === 'string' ? parseInt(postId, 10) : postId;
      }
      portId = orderPostId;
    } else {
      throw new Error('Division by zero is not allowed.');
    }
  } catch (error) {
    if (error instanceof Error) {
      console.error('An error occurred:', error.message);
    } else {
      console.error('An unknown error occurred:', error);
    }
  }

  return { portId, orderedId };
}

export const handleReorder = async (dataForAPI:IDataForAPI, orderPostId:number|undefined, orderedIds:number[], movedId: number, newIndex: number) => {
  //  Here we do  the saving and updating of the state
  const updatedIds = [...orderedIds];
  const movedIndex = updatedIds.findIndex((id) => id == movedId);
  const title = `SongList Order`;
  if (movedIndex !== -1) {
    updatedIds.splice(movedIndex, 1); // Remove the ID from its current position
    updatedIds.splice(newIndex, 0, Number(movedId)); // Insert the ID at the new position
    if (orderPostId === undefined) {
      await onAddReorder(dataForAPI, title, updatedIds);
    } else {
      await onUpdateReorder(dataForAPI, orderPostId, title, updatedIds);
    }
  }
};

const onUpdateReorder = async (dataForAPI:IDataForAPI, orderPostId:number, title: string, updatedIds: number[])=> {
  const updatedFormData = {
    postIds: updatedIds,
  }
  const dataForAPIUpdated = { ...dataForAPI, postId: orderPostId, title: title, formData: updatedFormData, tagIds: [6, 8] };
  const postData: IDataReturn = await handleUpdate(dataForAPIUpdated);
  let listOfOrderInfo:listOrderReturn;
  if (postData.success) {
  } else {
    throw new Error('Error updating order data.');
  }
};

const onAddReorder = async (dataForAPI:IDataForAPI, title: string, updatedIds: number[]) => {
  const updatedFormData = {
    postIds: updatedIds,
  }
  const dataForAPIUpdated = { ...dataForAPI, title: title, formData: updatedFormData, tagIds: [6, 8] };
  const postData: IDataReturn = await handleAdd(dataForAPIUpdated);
  if (postData.success) {
  } else {
    throw new Error('Error adding order data.');
  }
};