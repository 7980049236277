import React from 'react';
import { ChangeEvent, useState } from "react";
import { Counters } from "../../stage-items/counters/Counters";
import { IChildPanelInfoProps } from "../../../types/main";
import { StageType } from "../../../enums/main";

interface IMenuItem {
  id: number;
  stage: StageType;
  type: number;
  location: string;
  title: string;
  src: string;
}

interface IDropdownMenuProps {
  menuOptions: IMenuItem[];
}

export const PanelRight: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  // const menuOptions = [
  //   { id: 0, stage: StageType.Default, type: 1, location: 'ambler', title: 'Ambler, Pa', src: 'https://embed.windy.com/embed2.html?lat=39.402&lon=-77.476&detailLat=40.920&detailLon=-77.473&width=650&height=450&zoom=6&level=surface&overlay=rain&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=default&metricTemp=default&radarRange=-1' },
  //   // 'https://embed.windy.com/embed2.html?lat=40.182&lon=-75.148&detailLat=40.182&detailLon=-75.148&width=650&height=450&zoom=5&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1',
  //   { id: 1, stage: StageType.Default, type: 1, location: 'ambler2', title: 'Ambler, Pa', src: 'https://embed.windy.com/embed2.html?lat=27.994&lon=-77.520&detailLat=40.920&detailLon=-77.473&width=650&height=450&zoom=3&level=surface&overlay=rain&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=default&metricTemp=default&radarRange=-1' },
  //   { id: 2, stage: StageType.Default, type: 1, location: 'tamaqua', title: 'Tamaqua, Pa', src: 'https://embed.windy.com/embed2.html?lat=40.378&lon=-75.935&detailLat=40.565&detailLon=-75.935&width=650&height=450&zoom=9&level=surface&overlay=rain&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=default&metricTemp=default&radarRange=-1' },
  //   { id: 3, stage: StageType.Default, type: 1, location: 'tamaqua2', title: 'Tamaqua, Pa', src: 'https://embed.windy.com/embed2.html?lat=40.591&lon=-75.935&detailLat=40.779&detailLon=-75.222&width=650&height=450&zoom=9&level=surface&overlay=temp&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1' },
  //   { id: 4, stage: StageType.Default, type: 1, location: 'ventnor', title: 'Ventnor, Nj', src: 'https://embed.windy.com/embed2.html?lat=39.100&lon=-74.539&detailLat=39.292&detailLon=-74.955&width=650&height=450&zoom=9&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=default&metricTemp=default&radarRange=-1' },
  //   { id: 5, stage: StageType.Default, type: 1, location: 'ventnor2', title: 'Ventnor, Nj', src: 'https://embed.windy.com/embed2.html?lat=39.100&lon=-74.539&detailLat=39.292&detailLon=-74.955&width=650&height=450&zoom=9&level=surface&overlay=rain&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1' },
  //   { id: 6, stage: StageType.Default, type: 1, location: 'network', title: 'Network', src: 'http://p.artificial2.opalstacked.com/a-network/' },
  //   { id: 7, stage: StageType.Temperatures, type: 1, location: 'temperatures', title: 'Temperatures', src: 'http://p.artificial2.opalstacked.com/a-temperature/' },
  //   { id: 8, stage: StageType.Money, type: 2, location: 'money', title: 'Money', src: '' },
  //   { id: 9, stage: StageType.Default, type: 2, location: 'nicole', title: 'Nicole', src: '' },
  //   { id: 10, stage: StageType.Invoice, type: 2, location: 'invoice', title: 'Invoice', src: '' },
  //   { id: 11, stage: StageType.Default, type: 2, location: 'babysitter', title: 'Baby Sitter', src: '' },
  //   { id: 12, stage: StageType.Default, type: 2, location: 'words', title: 'Kid Words', src: '' },
  //   { id: 13, stage: StageType.Alarms, type: 2, location: 'alarms', title: 'Alarms', src: '' },
  //   { id: 14, stage: StageType.Default, type: 2, location: 'listOfCounters', title: 'List of Counters', src: '' },
  //   { id: 15, stage: StageType.Default, type: 1, location: 'supplements', title: 'Supplements', src: 'http://p.artificial2.opalstacked.com/supplements/' },
  //   { id: 16, stage: StageType.Default, type: 1, location: 'timestables', title: 'Times Tables', src: 'https://www.timestables.com/' },
  //   { id: 17, stage: StageType.Default, type: 1, location: 'additiontable', title: 'Addition Table', src: 'https://www.mathsisfun.com/numbers/addition-table.html' },
  //   { id: 18, stage: StageType.PostData, type: 1, location: '', title: 'Post Data', src: '' },
  // ];

  const [selectedItem, setSelectedItem] = useState<StageType | null>(null);

  // const handleMenuItemClick = (item: StageType) => {
  //   setPanelInfoState((prevState) => ({
  //     ...prevState, // Keep all other properties unchanged
  //     center: { stage: item.stage }, // Update the specific property
  //   }));
  //   setSelectedItem(item);
  //   // Perform any additional actions when a menu item is clicked
  // };
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    // // const selectedSound = event.target.value as StageType;
    // // onSelect(selectedSound);
    //    setPanelInfoState((prevState) => ({
    //   ...prevState, // Keep all other properties unchanged
    //   center: { stage: event.target.value as StageType }, // Update the specific property
    // }));


    // setSelectedItem(event.target.value as StageType);
  };
  return <div >
    <div>
      {/* <label>Select an item:</label> */}
      {/* <select
        value={selectedItem?.id || ''}
        onChange={(e) => {
          const selectedId = e.target.value;
          // StageType
          const selectedItem = menuOptions.find((item) => item.id.toString() === selectedId) || null;
          if (selectedItem !== null) {
            handleMenuItemClick(selectedItem);
          }
        }}
      >
        <option value="">Select...</option>
        {menuOptions.map((item) => (
          <option key={item.id} value={item.id}>
            {item.title}
          </option>
        ))}
      </select> */}















      {/* <select onChange={handleChange}>
        {Object.keys(StageType).map((soundOption) => (
        <option key={soundOption} value={StageType[soundOption as keyof typeof StageType]}>
          {soundOption}
        </option>
      ))}
      </select>
          <div className="spacer-10" /> */}

















      {/* {selectedItem && (
        <div>
        </div>
      )} */}
      {/* <h2>{selectedItem}</h2> */}
      {/* Render additional details or perform actions based on the selected item */}




      <Counters childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} ></Counters>






    </div>
    {/* <table className="table-right-side">
      <tr>
        <td>
          <button className="button-right-side" onClick={closeFullscreen} style={isFullScreenMode ? { display: 'block' } : { display: 'none' }}>
            Close Fullscreen
          </button>
          <button className="button-right-side" onClick={openFullscreen} style={!isFullScreenMode ? { display: 'block' } : { display: 'none' }}>
            Open Fullscreen
          </button>
        </td>
        <td>
          <button className="button-right-side" onClick={toggleCenterFreeze}>
            {isCenterFrozen ? 'Unfreeze' : 'Freeze'}
          </button>
        </td>
        <td>
          <button className="button-right-side" onClick={toggleShowInfo}>
            {isShowInfo ? 'Hide Info' : 'Show Info'}
          </button>
        </td>
      </tr>
    </table>
    {isShowInfo && (
      <div className="info-box">
        <h2>Events</h2>
        <div className="events-box">
          <div className="event-row">
            {arrayOfTitles.map((title, index) => (
              <div key={index} className="event-title">
                {title}
              </div>
            ))}
          </div>
          <div className="event-row">
            {arrayOfEvents.map((event, index) => (
              <div key={index} className="event-item">
                {event}
              </div>
            ))}
          </div>
        </div>
      </div>
    )} */}
  </div>
}