import React from 'react';
import { useState } from 'react';
import ApiService from '../../serviceApi';
import './add-item.scss';
import { IMoney } from '../../../types/money';
import { moneyOptions, moneyOptionsLookUp, peopleLookUp, peopleOptions } from '../../helpers/moneyHelpers';
import { ICounter } from './Counters';

interface IMoneyProps { 
  onAdd: (title: string,formData: ICounter) => void;
}

export const AddItem: React.FC<IMoneyProps> = ({onAdd}) => {
  const [formData, setFormData] = useState<ICounter>({
    id: 0,
    title: '',
    code: ''
  });
  const [people, setPeople] = useState<string[]>(peopleOptions); // Replace with your list of people
  const [options, setOptions] = useState<string[]>(moneyOptions); // Replace with your list of options

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const addCounter = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    console.log('Form Data', formData);

    // const { name } = formData;
    // const howMuch = Number(amount.toString().split(',').join('')).toFixed(2).toString();
    // const updatedFormData = {...formData, amount:Number(amount)}
    onAdd(formData.title, formData);
    setFormData(prevState => ({
      ...prevState,
      title: "",
      // [amount]: 0,
    }));
  };

  return (
    <form onSubmit={addCounter}>
      <div>
        <table className='table-100'>
          <tbody>
            <tr className='no-padding'>
              <td>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                />
              </td>
              <td className='column-button'>
                <button type="submit">Add Counter</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='spacer-10'/>
    </form>
  );
};


// console.log('howMuch', howMuch);

// const info = {
//     post: {
//         status: 'published',
//         title: `${person}-${what}-${option}-${howMuch}`,
//         html: `{"who":"${person}","what":"${what}","option":"${option}","value":"${howMuch}"}`,
//         tags: [person, 'money'],
//         featured: true,
//     },
// };

// // Assuming this.appService.addObject is an HTTP service for making API calls
// apiService.addObject('posts', info).then((res: any) => {
//     console.log('Return from addMoney', res);
//     // You might want to use state to trigger a re-render or perform other actions here
// });
    
//   const addTodo = () => {
//     const newTodo = { "todo": { "id": todos.length + 1, "title": inputTitle, "completed": false }, "id": 1 }
//     apiService.addObject('todos', newTodo)
//         .then(data => {
//             getAllTodos();
//         })
//         .catch(error => {
//             console.error('Error:', error);
//         });
//     setInputTitle('');

// };