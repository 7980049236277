import React from 'react';
import { IMoney } from "../../../types/money";
import { moneyOptionsLookUp, peopleLookUp } from "../../helpers/moneyHelpers";
import { IChildPanelInfoProps } from '../../../types/main';

interface IResultListProps extends IChildPanelInfoProps {
  moneyList: IMoney[];
  onDelete: (index: number) => void;
}
export const ResultList: React.FC<IResultListProps> = ({moneyList, onDelete, childPanelInfoState, setChildPanelInfoState }) => {
  return <div>
    <h2>List Of Items</h2>
    <div className="money-form">
      <table border={1}>
        <tbody>
          {moneyList.map((rowData, index) => (
            <tr key={index}>
              <td className="column-center">
                {/* <span
                  className="todo-text"
                  style={{ textDecoration: rowData.completed ? 'line-through' : 'none' }}
                  onClick={() => onToggle(rowData.id)}
                >
                  {rowData.title}
                </span> */}
                {moneyList.length-index}
              </td>
              <td>{peopleLookUp(rowData.who)}</td>
              <td>{rowData.what}</td>
              <td>{moneyOptionsLookUp(rowData.option).replace(/\(.*?\)/g, '')}</td>
              <td className='highlight-section'>${rowData.amount.toLocaleString()}</td>
              <td>{rowData.created}</td>
              {/* <td className='column-button'>
                <button style={{ backgroundColor: childPanelInfoState.mainPanelInfo.styles.dangerButtonColor }} title="Delete" onClick={() => onDelete(index as number)}>Delete</button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
}