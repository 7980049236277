import { DataPoint, HourData, ISensorData, ISensorInfo } from "./Temperature";

export const generateDataPoints = (hoursData: HourData[]): DataPoint[] => {
    const arrayOfPoints: DataPoint[] = [];
    console.log('hoursData', hoursData);
    for (let i = 0; i < hoursData.length; i++) {
      const { time, day,  hour, sensorInfo } = hoursData[i];
      
      //THIS WILL SHOW ALL SENSORS
      // for (let j = 0; j < sensorInfo.length; j++) {
        // Shift and normalize values
        const scaledX = (day / 24) * 0.9 - .45;
        // console.log('sensorInfo -->',sensorInfo[j])
        const scaledY = ((sensorInfo[0].sensorData.temperature - 57) / (80 - 57) - 0.5) * 0.9; // Scale Y to be between -0.45 and 0.45
        const scaledZ = (hour / 100 - 0.5) * 0.9;
        arrayOfPoints.push({ x: scaledX, y: scaledY, z: scaledZ, hourData:hoursData[i] });
      // }
    }
    return arrayOfPoints;
  };

export  const generateRandomInRange = (previous: number, next: number, min: number, max: number): number => {
  const random = Math.random() * (next - previous) + previous;
  return Math.max(min, Math.min(max, random));
};

export const generateTemperatureHumidity = (
    seedTemperature: number | undefined,
    seedHumidity: number | undefined,
    prevTemperature: number | undefined,
    prevHumidity: number | undefined
  ): { temperature: number | undefined; humidity: number | undefined } => {
    let temperature: number | undefined = undefined;
    let humidity: number | undefined = undefined;
    if (
      seedTemperature !== undefined &&
      seedHumidity !== undefined &&
      prevTemperature !== undefined &&
      prevHumidity !== undefined
    ) {
      temperature = generateRandomInRange(
        prevTemperature - 2,
        prevTemperature + 2,
        seedTemperature - 20,
        seedTemperature + 20
      ) as number;
      humidity = generateRandomInRange(
        prevHumidity - 2,
        prevHumidity + 2,
        seedHumidity - 20,
        seedHumidity + 20
      );
    }
    return { temperature, humidity };
  };


  export const generateDaysData = (
    startDate: string,
    numberOfDays: number,
    colors: string[][],
    sensorNames: string[],
    seedTemperatures:  number[],
    seedHumidities: number[]
  ): HourData[] => {
    if (numberOfDays < 1 || numberOfDays > 31) {
      throw new Error("Number of days must be between 1 and 31");
    }
    const initialDate = new Date(startDate); // Adjusted the date format and included timezone
    
    let sensorInfo:ISensorInfo[] = [];

    for (let index = 0; index < seedTemperatures.length; index++) {
      const element = seedTemperatures[index];
      sensorInfo.push({
        showSensor: true,
        sensorName: "Sensor :" + index,
        colors:colors[index],
        sensorData: {
          temperature: seedHumidities?.[index],
          humidity: seedHumidities?.[index],
          luminance:0,
          ultraviolet:0,
          vaporPressure:0,
          notes:""
      }
    });
    }

    const hoursData: HourData[] = [
      { 
        time: initialDate.toISOString(), 
        day: 1, 
        hour: 1, 
        sensorInfo: sensorInfo
      }
    ];
  
    //
    for (let day = 1; day <= numberOfDays; day++) {
      const prevData = hoursData[hoursData.length - 1];
      for (let hour = 0; hour < 24; hour++) {

        let sensorInfo:ISensorInfo[] = [];

        for (let index = 0; index < sensorNames.length; index++) {
          // const element = sensorNames[index];
          const temperatureHumidity = generateTemperatureHumidity(
            seedTemperatures?.[index],
            seedHumidities?.[index],
            prevData.sensorInfo[index].sensorData.temperature,
            prevData.sensorInfo[index].sensorData.humidity,
          )

          const sensorData: ISensorData = {
            temperature: temperatureHumidity.temperature as number,
            humidity: temperatureHumidity.humidity as number,
            luminance: 0,
            ultraviolet: 0,
            vaporPressure: 0,
            notes: ""
          }

          sensorInfo.push({
            showSensor: true,
            sensorName: sensorNames[index],
            colors:colors[index],
            sensorData:  sensorData
          })
        }

        // Create a new Date object and update the date and hours
        const newTime = new Date(prevData.time);
        newTime.setDate(
          newTime.getDate() + (day === 1 ? 0 : 1)
          ); // Only increment days for day > 1
        newTime.setHours(hour, 0, 0, 0);
  
        hoursData.push({
          time: newTime.toISOString(),
          day: day,
          hour: hour,
          sensorInfo: sensorInfo
        });
      }
    }
    // remove the  initial data point
    hoursData.shift();
    return hoursData;
  };




    // const generateDataPoints = (): DataPoint[] => {
    //   const arrayOfPoints: DataPoint[] = [];
    //   let lastY = generateRandomY(68); // Initial y value
    
    //   const xValues = Array.from({ length: 7 }, (_, i) => i * 0.0417);
    
    //   for (let x = 0; x < xValues.length; x++) {
    //     const zValues = Array.from({ length: 24 }, (_, i) => i * 0.0417);
    
    //     const maxY = Math.max(...zValues);
    //     const minY = Math.min(...zValues);
    
    //     const maxX = Math.max(...xValues);
    //     const minX = Math.min(...xValues);
    
    //     for (let z = 0; z < zValues.length; z++) {
    //       lastY = generateRandomY(lastY); // Update y value for each iteration
    
    //       // Shift and normalize values
    //       const scaledX = ((xValues[x] - minX) / (maxX - minX) - 0.5) * 0.9;
    //       const scaledY = ((lastY - 57) / (80 - 57) - 0.5) * 0.9; // Scale Y to be between -0.45 and 0.45
    //       const scaledZ = ((zValues[z] - minY) / (maxY - minY) - 0.5) * 0.9;
    
    //       // Example: Generate random temperature and humidity values
    //       const temperature = generateRandomInRange(60, 80);
    //       const humidity = generateRandomInRange(40, 60);
    
    //       arrayOfPoints.push({ x: scaledX, y: scaledY, z: scaledZ, temperature, humidity });
    //     }
    //   }
    
    //   return arrayOfPoints;
    // };





    // const generateDataPoints = (hoursData: HourData[] = []): DataPoint[] => {
    //   const arrayOfPoints: DataPoint[] = [];
    
    //   for (let i = 0; i < hoursData.length; i++) {
    //     const { time, temperature, humidity } = hoursData[i];
    
    //     // Process your time, temperature, and humidity data as needed
    //     // For this example, I'm assuming the time is in ISO format and extracting the hour
    //     const hour = new Date(time).getUTCHours();
    
    //     // Convert hour to the range [0, 24) and normalize
    //     const scaledX = (hour / 24) * 0.9 - 0.45;
    //     const scaledY = ((temperature - 57) / (80 - 57) - 0.5) * 0.9;
    //     const scaledZ = (humidity / 100 - 0.5) * 0.9;
    
    //     arrayOfPoints.push({ x: scaledX, y: scaledY, z: scaledZ, temperature, humidity });
    //   }
    
    //   return arrayOfPoints;
    // };





    






  
//   const generateDaysData = (numberOfDays: number): HourData[] => {
//     if (numberOfDays < 1 || numberOfDays > 31) {
//       throw new Error("Number of days must be between 1 and 31");
//     }
  
//     const hoursData: HourData[] = [
//       { time: "Mon, 01 Jan 2024 00:00:00 GMT", temperature: 60, humidity: 60 },
//     ];
  
//     for (let day = 1; day < numberOfDays; day++) {
//       const prevData = hoursData[hoursData.length - 1];
  
//       for (let hour = 0; hour < 24; hour++) {
//         const temperature = generateRandomInRange(prevData.temperature - 2, prevData.temperature + 2, 55, 90);
//         const humidity = generateRandomInRange(prevData.humidity - 2, prevData.humidity + 2, 60, 80);
  
//         const newTime = new Date(prevData.time);
//         newTime.setUTCDate(newTime.getUTCDate() + 1); // Move to the next day
//         newTime.setUTCHours(0); // Reset hours to 0 for the new day
//         newTime.setUTCHours(hour); // Set the current hour
  
//         hoursData.push({
//           time: newTime.toUTCString(),
//           temperature,
//           humidity,
//         });
//       }
//     }
  
//     return hoursData;
//   };





// const generateDataPoints = () => {
//   const dataPoints: DataPoint[] = [];

//   for (let x = 0.1; x <= 0.9; x += 0.1) {
//     for (let y = 0.1; y <= 0.9; y += 0.1) {
//       const z = Math.random(); // You can adjust how z is generated based on your requirements
//       dataPoints.push({ x, y, z });
//     }
//   }

//   return dataPoints;
// };


// const dataPoints: DataPoint[] = [];

// for (let x = 0.1; x <= 3.0; x += 0.1) {
//   for (let z = 0.1; z <= 2.4; z += 0.1) {
//     const y = Math.random(); // You can adjust how y is generated based on your requirements
//     dataPoints.push({ x, y, z });
//   }
// }



// const generateDataPoints = () => {
//   const dataPoints: DataPoint[] = [];

//   // const zStep = 1 / 24;
//   // const xStep = 1.29 / 31;
//   // const yMax = 30;

//   const zStep = 24; // hours
//   const xStep = 1; // days
//   const yMax = 30; // temperature max height

//   for (let z = 0; z <= 1; z += zStep) {
//     for (let x = 0; x <= 1.29; x += xStep) {
//       for (let y = 0; y <= yMax; y += yMax / 24) {
//         dataPoints.push({ x, y, z });
//       }
//     }
//   }

//   return dataPoints;
// };

// const generateRandomY = (lastY: number): number => {
//     const min = Math.max(57, lastY - 2);
//     const max = Math.min(80, lastY + 2);
//     return Math.floor(Math.random() * (max - min + 1)) + min;
//   };
  
  // const generateDataPoints = (): DataPoint[] => {
  //   const arrayOfPoints: DataPoint[] = [];
  //   let lastY = generateRandomY(68); // Initial y value
  
  //   for (let x = 0; x <= 1; x++) {
  //     for (let z = 0; z <= 23; z++) {
  //       lastY = generateRandomY(lastY); // Update y value for each iteration
  //       arrayOfPoints.push({ x, y: lastY, z });
  //     }
  //   }
  
  //   return arrayOfPoints;
  // };
  
  
  /////////////NUMBER WITH NO ADJUSTMENTS//////////////
  
  // const generateDataPoints = (): DataPoint[] => {
  //   const arrayOfPoints: DataPoint[] = [];
  //   let lastY = generateRandomY(68); // Initial y value
  
  //   for (let x = 0; x <= 1; x += 0.1) {
  //     for (let z = 0; z <= 1; z += 0.0417) { // Adjust the step size to ensure a total of 24 points
  //       lastY = generateRandomY(lastY); // Update y value for each iteration
  
  //       // Reduce x, y, and z proportionally
  //       const scaledX = x / 1; // Max x value is 1
  //       const scaledY = (lastY - 57) / (80 - 57); // Normalize y to be between 0 and 1
  //       const scaledZ = z / 1; // Max z value is 1
  
  //       arrayOfPoints.push({ x: scaledX, y: scaledY, z: scaledZ });
  //     }
  //   }
  
  //   return arrayOfPoints;
  // };
  
  // const generateDataPoints = (): DataPoint[] => {
  //   const arrayOfPoints: DataPoint[] = [];
  //   let lastY = generateRandomY(68); // Initial y value
  
  //   const xValues = Array.from({ length: 2 }, (_, i) => i * 0.0417);
  
  //   for (let x = 0; x < xValues.length; x++) {
  //     const zValues = Array.from({ length: 24 }, (_, i) => i * 0.0417);
  
  //     const max = Math.max(...zValues);
  //     const min = Math.min(...zValues);
  
  //     for (let z = 0; z < zValues.length; z++) {
  //       lastY = generateRandomY(lastY); // Update y value for each iteration
  
  //       // Shift and normalize values
  //       const scaledX = ((xValues[x] - xValues[0]) / (xValues[xValues.length - 1] - xValues[0]) - 0.5) * 0.9;
  //       const scaledY = (lastY - 57) / (80 - 57);
  //       const scaledZ = ((zValues[z] - min) / (max - min) - 0.5) * 0.9;
  
  //       arrayOfPoints.push({ x: scaledX, y: scaledY, z: scaledZ });
  //     }
  //   }
  
  //   return arrayOfPoints;
  // };
  
  
  
  
  
  
  
  // const generateDaysData = (numberOfDays: number): HourData[] => {
  //   if (numberOfDays < 1 || numberOfDays > 31) {
  //     throw new Error("Number of days must be between 1 and 31");
  //   }
  
  //   const hoursData: HourData[] = [
  //     { time: "Mon, 1 Jan 2024 00:00:00 GMT", temperature: 60, humidity: 60 },
  //   ];
  
  //   for (let day = 1; day < numberOfDays; day++) {
  //     for (let hour = 0; hour < 24; hour++) {
  //       const prevData = hoursData[hoursData.length - 1];
  //       const temperature = generateRandomInRange(prevData.temperature - 2, prevData.temperature + 2, 55, 90);
  //       const humidity = generateRandomInRange(prevData.humidity - 2, prevData.humidity + 2, 60, 80);
  
  //       hoursData.push({
  //         time: new Date(prevData.time).toISOString(),
  //         temperature,
  //         humidity,
  //       });
  //     }
  //   }
  
  //   return hoursData;
  // };
  
  // interface HourData {
  //   time: string;
  //   temperature: number;
  //   humidity: number;
  // }
  
  
  
  // const generateDaysData = (numberOfDays: number): HourData[] => {
  //   if (numberOfDays < 1 || numberOfDays > 31) {
  //     throw new Error("Number of days must be between 1 and 31");
  //   }
  
  //   const hoursData: HourData[] = [
  //     { time: "Mon, 01 Jan 2024 00:00:00 GMT", temperature: 60, humidity: 60 },
  //   ];
  
  //   for (let day = 1; day < numberOfDays; day++) {
  //     const prevData = hoursData[hoursData.length - 1];
  
  //     for (let hour = 1; hour < 24; hour++) {
  //       const temperature = generateRandomInRange(prevData.temperature - 2, prevData.temperature + 2, 55, 90);
  //       const humidity = generateRandomInRange(prevData.humidity - 2, prevData.humidity + 2, 60, 80);
  
  //       const newTime = new Date(prevData.time);
  //       newTime.setUTCDate(newTime.getUTCDate() + 1); // Move to the next day
  //       newTime.setUTCHours(0); // Reset hours to 0 for the new day
  //       newTime.setUTCHours(hour); // Set the current hour
  
  //       hoursData.push({
  //         time: newTime.toUTCString(),
  //         temperature,
  //         humidity,
  //       });
  //     }
  //   }
  
  //   return hoursData;
  // };
  
  