// import React, { useState, useEffect } from 'react';
// import './piano.scss'; // Import a CSS file for styling

// const Piano: React.FC = () => {
//   const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
//   const baseFrequency = 261.63; // Frequency of C4
//   const [currentFrequency, setCurrentFrequency] = useState<number>(baseFrequency);

//   useEffect(() => {
//     const context = new (window.AudioContext || (window as any).webkitAudioContext)();
//     setAudioContext(context);

//     return () => {
//       context.close();
//     };
//   }, []);

//   const playNote = (frequency: number) => {
//     if (!audioContext) return;

//     const oscillator = audioContext.createOscillator();
//     oscillator.type = 'sine';
//     oscillator.frequency.setValueAtTime(frequency, audioContext.currentTime);

//     const gainNode = audioContext.createGain();
//     oscillator.connect(gainNode);
//     gainNode.connect(audioContext.destination);

//     oscillator.start();
//     gainNode.gain.setValueAtTime(1, audioContext.currentTime);
//     gainNode.gain.exponentialRampToValueAtTime(0.001, audioContext.currentTime + 1);

//     oscillator.stop(audioContext.currentTime + 1);

//     setCurrentFrequency(frequency);
//   };

//   const handleButtonClick = (frequency: number) => {
//     playNote(frequency);
//   };

//   const renderPianoKeys = () => {
//     const keys = ['C', 'D', 'E', 'F', 'G', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'A', 'B', 'C'];

//     return keys.map((key, index) => {
//       const frequency = baseFrequency * Math.pow(2, index / 12); // Calculate frequency for each key
//       return (
//         <button key={index} onClick={() => handleButtonClick(frequency)}>
//           {key}
//         </button>
//       );
//     });
//   };

//   return <div>
//     {currentFrequency}
//     <div className="piano-container">
//       {renderPianoKeys()}
//     </div>
//   </div>;
// };

// export default Piano;


import React, { useState, useEffect } from 'react';
import './piano.scss';

const Piano: React.FC = () => {
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [isMajorMode, setIsMajorMode] = useState(true);
  
  const baseFrequency = 261.63; // Frequency of C4
  const [currentFrequency, setCurrentFrequency] = useState<number>(baseFrequency);

  useEffect(() => {
    const context = new (window.AudioContext || (window as any).webkitAudioContext)();
    setAudioContext(context);

    return () => {
      context.close();
    };
  }, []);

  const playNote = (frequency: number) => {
    if (!audioContext) return;

    const oscillator = audioContext.createOscillator();
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(frequency, audioContext.currentTime);

    const gainNode = audioContext.createGain();
    oscillator.connect(gainNode);
    gainNode.connect(audioContext.destination);

    oscillator.start();
    gainNode.gain.setValueAtTime(1, audioContext.currentTime);
    gainNode.gain.exponentialRampToValueAtTime(0.001, audioContext.currentTime + 1);

    oscillator.stop(audioContext.currentTime + 1);
    setCurrentFrequency(frequency);
  };

  const handleButtonClick = (frequency: number) => {
    playNote(frequency);
  };

  const renderScaleKeys = (baseFrequency: number, intervals: number[]) => {
    return intervals.map((interval, index) => {
      const frequency = baseFrequency * Math.pow(2, interval / 12);
      return (
        <button key={index} onClick={() => handleButtonClick(frequency)}>
          {isMajorMode ? 'CDEFGAB'[index % 7] : 'CDEFGAB'[index % 7].toLowerCase()}
        </button>
      );
    });
  };

  const renderPianoKeys = () => {
    const majorIntervals = [0, 2, 4, 5, 7, 9, 11]; // Major scale intervals
    const minorIntervals = [0, 2, 3, 5, 7, 8, 10]; // Natural minor scale intervals

    // const baseFrequency = 261.63; // Frequency of C4

    return (
      <>
        <div className='section-border'>
          <h3>Major Scale</h3>
          {renderScaleKeys(baseFrequency, majorIntervals)}
        </div>
        <div className='section-border'>
          <h3>Minor Scale</h3>
          {renderScaleKeys(baseFrequency, minorIntervals)}
        </div>
      </>
    );
  };

  return <div>
    {currentFrequency.toFixed(2)} Hz
    <div className="piano-container">
      {/* <label>
        <input type="checkbox" checked={isMajorMode} onChange={() => setIsMajorMode(!isMajorMode)} />
        Major Mode
      </label> */}
      {renderPianoKeys()}
    </div>
    </div>;
};

export default Piano;
