import React from 'react';
import { useEffect, useState } from 'react';
import './counters.scss';
import ApiService from '../../serviceApi';
import { listOfCounters } from '../../serviceData';
import { IDataReturn, IDataForAPI, IPostData, fetchPostsByTags, fetchPostsByTagsAndDate, handleAdd } from '../../radApi';
import { AddItem } from './AddItem';
import { IChildPanelInfoProps } from '../../../types/main';
import { formatDateForDay } from '../../helpers/mainHelpers';

interface iSourceData {
  id: number;
  updated_at?: string;
  title: string;
  status?: string;
  featured?: boolean;
  updated_at_formatted?: string;
  title_formatted?: string;
}

export interface ICounter {
  id: number;
  title: string;
  code: string;
}

// interface iPostToTarget {
//   featured: boolean;
//   status: string;
//   title: string;
//   html: string;
//   tags: string[];
// }




// Disable Cache
// 12 requests
// 6.16 MB / 16.46 kB transferred
// Finish: 3.46 s
// DOMContentLoaded: 171 ms
// load: 1.03 s

// 0	Object { id: "66298e926c0ebc0be7d64fca", updated_at: "2024-04-24T22:58:26.000Z", title: "Dogs-Watered", … }
// id	"66298e926c0ebc0be7d64fca"
// updated_at	"2024-04-24T22:58:26.000Z"
// title	"Dogs-Watered"
// status	"published"
// featured	true
// 1	Object { id: "66298e166c0ebc0be7d64fc4", updated_at: "2024-04-24T22:56:22.000Z", title: "Dogs-Watered", … }
// id	"66298e166c0ebc0be7d64fc4"
// updated_at	"2024-04-24T22:56:22.000Z"
// title	"Dogs-Watered"
// status	"published"
// featured	true

//10

export const Counters: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  const dataForAPI: IDataForAPI = {
    userId: childPanelInfoState.userInfo.id,
    key: childPanelInfoState.userInfo.key,
    tagIds: [10],
    status: 1,
    encryptData: childPanelInfoState.mainPanelInfo.system.encryptData
  }
  const [buttons, setButtons] = useState<ICounter[]>([]);
  // const apiService = new ApiService();

  // const addTodo = () => {
  //     const newTodo = { "todo": { "id": todos.length + 1, "title": inputTitle, "completed": false }, "id": 1 }
  //     apiService.addObject('todos', newTodo)
  //         .then(data => {
  //             getAllTodos();
  //         })
  //         .catch(error => {
  //             console.error('Error:', error);
  //         });
  //     setInputTitle('');

  // };

  // const toggleTodo = (id: number) => {
  //     const updatedTodos = todos.map((todo) => {
  //         if (todo.id === id) {
  //             return { ...todo, completed: !todo.completed };
  //         }
  //         return todo;
  //     });
  //     setTodos(updatedTodos);
  // };

  // const deleteTodo = (id: number) => {
  //     apiService.delete('todos', id)
  //         .then(data => {
  //             getAllTodos();
  //         })
  //         .catch(error => {
  //             console.error('Error:', error);
  //         });
  // };

  const extractPageValues = (arrayOfEvents: iSourceData[]) => {
    for (let index = 0; index < arrayOfEvents.length; index++) {
      // var today = new Date(arrayOfEvents[index].updated_at);
      // arrayOfEvents[index].updated_at_formatted = today.toLocaleTimeString();
      arrayOfEvents[index].title_formatted = capitalizeFirstLetter(arrayOfEvents[index].title.split('-').join(' '));
    }

    const allCounters: ICounter[] = [];

    for (let index = 0; index < listOfCounters.length; index++) {
      allCounters.push(
        {
          id: index,
          title: listOfCounters[index],
          // value: arrayOfEvents.filter((post: any) => post.title === listOfCounters[index].split(' ').join('-')).length,
          code: listOfCounters[index].split(' ').join('-')
        }
      );
    }
    setButtons(allCounters);
  }

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  // const getAllCounters = () => {
  //   // var today = new Date();
  //   // var dd = String(today.getDate()).padStart(2, '0');
  //   // var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  //   // var yyyy = today.getFullYear();

  //   // //we need to change this to 4am for some crazy reason
  //   // var dateString = yyyy + '-' + mm + '-' + dd + ' 04:00:00';

  //   // const url = `posts/date`;

  //   // let info = {
  //   //   date: dateString
  //   // };

  //   // apiService.addObject(url, info)
  //   //   .then(data => {
  //   //     extractPageValues(data as iSourceData[]);
  //   //   })
  //   //   .catch(error => {
  //   //     console.error('Error:', error);
  //   //   });
  // }

  
  const [allPosts, setAllPosts] = useState<IPostData[]>([]);
  const [counters, setCounters] = useState<ICounter[]>([]);

  const countCode = (code:string):number => {
    const filterForCode=counters.filter(c => c.code === code);
    return filterForCode.length;
  }

  const onGetData = () => {
    const dataForAPIUpdated = { ...dataForAPI };
    dataForAPIUpdated.getForDate=formatDateForDay(new Date());
    let arrayOfEvents: iSourceData[] = [];
    arrayOfEvents.push({
      id: 0,
      title: 'not sure',
    });

    // creates buttons
    extractPageValues(arrayOfEvents);
    // see haw many times button was pressed today
    fetchPostsByTagsAndDate(dataForAPIUpdated).then((postData: IDataReturn) => {
      if (postData.success) {
        setAllPosts(postData.posts as IPostData[]);
        const data = postData.data as ICounter[];

        console.log('data', data);

        setCounters(data);
      } else {
        console.error('Error Updating', postData.errors, postData.requestOptions, postData.variables);
      }
    })
    .catch((error) => {
      console.error('Error Updating:', error);
    });
  }

  const onAdd = (title: string, newEntry: ICounter) => {
    const dataForAPIUpdated = { ...dataForAPI, title: title, formData: newEntry };
    handleAdd(dataForAPIUpdated).then((postData: IDataReturn) => {
      if (postData.success) {
        onGetData();
      } else {
        console.error('Error Adding', postData.errors, postData.requestOptions, postData.variables);
      }
    })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    onGetData();
  }, []);

  const buttonPress = (index: number): void => {
    const newEntry = {
      title: buttons[index].title,
      code: buttons[index].code,
    }

    const dataForAPIUpdated = { ...dataForAPI, title: buttons[index].title, formData: newEntry };
    handleAdd(dataForAPIUpdated).then((postData: IDataReturn) => {
      if (postData.success) {
        onGetData();
      } else {
        console.error('Error Adding', postData.errors, postData.requestOptions, postData.variables);
      }
    })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  return <div >
    <AddItem onAdd={onAdd} />
    {buttons && (
      // <span className="counters">
      //   {buttons.map((item,index) => (
      //     <span key={item.code}>
      //       <button onClick={() => buttonPress(index)}
      //         className="round">
      //         {item.title}
      //       </button>
      //       <span className='count-outputs'><span>{countCode(item.code)}</span> <span>{item.title}</span></span>
      //     </span>
      //   ))}
      // </span>
      <span className="counters">
      {buttons.map((item, index) => (
        <span key={item.code} className="button-container">
          <button onClick={() => buttonPress(index)} className="round">
            {item.title}
          </button>
          <span className='count-outputs'>
            <span>{countCode(item.code)}</span>&nbsp;
            <span>{item.title}</span>
          </span>
        </span>
      ))}
    </span>
    )}


    {/* <span className="count-down-timer">
            <table>
                <tr>
                    <td>
                        Screen change: {countDownVar}
                    </td>
                </tr>
                <tr>
                    <td>
                        Refresh info: {countDown2Var}
                    </td>
                </tr>
            </table>
        </span> */}
  </div>
}