import React from 'react';
import { useEffect, useState } from 'react';
import './App.scss';
import { IUserInfo } from './types/main';
import { Main } from './components/main/Main';
import LoginForm from './components/login/login-form/LoginForm';
// import { Main } from './components/main/Main';
// import LoginForm from './components/login/login-form/LoginForm';
// import { IUserInfo } from './types/main';

const App = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState({
    username: '',
    id: 0,
  });
  const handleLogin = (userInfo: IUserInfo) => {
    console.log(userInfo);
    // Implement the login logic and set isLoggedIn to true if successful
    // ...
    setUserInfo(userInfo)
    // For example, you can use a fake authentication for testing:
    setLoggedIn(true);
  };

  useEffect(() => {
    // Access the body and modify styles
    document.body.style.backgroundColor = "black";
    document.body.style.color = "red";

    // Clean up styles when the component is unmounted
    return () => {
      document.body.style.backgroundColor = '';
      document.body.style.color = '';
    };
  }, []);
  
  return (
    <div className="App">
      <div>
      {isLoggedIn ? (
      <Main userInfo={userInfo} />
      ) : (
        <LoginForm onLogin={handleLogin} />
      )}
    </div>
    </div>
  );
}



export default App;








// import { Component, LOCALE_ID  } from '@angular/core';
// import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
// import { Injectable, Inject } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { Observable } from 'rxjs';
// import { catchError, last, map, tap, finalize } from 'rxjs/operators';
// import { AppService } from './app.service';

// // export enum modesForCenter {invoice,money,wod,buttonsAtTop}

// // class Passenger {
// //   constructor(public Title: string) { };
// // }
// // class ValueAndText {
// //   constructor(public Value: string, public Text: string) { }
// // }

// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html'
// })

// export class AppComponent {
//   name = 'Angular 4';
//   date:any;
//   iframeSrc = ``;


//   WOD = '';
//   mathProblem = ''
  
//   // modesForCenter=this.appService.iframeSrcArray[0].location;
//   centerMode=this.appService.iframeSrcArray[0].title;//.location
//   usingIframe = false;
  


//   totalForNic = 0;
//   totalForWil = 0;
//   totalDif = '';

  
//   newArrayOfObjects=[];
//   resultByYearMonth = [];
//   totalAndSortedExpenses = [];

  
//   // selectedCenterInfo=this.appService.iframeSrcArray[0].title;//'money';

//     // https://embed.windy.com/embed2.html?
//     // lat=39.992&
//     // lon=-75.440&
//     // detailLat=40.182&
//     // detailLon=-75.148&
//     // width=650&
//     // height=450&
//     // zoom=9&
//     // level=surface&
//     // overlay=wind&
//     // product=ecmwf&
//     // menu=&
//     // message=true&
//     // marker=&
//     // calendar=now&
//     // pressure=&
//     // type=map&
//     // location=coordinates&
//     // detail=true&
//     // metricWind=default&
//     // metricTemp=default&
//     // radarRange=-1

//   // 'https://embed.windy.com/embed2.html?lat=40.920&lon=-77.473&detailLat=40.847&detailLon=-76.889&width=650&height=450&zoom=7&level=surface&overlay=rain&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1'];
                   
  
//   // 'https://embed.windy.com/embed2.html?lat=39.992&lon=-75.440&detailLat=40.182&detailLon=-75.148&width=650&height=450&zoom=9&level=surface&overlay=wind&product=ecmwf&menu=&message=true&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=default&metricTemp=default&radarRange=-1'];
// //                   https://embed.windy.com/embed2.html?lat=40.182&lon=-75.148&detailLat=40.182&detailLon=-75.148&width=650&height=450&zoom=5&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1
// // "https://embed.windy.com/embed2.html?lat=40.920&lon=-77.473&detailLat=40.847&detailLon=-76.889&width=650&height=450&zoom=7&level=surface&overlay=rain&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1" 
//   currentFrame = 0;
//   countDownTimeTakes = 100;
//   countDownVar = this.countDownTimeTakes;
//   countDown2TimeTakes = this.countDownTimeTakes * 10;
//   countDown2Var = this.countDown2TimeTakes;

//   urlSafe: SafeResourceUrl;

//   // dogFedCount=0;
//   // dogsWateredCount = 0;
//   // dogsMedsCount = 0;
//   // domFedCount = 0;
//   // dogLetOutCount = 0;

//   refreshForcer = true;

//   elem:any;

//   arrayOfTitles = [];

//   isFullScreenMode = false;
//   isOpenLogoMode = false;
//   isCenterFrozen = false;
//   currentLocation = '';
//   currentLocationId = 0;
//   iframeSrcArrayCurrentLocation: any;
//   arrayOfEvents = [];
//   dateString = '';

//   isShowInfo = true;

  
//   showAll=false;
//   paidWhat="";
//   paidAmount="";

//   constructor(
//     public sanitizer: DomSanitizer,
//     @Inject('API_URL') private apiUrl: string,
//     private http: HttpClient,
//     private appService: AppService) {
//     // this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeSrcArray[this.currentFrame]);
//     this.urlSafe = this.appService.iframeSrcArray[this.currentFrame];
//     this.currentFrame++;

//     for (let index = 0; index < this.appService.iframeSrcArray.length; index++) {
//       //this.arrayOfTitles.find(x => x.location === this.appService.iframeSrcArray[index].location)
//       if (this.arrayOfTitles.indexOf(this.appService.iframeSrcArray[index].title) == -1) {
//         this.arrayOfTitles.push(this.appService.iframeSrcArray[index].title);
//       }
//     }

//     this.getNewWOD();
//     this.getNewMathProblem();
//     this.loadMoney()

//     // this.currentLocationId = this.arrayOfTitles.length-1;
//     this.changeOptionForCenter();
//     // this.changeLocation();

//     this.elem = document.documentElement;
//     this.getUpdatedNumbers();

//     // Change the time on the screen every second
//     setInterval(() => {
//       if ( this.countDownVar > 0 ) {
//         this.countDownVar--;
//         this.countDown2Var--;
//       }
//       const currentDate = new Date();
//       this.date = currentDate.toLocaleTimeString().replace(' PM','').replace(' AM','');
//       let longMonth = currentDate.toLocaleString('en-us', { month: 'short' }); /* June */
//       let dateStringArray = currentDate.toString().split(' ');
//       this.dateString = `${dateStringArray[0]}, ${longMonth} ${dateStringArray[2]}`;
//     }, 1000);

//     // First timer
//     setInterval(() =>{
//       this.countDownVar = this.countDownTimeTakes;
//       if(!this.isOpenLogoMode && !this.isCenterFrozen && this.usingIframe) {
//         this.gotoNextView();
//         this.getUpdatedNumbers();
//       }
//     }, this.countDownTimeTakes * 1000);

//     // Second timer
//     setInterval(() =>{
//       if(!this.isOpenLogoMode) {
//         // location.reload();
//         // reload page
//         this.refreshForcer = !this.refreshForcer;
//         this.countDown2Var = this.countDown2TimeTakes;
//       }
//     }, this.countDown2TimeTakes * 1000);
//   }  

//   private gotoNextView() {
//     if (this.currentFrame == this.iframeSrcArrayCurrentLocation.length) {
//       this.currentFrame = 0;
//     }
//     this.urlSafe = this.iframeSrcArrayCurrentLocation[this.currentFrame];
//     this.currentFrame++;
//   }

//   ngOnInit() {
//     this.openFullscreen();
//     this.selectedColor = 'purple';
//   }

//   cloneWOD = [];
//   cloneMathProblem = [];





//   openLogoMode() {
//     this.isOpenLogoMode = true;
//   }
//   closeLogoMode() {
//     this.isOpenLogoMode = false;
//   }

//   resetNumbers() {
//     // const url = `${this.apiUrl}/posts`;
//     // return this.http.delete(url)
//     // .pipe(
//     //   map((response: any) => {
//     //     this.getUpdatedNumbers();
//     //   }),
//     // );

//     for (let index = 0; index < this.arrayOfEvents.length; index++) {
//       this.deleteById(this.arrayOfEvents[index].id);
//       if (index == this.arrayOfEvents.length - 1) {
//         this.getUpdatedNumbers();
//       }
//     }
//   } 

//   private deleteById(id: number) {
//     this.appService.delete<any>(`posts`, id)
//       .subscribe((res: any) => {
//         // console.log('delete return', res)
//         this.getUpdatedNumbers();
//       });
//   }

//   private changeIframe() {
//     console.log('change to this',this.centerMode);
//     this.usingIframe=true;
//     // console.log('id', id);
//     this.iframeSrcArrayCurrentLocation = this.appService.iframeSrcArray.filter(x => x.title === this.centerMode);
//     this.refreshForcer = !this.refreshForcer;
//   }

//   changeCenter() {
//     this.gotoNextView();
//   }

//   freezeCenter() {
//     this.isCenterFrozen = !this.isCenterFrozen;
//   }

  

//   selectedColor = '';
//   currentSelectedColor = 0;
//   colorOptions = ['teal', 'red', 'blue', 'purple'];

//   dominicIsGreat() {
//     //change colors of background


//     // [ngClass]="{'color-teal': selectedColor=='teal', 'color-red': 
//     // selectedColor=='red', 'color-blue': selectedColor=='blue', 
//     // 'color-purple': selectedColor=='purple'}"

    
//     if (this.currentSelectedColor < this.colorOptions.length)
//       this.currentSelectedColor++;
//     else 
//       this.currentSelectedColor = 0;

//     this.selectedColor = this.colorOptions[this.currentSelectedColor];


//   }

//   showInfo() {
//     this.isShowInfo = !this.isShowInfo;
//   }

//   /* When the openFullscreen() function is executed, open the video in fullscreen.
//   Note that we must include prefixes for different browsers, as they don't support the requestFullscreen method yet */
//   openFullscreen() {
//     if (this.elem.requestFullscreen) {
//       this.elem.requestFullscreen();
//     } else if (this.elem.webkitRequestFullscreen) { /* Safari */
//       this.elem.webkitRequestFullscreen();
//     } else if (this.elem.msRequestFullscreen) { /* IE11 */
//       this.elem.msRequestFullscreen();
//     }
    
//     this.isFullScreenMode = true;
//   }

//   closeFullscreen() {
//     document.exitFullscreen();
//     this.isFullScreenMode = false;
//   }
  
//   allCounters = [];

  


  
//   showAllClicked() {
//     this.showAll=!this.showAll;
//   }

//   // getInvoices() {
//   //   this.centerMode=modesForCenter.invoice;
//   //   this.usingIframe=false;
//   // }

//   addInvoice(thisForm){
//     console.log('whatPressed', thisForm);
//     console.log('whatPressed.value', thisForm.value);
//     let values = thisForm.value;
//     thisForm.reset();
//     const url = `posts`;
        
//     // let info = { 
//     //   post:{
//     //     status: 'published',
//     //     title: whatPressed,
//     //     html: whatPressed,
//     //     tags: [whatPressed,'money'],
//     //     featured: true,
//     //   }
//     // };

//     let who=values.person;
//     let what=values.what;
//     let howMuch=Number(values.amount.split(',').join('')).toFixed(2).toString();
//     let option=values.option;
//     console.log('howMuch', howMuch);

//     let info = { 
//       post:{
//         status: 'published',
//         title: `${who}-${what}-${option}-${howMuch}`,//who+howMuch,
//         html: `{"who":"${who}","what":"${what}","option":"${option}","value":"${howMuch}"}`,
//         tags: [who,'money'],
//         featured: true,
//       }
//     };

//     this.appService.addObject<any>(url, info)
//       .subscribe((res: any) => {
//         console.log('return from addMoney'+res);
//         this.loadMoney();
//       });
//   }

//   loadInvoice() {
//     // const url = `posts/var`;
        
//     // //money
//     // let info = { 
//     //   var: 'buttons' 
//     // };

//     this.appService.getTagFromCMS<any>('money')
//       .subscribe((res: any) => {
//         console.log('getMoney res.posts',res.posts);
//         const ps = res.posts;
//         this.newArrayOfObjects=[];
//         for (let i = 0; i < ps.length; i++) {
//           //JSON.parse(JSON.parse(JSON.stringify()))
//           //ps[i].published_at
//           let thisValue={...JSON.parse(ps[i].html.split('<p>').join('').split('</p>').join('')),id:ps[i].id,published_at:ps[i].published_at};
//           this.newArrayOfObjects.push(thisValue);
//         }
//         console.log('newArrayOfObjects',this.newArrayOfObjects);
//         const resultForWil = this.newArrayOfObjects.filter(person => person.who === 'wil');
//         const resultForNic = this.newArrayOfObjects.filter(person => person.who === 'nic');
//         const resultForWilHalf = resultForWil.filter(person => person.option === 'half');
//         const resultForNicHalf = resultForNic.filter(person => person.option === 'half');
//         const resultForWilAll = resultForWil.filter(person => person.option === 'all');
//         const resultForNicAll = resultForNic.filter(person => person.option === 'all');
        
//         this.totalForNic=0;
//         this.totalForWil=0;
//         for  (let i = 0; i < resultForNicHalf.length; i++) {
//           this.totalForNic += (Number(resultForNicHalf[i].value)/2);
//         }
//         for  (let i = 0; i < resultForNicAll.length; i++) {
//           this.totalForNic += Number(resultForNicAll[i].value);
//         }
//         for  (let i = 0; i < resultForWilHalf.length; i++) {
//           this.totalForWil += (Number(resultForWilHalf[i].value)/2);
//         }
//         for  (let i = 0; i < resultForWilAll.length; i++) {
//           this.totalForWil += Number(resultForWilAll[i].value);
//         }
//         if (this.totalForWil>this.totalForNic) {
//           this.totalDif=`Nic Owes $${(this.totalForWil-this.totalForNic).toFixed(2).toString()}`;
//         } else {
//           this.totalDif=`Will Owes $${(this.totalForNic-this.totalForWil).toFixed(2).toString()}`;
//         }
//       });
//   }

//   changeOptionForCenter() {
//     console.log('selectedCenterInfo',this.centerMode);
//     // console.log('changeOptionForCenter',value);

//     // <option (click)="getMoney()" value="{{i}}">Money</option>
//     //                     <option (click)="getInvoices()" value="{{i}}">Invoices</option>
//     // if (this.selectedCenterInfo.type=2) {
    
//       // this.currentLocation = this.centerMode;//this.arrayOfTitles[id];
//       switch (this.centerMode) {
//         case 'Nicole':
//         case 'Invoice':
//         case 'Kid Words':
//           this.usingIframe=false;
//           break;
//         case 'Money':
//           this.loadMoney();
//           this.usingIframe=false;
//           break;
//         default:
//           this.changeIframe();
//           this.usingIframe=true;
//           break;
//       }
//     // } else {

//     // }
//   }

  
//   selectedCenterChange() {
//     console.log('selectedCenterChange');
//   }
  
// }

