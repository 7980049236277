import { ChangeEvent, useEffect, useState } from 'react';
import React from 'react';
import './notes.scss';
import { IDataReturn, IDataForAPI, IPostData, fetchPostsByTags, handleAdd, handleDelete, handleUpdate } from '../../radApi';
import { v4 as uuidv4 } from 'uuid';
import { IChildPanelInfoProps, IErrorInfo } from '../../../types/main';

interface IOption {
  id: number;
  option: string;
}

export interface INotes {
  postId?: number;
  id: number | undefined;
  title: string;
  note: string;
  created?: string;
  updated?: string;
  completed?: boolean;
}

const defaultFormData: INotes = {
  id: undefined,
  title: "",
  note: "",
}

export const Notes: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  const dataForAPI: IDataForAPI = {
    userId: childPanelInfoState.userInfo.id,
    key: childPanelInfoState.userInfo.key,
    tagIds: [5],
    status: 1,
    encryptData:childPanelInfoState.mainPanelInfo.system.encryptData
  }
  const [allPosts, setAllPosts] = useState<IPostData[]>([]);
  const [editModeOn, setEditModeOn] = useState<boolean>(false);
  const [editId, setEditId] = useState<number>(0);
  const [formData, setFormData] = useState<INotes | undefined>(undefined);
  const [tableData, setTableData] = useState<INotes[]>([]);
  const [sortedTableData, setSortedTableData] = useState<INotes[]>([]);

  const onError = (friendly: string, e: any) => {
    const errorInfo: IErrorInfo = {
      error: friendly,
      e: e,
    }
    if (childPanelInfoState.errorInfo) {
      const newErrorArray = [...childPanelInfoState.errorInfo, errorInfo];
      setChildPanelInfoState((prevObject) => ({
        ...prevObject,
        errorInfo: newErrorArray,
      }));
    }
  };

  const onAdd = (title: string, newEntry: any) => {
    const dataForAPIUpdated = {
      ...dataForAPI,
      title: title,
      formData: newEntry
    };
    handleAdd(dataForAPIUpdated).then((postData: IDataReturn) => {
      if (postData.success) {
        onGetData();
        setFormData(undefined);
      } else {
        onError('Error Adding', { errors: postData.errors, requestOptions: postData.requestOptions, variables: postData.variables })
      }
    })
      .catch((error) => {
        onError('Error Adding', error);
      });
  };

  const onUpdate = (index: number) => {
    if (formData !== undefined) {
      const updatedForm = {
        ...formData,
        title: formData.title,
        updated: new Date().toLocaleString()
      };
      const dataForAPIUpdated = { ...dataForAPI, postId: updatedForm.postId, title: updatedForm.title, formData: updatedForm };
      console.log('dataForAPIUpdated', dataForAPIUpdated);
      handleUpdate(dataForAPIUpdated).then((postData: IDataReturn) => {
        if (postData.success) {
          onGetData();
          setFormData(undefined);
        } else {
          onError('Error Updating', { errors: postData.errors, requestOptions: postData.requestOptions, variables: postData.variables })
        }
      })
        .catch((error) => {
          onError('Error Updating', error);
        });
    }
  };

  const onDelete = (index: number) => {
    const dataForAPIUpdated = { ...dataForAPI, postId: allPosts[index].id };
    handleDelete(dataForAPIUpdated).then((postData: IDataReturn) => {
      console.log('delete', postData);
      if (postData.success) {
        onGetData();
      } else {
        onError('Error Deleting', { errors: postData.errors, requestOptions: postData.requestOptions, variables: postData.variables })
      }
    })
      .catch((error) => {
        onError('Error Deleting', error);
      });
  };

  const onGetData = () => {
    const dataForAPIUpdated = { ...dataForAPI };
    fetchPostsByTags(dataForAPIUpdated).then((postData: IDataReturn) => {
      if (postData.success) {
        const posts = postData.posts as IPostData[];
        setAllPosts(posts);
        const data = postData.data as INotes[];
        setTableData(data);
      } else {
        onError('Error Updating', { errors: postData.errors, requestOptions: postData.requestOptions, variables: postData.variables })
      }
    })
      .catch((error) => {
        onError('Error Updating', error);
      });
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { title, note } = formData as INotes;
    const updatedForm = {
      ...formData,
      id: uuidv4(),
      created: new Date().toLocaleString(),
      updated: "",
    };
    onAdd(`${title}`, updatedForm);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (formData !== undefined) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    if (formData !== undefined) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  }

  const onEdit = (id: number) => {
    setFormData(tableData[id]);
    setEditModeOn(true);
    setEditId(id);
  };

  const createNew = () => {
    // formData
    setFormData(defaultFormData as INotes);
  }

  const close = () => {
    setFormData(undefined);
  }

  useEffect(() => {
    onGetData();
  }, []);

  return <div className="notes-form">
    
    <div className='spacer-10' />
    <button title="Refresh" onClick={onGetData}>Refresh</button>
    <div className='spacer-10' />
    <table border={1}>
      <tbody>
        {tableData.map((rowData, index) => (
          <tr key={index}>
            <td className='column-button'>
              <button title="Edit" onClick={() => onEdit(index as number)}>Edit</button>
            </td>
            <td className="column-center">
              {index + 1}
            </td>
            <td>{rowData.title}</td>
            <td>{rowData.created}</td>
            <td>{rowData.updated}</td>
            <td className='column-button'>
              <button style={{ backgroundColor: childPanelInfoState.mainPanelInfo.styles.dangerButtonColor }} className='dangerButtonColor' title="Delete" onClick={() => onDelete(index as number)}>Delete</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>

    <div className='spacer-10' />
    <button title="New" onClick={createNew}>New</button>
    {formData !== undefined && <button title="Close" onClick={close} className='right' style={{ backgroundColor: childPanelInfoState.mainPanelInfo.styles.dangerButtonColor }}>New</button>}
    {formData !== undefined && <div>
      <div className='spacer-10' />
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
        />
        <div className="spacer-10" />
        <textarea
          name="note"
          value={formData.note}
          onChange={handleTextAreaChange}
          className='note'
        />
        <div className='spacer-10' />
        {(editModeOn) ?
          <button type="button" title="Edit" onClick={() => onUpdate(editId)}>Edit</button>
          :
          <button type="submit" title="Add">Add</button>
        }
      </form>
    </div>
    }
  </div>;
};