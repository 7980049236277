import React from 'react';
import { IChildPanelInfoProps } from "../../../types/main";
import { RightAngleTriangle } from "../../main/Main";
import './panel-footer.scss';

export const PanelFooter: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  return <div>
    <div className="column">
      <div >
        {childPanelInfoState.mainPanelInfo.browser.isWebSite ?
          <div className="height-100">
            <span style={{
              width: `${childPanelInfoState.mainPanelInfo.left.width - 0}px`,
              // backgroundColor: "gray"
            }}>
              <RightAngleTriangle width={childPanelInfoState.mainPanelInfo.left.width - 0}
                height={125} stroke={childPanelInfoState.mainPanelInfo.styles.borderColor} fill="none" rotation="180" strokeWidth={2}
                padding={4} cornerRadius={15} text="This is my text" />
            </span>


            <div className="overflow-auto section-border animate" style={{
              width: `${childPanelInfoState.mainPanelInfo.center.width - 20}px`,
              height: '124px',
              margin: '0 0 3px 0',
              borderColor: childPanelInfoState.mainPanelInfo.styles.borderColor,
              // width: `${childPanelInfoState.mainPanelInfo.center.width - 35}px`, //31
              // backgroundColor: "gray"
            }} >

              <div style={{ padding: "10px" }}>
                <br />
                Width:{childPanelInfoState.mainPanelInfo.center.width}
              </div>
            </div>

            <span style={{
              width: `${childPanelInfoState.mainPanelInfo.right.width - 0}px`,
              // backgroundColor: "gray"
            }}>
              <RightAngleTriangle width={childPanelInfoState.mainPanelInfo.right.width - 0} height={125} stroke={childPanelInfoState.mainPanelInfo.styles.borderColor}
                fill='none'
                rotation="none" strokeWidth={2}
                padding={4} cornerRadius={15} />
            </span>
          </div>
          :
          <div className="height-100">
            <div className="overflow-auto section-border animate" style={{
              width: `${childPanelInfoState.mainPanelInfo.center.width - 4}px`,
              borderColor: childPanelInfoState.mainPanelInfo.styles.borderColor,
              backgroundColor: childPanelInfoState.mainPanelInfo.styles.borderColor,
              color: '#000',
              // width: `${childPanelInfoState.mainPanelInfo.center.width - 35}px`, //31
              // backgroundColor: "gray"
            }} >

              <div style={{ padding: "10px" }}>
                Width:{childPanelInfoState.mainPanelInfo.center.width}
              </div>
            </div>



            <div style={{ margin: '-5px 0 0 0' }}>
              <span style={{
                width: `${childPanelInfoState.mainPanelInfo.left.width * .25}px`,
                // backgroundColor: "gray"
              }}>
                <RightAngleTriangle width={childPanelInfoState.mainPanelInfo.left.width * .25}
                  height={60} stroke={childPanelInfoState.mainPanelInfo.styles.borderColor}
                  fill={childPanelInfoState.mainPanelInfo.styles.borderColor}
                  rotation="180" strokeWidth={2}
                  padding={4} cornerRadius={15} text="This is my text" />
              </span>
              <div className="overflow-auto section-border animate" style={{
                width: `${(childPanelInfoState.mainPanelInfo.center.width) * .50 - 20}px`,
                height: '59px',
                margin: '0 0 3px 0',
                borderColor: childPanelInfoState.mainPanelInfo.styles.borderColor,
                backgroundColor: childPanelInfoState.mainPanelInfo.styles.borderColor
                // width: `${childPanelInfoState.mainPanelInfo.center.width - 35}px`, //31
                // backgroundColor: "gray"
              }} >

                <div style={{ padding: "10px" }}>
                  {/* Width:{childPanelInfoState.mainPanelInfo.center.width} */}
                </div>
              </div>
              <span style={{
                width: `${childPanelInfoState.mainPanelInfo.right.width * .25}px`
              }}>
                <RightAngleTriangle width={childPanelInfoState.mainPanelInfo.right.width * .25} height={60}
                  stroke={childPanelInfoState.mainPanelInfo.styles.borderColor}
                  fill={childPanelInfoState.mainPanelInfo.styles.borderColor} rotation="none" strokeWidth={2}
                  padding={4} cornerRadius={15} />
              </span>
            </div>




          </div>}

      </div>
    </div>


  </div>
}

{/* <RightAngleTriangle width={childPanelInfoState.mainPanelInfo.left.width} height={125} stroke={childPanelInfoState.mainPanelInfo.styles.borderColor} fill="none" rotation="180" strokeWidth={2}
      padding={4} cornerRadius={5} />
    <div className="overflow-auto section-border animate"
      style={{
        width: `${childPanelInfoState.mainPanelInfo.center.width - 28}px`,
        borderColor: childPanelInfoState.mainPanelInfo.styles.borderColor,
        // width: `${childPanelInfoState.mainPanelInfo.center.width - 35}px`, //31
        // backgroundColor: "gray"
      }}>
    </div>
    <RightAngleTriangle width={childPanelInfoState.mainPanelInfo.right.width} height={125} stroke={childPanelInfoState.mainPanelInfo.styles.borderColor} fill="none" rotation="none" strokeWidth={2}
      padding={4} cornerRadius={5} /> */}


{/* <div dangerouslySetInnerHTML={{ __html: sanitizeHTMLString }} /> */ }