import React from 'react';
import { IMoney } from '../../../types/money';
import { processMoneyList } from '../../helpers/moneyHelpers';

interface IResultsByItemProps {
  moneyList: IMoney[];
  onItemSelected : (itemSelected:string,averageSpent:number) => void;
}

export const ResultsByItem: React.FC<IResultsByItemProps> = ({ moneyList, onItemSelected }) => {

  const { sortedData, countTotalByItem, countOfOccurrences, averageForItem } = processMoneyList(moneyList);
  return (
    <div>
      <h2>Results By Item</h2>
      <table>
        <thead>
          <tr>
            <th>Item</th>
            <th>Total Amount</th>
            <th>Occurrences</th>
            <th>Average Amount</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(sortedData).map((item, index) => (
            <tr key={item}>
              <td onClick={()=>onItemSelected(item,averageForItem[item])}>{item}</td>
              <td className='highlight-section'>${countTotalByItem[item].toLocaleString()}</td>
              <td>{countOfOccurrences[item]}</td>
              <td>${averageForItem[item].toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
