import React, { useEffect, useState } from 'react';
import { IChildPanelInfoProps } from '../../../types/main';
import GuitarTuner from './GuitarTuner';
import Freq from './Freq';
import Visualizer from './Visualizer';

const Tuner: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
//   const [note, setNote] = useState<string>('');
//   const [frequency, setFrequency] = useState<number | null>(null);

//   useEffect(() => {
//     const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
//     const analyser = audioContext.createAnalyser();
//     analyser.fftSize = 2048;

//     navigator.mediaDevices.getUserMedia({ audio: true })
//       .then((stream) => {
//         const source = audioContext.createMediaStreamSource(stream);
//         source.connect(analyser);

//         const bufferLength = analyser.fftSize;
//         const dataArray = new Float32Array(bufferLength);

//         const detectPitch = () => {
//           analyser.getFloatTimeDomainData(dataArray);
//           const pitch = autoCorrelate(dataArray, audioContext.sampleRate);

//           if (pitch !== -1) {
//             setFrequency(pitch);
//             const closestNote = getClosestNote(pitch);
//             setNote(closestNote);
//           }

//           requestAnimationFrame(detectPitch);
//         };

//         detectPitch();
//       });
//   }, []);

//   const autoCorrelate = (buffer: Float32Array, sampleRate: number): number => {
//     let SIZE = buffer.length;
//     let MAX_SAMPLES = Math.floor(SIZE / 2);
//     let bestOffset = -1;
//     let bestCorrelation = 0;
//     let rms = 0;

//     for (let i = 0; i < SIZE; i++) {
//       rms += buffer[i] * buffer[i];
//     }
//     rms = Math.sqrt(rms / SIZE);
//     if (rms < 0.01) return -1;

//     let lastCorrelation = 1;
//     for (let offset = 0; offset < MAX_SAMPLES; offset++) {
//       let correlation = 0;

//       for (let i = 0; i < MAX_SAMPLES; i++) {
//         correlation += Math.abs(buffer[i] - buffer[i + offset]);
//       }
//       correlation = 1 - (correlation / MAX_SAMPLES);
//       if (correlation > 0.9 && correlation > lastCorrelation) {
//         bestCorrelation = correlation;
//         bestOffset = offset;
//       }
//       lastCorrelation = correlation;
//     }

//     if (bestCorrelation > 0.01) {
//       return sampleRate / bestOffset;
//     }
//     return -1;
//   };

//   const getClosestNote = (frequency: number): string => {
//     const notes = [
//       { note: 'E', frequency: 82.41 },
//       { note: 'A', frequency: 110.00 },
//       { note: 'D', frequency: 146.83 },
//       { note: 'G', frequency: 196.00 },
//       { note: 'B', frequency: 246.94 },
//       { note: 'E', frequency: 329.63 }
//     ];

//     let closest = notes[0];
//     notes.forEach(n => {
//       if (Math.abs(n.frequency - frequency) < Math.abs(closest.frequency - frequency)) {
//         closest = n;
//       }
//     });

//     return closest.note;
//   };

  return (
    <div>
      {/* <h1>Guitar Tuner</h1>
      <p>Note: {note}</p>
      <p>Frequency: {frequency?.toFixed(2)} Hz</p> */}
      <GuitarTuner childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState}/>
      <Freq childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState}/>
      {/* <Visualizer /> */}
    </div>
  );
};

export default Tuner;
