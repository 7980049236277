import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { PanelCenter } from '../panels/panel-center/PanelCenter';
import { PanelLeft } from '../panels/panel-left/PanelLeft';
import { PanelRight } from '../panels/panel-right/PanelRight';
import { PanelTop } from '../panels/panel-top/PanelTop';
import './main.scss';
import * as d3 from 'd3';
import { PanelFooter } from '../panels/panel-footer/PanelFooter';
import { IChildPanelInfo, IErrorInfo, IMainPanelInfo, IMainProps, ITag, MySVGComponentProps } from '../../types/main';
import { StageType } from '../../enums/main';



// export enum DataPoints {
//   Invoice = "invoice",
//   Temp = "temp",
//   Todo = "todo",
//   Money = "money",
//   Notes = "notes",
//   SongInfo = "songInfo",
// }


// export const RightAngleTriangle: React.FC<MySVGComponentProps> = ({
//   width = 50,
//   height = 50,
//   stroke = '#000000',
//   fill = '#000000',
//   strokeWidth = 1,
//   rotation = 'none',
//   padding = 0,
//   cornerRadius = 0,
//   innerComponent,
// }) => {
//   const points = () => {
//     switch (rotation) {
//       case '90':
//         return `${width},${height} 0,${height} 0,0`;
//       case '180':
//         return `${width},${height} ${width},0 0,${height}`;
//       case '270':
//         return `0,${height} ${width},0 ${width},${height}`;
//       default: // 'none' or any other value
//         return `0,0 ${width},0 0,${height}`;
//     }
//   };

//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox={`0 0 ${width} ${height}`}
//       width={width}
//       height={height}
//     >
//       <polygon
//         points={points()}
//         fill={fill}
//         stroke={stroke}
//         strokeWidth={strokeWidth}
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         rx={cornerRadius}
//         ry={cornerRadius}
//       />
//       {innerComponent && (
//         <foreignObject width={width} height={height}>
//           {innerComponent}
//         </foreignObject>
//       )}
//     </svg>
//   );
// };

export const RightAngleTriangle: React.FC<MySVGComponentProps> = ({
  width = 50,
  height = 50,
  stroke = '#000000',
  fill = '#000000',
  strokeWidth = 1,
  rotation = 'none',
  padding = 0,
  cornerRadius = 10,
  innerComponent,
}) => {
  const points = () => {
    switch (rotation) {
      case '90':
        return `${width + padding},${height + padding} ${padding},${height + padding} ${padding},${padding}`;
      case '180':
        return `${width + padding},${height + padding} ${width + padding},${padding} ${padding},${padding}`;
      case '270':
        return `${padding},${height + padding} ${width + padding},${padding} ${width + padding},${height + padding}`;
      default: // 'none' or any other value
        return `${padding},${padding} ${width + padding},${padding} ${padding},${height + padding}`;
    }
  };
  // const points = () => {
  //   switch (rotation) {
  //     case '90':
  //       return `${width + padding},${height + padding} ${padding},${height + padding} ${padding},0`;
  //     case '180':
  //       return `${width + padding},${height + padding} ${width + padding},${padding} ${padding},${height + padding}`;
  //     case '270':
  //       return `${padding},${height + padding} ${width + padding},0 ${width + padding},${height + padding}`;
  //     default: // 'none' or any other value
  //       return `${padding},${height + padding} ${width + padding},${height + padding} ${padding},0`;
  //   }
  // };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width + padding * 2} ${height + padding * 2}`}
      width={width + padding * 2}
      height={height + padding * 2}
    >
      <polygon
        points={points()}
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        rx={cornerRadius}
        ry={cornerRadius}
      />
      {innerComponent && (
        <foreignObject width={width} height={height} x={padding} y={padding}>
          {innerComponent}
        </foreignObject>
      )}
    </svg>
  );
};

// export const RightAngleTriangle: React.FC<MySVGComponentProps> = ({
//   width = 50,
//   height = 50,
//   stroke = '#000000',
//   fill = '#000000',
//   strokeWidth = 1,
//   rotation = 'none',
//   padding = 0,
//   cornerRadius = 0,
//   innerComponent,
// }) => {
//   const points = () => {
//     switch (rotation) {
//       case '90':
//         return `${width + padding},${height + padding} ${padding},0 ${padding},${height + padding}`;
//       case '180':
//         return `${width + padding},${height + padding} 0,${padding} ${width + padding},${padding}`;
//       case '270':
//         return `0,${height + padding} ${width + padding},${height + padding} ${width + padding},${padding}`;
//       default:
//         return `0,0 ${width + padding},0 0,${height + padding}`;
//     }
//   };

//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox={`0 0 ${width + padding} ${height + padding}`}
//       width={width}
//       height={height}
//     >
//       <polygon
//         points={points()}
//         fill={fill}
//         stroke={stroke}
//         strokeWidth={strokeWidth}
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       // rx={cornerRadius}
//       // ry={cornerRadius}
//       />
//       {innerComponent && (
//         <foreignObject width={width} height={height} x={padding} y={padding}>
//           {innerComponent}
//         </foreignObject>
//       )}
//     </svg>
//   );
// };

export const Main: React.FC<IMainProps> = ({ userInfo }) => {
  // console.log('main userInfo',userInfo);
  const defaultPanelInfo: IMainPanelInfo = {
    system: {
      encryptData: true,
    },
    browser: {
      width: 0,
      height: 0,
      isWebSite: true,
    },
    styles: {


      // {
      //   "fontColor": "#ff0000",
      //   "backgroundColor": "#000000",
      //   "borderColor": "#ff0000"
      // },

      // {
      //   "fontColor": "#94b6ff",
      //   "backgroundColor": "#1e2236",
      //   "borderColor": "#96c3ff"
      // }

      dangerButtonColor: 'red',
      normalButtonColor: 'white',

      backgroundColor: '#1e2236',
      borderColor: '#94b6ff',
      fontColor: '#94b6ff',

      // backgroundColor: 'black',
      // dangerButtonColor: 'red',
      // normalButtonColor: 'white',
      // borderColor: 'red',
      // fontColor: 'red',

      // backgroundColor: '',
      // dangerButtonColor: '',
      // normalButtonColor: '',
      // borderColor: '',
      // fontColor: '',
    },
    header: {
      jsonMessage: undefined,
      message: undefined,
      width: 0,
      height: 0,
      whoOwes: '',
      owesHowMuch: ''
    },
    left: {
      stage: StageType.Default,
      width: 0,
      height: 0,
    },
    center: {
      stage: StageType.Default,
      width: 0,
      height: 0,
    },
    right: {
      stage: StageType.Default,
      width: 0,
      height: 0,
    },
    footer: {
      message: undefined,
      width: 0,
      height: 0,
    }
  }
  const defaultErrorInfo: IErrorInfo = {
    error: "",
  }

  const [childPanelInfoState, setChildPanelInfoState] = useState<IChildPanelInfo>({ userInfo: userInfo, mainPanelInfo: defaultPanelInfo });
  const [borderColor, setBorderColor] = useState<string>('red');

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (childPanelInfoState.errorInfo && childPanelInfoState.errorInfo.length > 0) {
      intervalId = setInterval(() => {
        // Change the border color randomly
        const newColor = getRandomColor();
        setBorderColor(newColor);
      }, 1000); // Change color every 1000 milliseconds (1 second)
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [childPanelInfoState.errorInfo]);

  // Handle Resize
  const handleResize = () => {

    const panelContainer = document.getElementById('panel-container') as HTMLElement;
    const panelMain = document.getElementById('main') as HTMLElement;
    let mainPanelInfo = { ...childPanelInfoState.mainPanelInfo };

    const windowInnerHeight = window.innerHeight;
    const windowInnerWidth = window.innerWidth;

    const { width: containerWidth } = panelContainer.getBoundingClientRect();
    const { height: maineHeight } = panelMain.getBoundingClientRect();

    if (windowInnerWidth > 1300) {
      mainPanelInfo = {
        ...mainPanelInfo,
        center: { ...mainPanelInfo.center, width: windowInnerWidth * .50 },
        left: { ...mainPanelInfo.left, width: windowInnerWidth * .25, height: maineHeight },
        right: { ...mainPanelInfo.right, width: windowInnerWidth * .25, height: maineHeight },
        browser: { ...mainPanelInfo.browser, width: windowInnerHeight, height: windowInnerWidth, isWebSite: true },
      }
    } else {
      const bufferWidth = 0;
      mainPanelInfo = {
        ...mainPanelInfo,
        center: { ...mainPanelInfo.center, width: windowInnerWidth + bufferWidth },
        left: { ...mainPanelInfo.left, width: windowInnerWidth + bufferWidth, height: maineHeight },
        right: { ...mainPanelInfo.right, width: windowInnerWidth + bufferWidth, height: maineHeight },
        browser: { ...mainPanelInfo.browser, width: windowInnerHeight, height: windowInnerWidth, isWebSite: false },
      }
    }
    setChildPanelInfoState((prevObject) => ({
      ...prevObject,
      mainPanelInfo: mainPanelInfo,
    }));
    
  }

  useEffect(() => {
    const infoTagList: ITag[] = [
      { name: 'Song Info' },
      { name: 'Notes' },
      { name: 'Money' },
      { name: 'Todo' },
      { name: 'Temp' },
      { name: 'Invoice' },
    ]
    infoTagList.reverse();
    let tagsFormatted: ITag[] = [];
    infoTagList.forEach((tag, index) => {
      const words = tag.name.split(' ');
      const formattedName = words[0].toLowerCase() + words.slice(1).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');
      let tagFormatted: ITag = {
        id: index + 1,
        name: tag.name,
        formattedName: formattedName
      }
      tagsFormatted.push(tagFormatted);
    });

    setChildPanelInfoState((prevObject) => ({
      ...prevObject,
      tagList: tagsFormatted,
    }));

    handleResize();
  }, []);

  useEffect(() => {
    //  handleResize();
    window.addEventListener('resize', handleResize);
    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [childPanelInfoState]);

  const getRandomColor = (): string => {
    const colors = ['red', 'darkred', 'firebrick', 'indianred'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  useEffect(() => {
    // Access the body and modify styles
    document.body.style.backgroundColor = childPanelInfoState.mainPanelInfo.styles.backgroundColor;
    document.body.style.color = childPanelInfoState.mainPanelInfo.styles.fontColor;
    // Clean up styles when the component is unmounted
    return () => {
      document.body.style.backgroundColor = '';
      document.body.style.color = '';
    };
  }, [childPanelInfoState]);

  // useEffect(() => {
  //   // // Fetch data immediately when the component mounts
  //   // fetchData();

  //   // Set up the interval to reload the entire app every 2 minutes (120000 milliseconds)
  //   const intervalId = setInterval(() => {
  //     window.location.reload();
  //   }, 120000);

  //   // Clean up the interval when the component unmounts
  //   return () => clearInterval(intervalId);
  // }, []);

  // const borderColorStyle = {
  //   borderColor: childPanelInfoState.mainPanelInfo.styles.borderColor,
  //   // };


  //   // const centerColorStyle = {
  //   //   borderColor: childPanelInfoState.mainPanelInfo.styles.borderColor,
  //   // width: `${childPanelInfoState.mainPanelInfo.center.width-35}px`, //31
  //   maxHeight: `${childPanelInfoState.mainPanelInfo.browser.height - 300}px`, //31
  // };

  return (
    // <div>
    //   <div className='spacer-10' />
    //   <div className="grid-container">
    //     <div className="header"><div className=""><PanelTop childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} /></div></div>
    //     <div className='three-column-layout'>
    //       <div id='left' className="left column column-30"><div className="section-border" style={borderColorStyle}><PanelLeft childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} /></div></div>
    //       <div id='main' className="main column column-40"><div className="section-border" style={borderColorStyle}><PanelCenter childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} /></div></div>
    //       <div id='right' className="right column column-30"><div className="section-border" style={borderColorStyle}><PanelRight childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} /></div></div>
    //     </div>
    //     <div className="footer">
    //       <PanelFooter childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
    //     </div>
    //   </div>
    // </div>
    <div id="panel-container" className="container">
      <div className="spacer-10" />
      <div className="grid-container">
        <div className="header">
          <PanelTop childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} handleResize={handleResize} />
        </div>

        <div className={childPanelInfoState.mainPanelInfo.browser.isWebSite ? 'three-column-layout' : ''}
          style={{ margin: childPanelInfoState.mainPanelInfo.browser.isWebSite ? '-6px 0 0 0' : '0' }}>
          <div id="left" className="column section-border" style={{
            borderColor: childPanelInfoState.mainPanelInfo.styles.borderColor,
            width: childPanelInfoState.mainPanelInfo.browser.isWebSite ?
              `${childPanelInfoState.mainPanelInfo.left.width + 6}px` :
              `${childPanelInfoState.mainPanelInfo.left.width}px`,
            margin: childPanelInfoState.mainPanelInfo.browser.isWebSite
              ? '0'
              : '-2px 0 0 0'
            // height: `${childPanelInfoState.mainPanelInfo.left.height - 0}px`,
          }}>
            <div style={{padding:"10px"}}>
              <PanelLeft childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
            </div>
          </div>
          {/* // .three-column-layout {
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-between;
  // } */}
          <div id="main" className="column section-border" style={{
            borderColor: childPanelInfoState.mainPanelInfo.styles.borderColor,
            // width: `${childPanelInfoState.mainPanelInfo.center.width - 14}px`,
            // margin: '0 0 0 2px'
            width: childPanelInfoState.mainPanelInfo.browser.isWebSite
              ? `${childPanelInfoState.mainPanelInfo.center.width - 14}px`
              : `${childPanelInfoState.mainPanelInfo.center.width}px`, // Example value for mobile, set as needed
            margin: childPanelInfoState.mainPanelInfo.browser.isWebSite
              ? '0 0 0 2px'
              : '4px 0 0 0'
          }}>
          <div style={{padding:"10px"}}>
            <PanelCenter childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
          </div>
          </div>
          <div id="right" className="column section-border" style={{
            borderColor: childPanelInfoState.mainPanelInfo.styles.borderColor,
            width: `${childPanelInfoState.mainPanelInfo.right.width - 0}px`,
            margin: childPanelInfoState.mainPanelInfo.browser.isWebSite ? '0 0 0 2px' : '4px 0 4px 0'
            // height: `${childPanelInfoState.mainPanelInfo.right.height - 0}px`,
          }}>
          <div style={{padding:"10px"}}>
            <PanelRight childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
          </div>
          </div>
        </div>
        <div className="footer">
          <PanelFooter childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
        </div>
      </div>
    </div>

  );
}



// export const RightAngleTriangle: React.FC<MySVGComponentProps> = ({
//   width = 50,
//   height = 50,
//   stroke = '#000000',
//   fill = '#000000',
//   strokeWidth = 1,
//   rotation = 'none',
//   padding = 8,
//   cornerRadius = 0,
// }) => {
//   const svgRef = useRef<SVGSVGElement>(null);

//   useEffect(() => {
//     const svg = d3.select(svgRef.current);

//     const points = () => {
//       switch (rotation) {
//         case '90':
//           return `${width + padding},${height + padding} ${padding},0 ${padding},${height + padding}`;
//         case '180':
//           return `${width + padding},${height + padding} 0,${padding} ${width + padding},${padding}`;
//         case '270':
//           return `0,${height + padding} ${width + padding},${height + padding} ${width + padding},${padding}`;
//         default:
//           return `0,0 ${width + padding},0 0,${height + padding}`;
//       }
//     };

//     // Draw the visible triangle with stroke and fill
//     svg
//       .append('polygon')
//       .attr('points', points())
//       .attr('fill', fill)
//       .attr('stroke', stroke)
//       .attr('stroke-width', strokeWidth)
//       .attr('stroke-linecap', 'round')
//       .attr('stroke-linejoin', 'round')
//       .attr('rx', cornerRadius)
//       .attr('ry', cornerRadius);
//   }, [width, height, stroke, fill, strokeWidth, rotation, padding, cornerRadius]);

//   return (
//     <svg
//       ref={svgRef}
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox={`0 0 ${width + padding} ${height + padding}`}
//       width={width}
//       height={height}
//     />
//   );
// };

// export const RightAngleTriangle: React.FC<MySVGComponentProps> = ({
//       width = 50,
//       height = 50,
//       stroke = '#000000',
//       fill = '#000000',
//       strokeWidth = 1,
//       rotation = 'none',
//       padding = 8,
//       cornerRadius = 0,
//       text = '',
//     }) => {
//       const svgRef = useRef<SVGSVGElement>(null);

//       useEffect(() => {
//         const svg = d3.select(svgRef.current);

//         const points = () => {
//           switch (rotation) {
//             case '90':
//               return `${width + padding},${height + padding} ${padding},0 ${padding},${height + padding}`;
//             case '180':
//               return `${width + padding},${height + padding} 0,${padding} ${width + padding},${padding}`;
//             case '270':
//               return `0,${height + padding} ${width + padding},${height + padding} ${width + padding},${padding}`;
//             default:
//               return `0,0 ${width + padding},0 0,${height + padding}`;
//           }
//         };

//         // Draw the visible triangle with stroke and fill
//         svg
//           .append('polygon')
//           .attr('points', points())
//           .attr('fill', fill)
//           .attr('stroke', stroke)
//           .attr('stroke-width', strokeWidth)
//           .attr('stroke-linecap', 'round')
//           .attr('stroke-linejoin', 'round')
//           .attr('rx', cornerRadius)
//           .attr('ry', cornerRadius);

//         // Apply the mask to the text
//         if (text) {
//           svg
//             .append('text')
//             .attr('x', width / 2 + padding / 2)
//             .attr('y', height / 2 + padding / 2)
//             .attr('dominant-baseline', 'middle')
//             .attr('text-anchor', 'middle')
//             .attr('fill', '#ffffff')
//             .text(text);
//         }
//       }, [width, height, stroke, fill, strokeWidth, rotation, padding, cornerRadius, text]);

//       return (
//         <svg
//           ref={svgRef}
//           xmlns="http://www.w3.org/2000/svg"
//           viewBox={`0 0 ${width + padding} ${height + padding}`}
//           width={width}
//           height={height}
//         />
//       );
//     };

// export const RightAngleTriangle: React.FC<MySVGComponentProps> = ({
//   width = 50,
//   height = 50,
//   stroke = '#000000',
//   strokeWidth = 1,
//   rotation = 'none',
// }) => {
//   const points = () => {
//     switch (rotation) {
//       case '90':
//         return `${width + 8},${height + 8} 8,8 8,${height + 8}`;
//       case '180':
//         return `${width + 8},${height + 8} 8,8 ${width + 8},8`;
//       case '270':
//         return `8,${height + 8} ${width + 8},${height + 8} ${width + 8},8`;
//       default:
//         return `8,8 ${width + 8},8 8,${height + 8}`;
//     }
//   };

//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${width + 8} ${height + 8}`} width={width} height={height}>
//       <polygon
//         points={points()}
//         fill="none"
//         stroke={stroke}
//         strokeWidth={strokeWidth}
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//     </svg>
//   );
// };

// export const RightAngleTriangle: React.FC<MySVGComponentProps> = ({
//   width = 50,
//   height = 50,
//   stroke = '#000000',
//   strokeWidth = 1,
//   rotation = 'none',
// }) => {
//   const points = () => {
//     switch (rotation) {
//       case '90':
//         return `${width},${height} 0,0 0,${height}`;
//       case '180':
//         return `${width},${height} 0,0 ${width},0`;
//       case '270':
//         return `0,${height} ${width},${height} ${width},0`;
//       default:
//         return `0,0 ${width},0 0,${height}`;
//     }
//   };

//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
//       <polygon
//         points={points()}
//         fill="none"
//         stroke={stroke}
//         strokeWidth={strokeWidth}
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//     </svg>
//   );
// };





// export const RightAngleTriangle: React.FC<MySVGComponentProps> = ({
//   width = 50,
//   height = 50,
//   stroke = '#000000',
//   fill = '#000000',
//   strokeWidth = 1,
//   rotation = 'none',
//   padding = 8,
//   cornerRadius = 0,
//   text = '',
// }) => {
//   const points = () => {
//     switch (rotation) {
//       case '90':
//         return `${width + padding},${height + padding} ${padding},0 ${padding},${height + padding}`;
//       case '180':
//         return `${width + padding},${height + padding} 0,${padding} ${width + padding},${padding}`;
//       case '270':
//         return `0,${height + padding} ${width + padding},${height + padding} ${width + padding},${padding}`;
//       default:
//         return `0,0 ${width + padding},0 0,${height + padding}`;
//     }
//   };

//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox={`0 0 ${width + padding} ${height + padding}`}
//       width={width}
//       height={height}
//     >
//       {/* Define a mask with the triangle shape */}
//       <defs>
//         <mask id="triangleMask" maskContentUnits="objectBoundingBox">
//           {/* <polygon points="1,1 0,0 1,0" fill="#fff" /> */}
//           <polygon
//         points={points()}
//         fill={fill}
//         stroke={stroke}
//         strokeWidth={strokeWidth}
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         rx={cornerRadius}
//         ry={cornerRadius}
//       />
//         </mask>
//       </defs>

//       {/* Draw the visible triangle with stroke and fill */}
//       <polygon
//         points={points()}
//         fill={fill}
//         stroke={stroke}
//         strokeWidth={strokeWidth}
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         rx={cornerRadius}
//         ry={cornerRadius}
//       />

//       {/* Apply the mask to the text */}
//       {text && (
//         <text
//           mask="url(#triangleMask)"
//           x={width / 2 + padding / 2}
//           y={height / 2 + padding / 2}
//           dominantBaseline="middle"
//           textAnchor="middle"
//           fill="#ffffff"
//         >
//           {text}
//         </text>
//       )}
//     </svg>
//   );
// };



// export const RightAngleTriangle: React.FC<MySVGComponentProps> = ({
//   width = 50,
//   height = 50,
//   stroke = '#000000',
//   fill = '#000000',
//   strokeWidth = 1,
//   rotation = 'none',
//   padding = 8,
//   cornerRadius = 0,
//   text = '', // Default empty string
// }) => {
//   const points = () => {
//     switch (rotation) {
//       case '90':
//         return `${width + padding},${height + padding} ${padding},0 ${padding},${height + padding}`;
//       case '180':
//         return `${width + padding},${height + padding} 0,${padding} ${width + padding},${padding}`;
//       case '270':
//         return `0,${height + padding} ${width + padding},${height + padding} ${width + padding},${padding}`;
//       default:
//         return `0,0 ${width + padding},0 0,${height + padding}`;
//     }
//   };

//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox={`0 0 ${width + padding} ${height + padding}`}
//       width={width}
//       height={height}
//     >
//       <polygon
//         points={points()}
//         fill={fill}
//         stroke={stroke}
//         strokeWidth={strokeWidth}
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         rx={cornerRadius}
//         ry={cornerRadius}
//       />
//       {text && (
//         <text
//           x={width / 2 + padding / 2} // Adjust the position based on your requirements
//           y={height / 2 + padding / 2}
//           dominantBaseline="middle"
//           textAnchor="middle"
//           fill="#ffffff" // Change the text color as needed
//         >
//           {text}
//         </text>
//       )}
//     </svg>
//   );
// };




// export const RightAngleTriangle: React.FC<MySVGComponentProps> = ({
//   width = 50,
//   height = 50,
//   stroke = '#000000',
//   fill = '#000000',
//   strokeWidth = 1,
//   rotation = 'none',
//   padding = 8,
//   cornerRadius = 0,
// }) => {
//   const points = () => {
//     switch (rotation) {
//       case '90':
//         return `${width + padding},${height + padding} ${padding},0 ${padding},${height + padding}`;
//       case '180':
//         return `${width + padding},${height + padding} 0,${padding} ${width + padding},${padding}`;
//       case '270':
//         return `0,${height + padding} ${width + padding},${height + padding} ${width + padding},${padding}`;
//       default:
//         return `0,0 ${width + padding},0 0,${height + padding}`;
//     }
//   };

//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox={`0 0 ${width + padding} ${height + padding}`}
//       width={width}
//       height={height}
//     >
//       <polygon
//         points={points()}
//         fill={fill}
//         stroke={stroke}
//         strokeWidth={strokeWidth}
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         rx={cornerRadius}
//         ry={cornerRadius}
//       />
//     </svg>
//   );
// };

// interface MySVGComponentProps {
//   width?: number;
//   height?: number;
//   fill?: string;
//   stroke?: string;
// }

// const RightSideRightAngle: React.FC<MySVGComponentProps> = ({ width = 50, height = 50, fill = '#FFFFFF', stroke = '#000000' }) => {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.392 33.392" width={width} height={height} fill={fill} stroke={stroke}>
//       <g transform="translate(-7.94,-7.051732)">
//         <path
//           fillRule="evenodd"
//           clipRule="evenodd"
//           d="M8,9.1153 C8,7.29468 10.2347,6.42094 11.4696,7.75874 L40.9016,39.6434 C42.0842,40.9246 41.1755,43 39.432,43 H 10 C 8.89543,43 8,42.1046 8,41 Z"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//           id="path1"
//         />
//       </g>
//     </svg>
//   );
// };


// const [errorInfo, setErrorInfo] = useState<IErrorInfo[]>([]);
// const [tagList, setTagList] = useState<ITag[]>([]);
// const [tagFormatedList, setTagFormatedList] = useState<ITag[]>([]);

// const [isAnimating, setIsAnimating] = useState(true);




// const footerCenterColorStyle = {
//   borderColor: childPanelInfoState.mainPanelInfo.styles.borderColor,
//   width: `${childPanelInfoState.mainPanelInfo.center.width - 35}px`, //31
//   // height: `${childPanelInfoState.mainPanelInfo.browser.height-35}px`, //31
// };

// <div style={colorStyle}></div>




// <div>
//   <div className="grid-container">
//     <div className="header"><div className="section-border"><PanelTop panelInfoState={panelInfoState} setPanelInfoState={setPanelInfoState} errorInfo={errorInfo} setErrorInfo={setErrorInfo}/></div></div>
//     <div className='three-column-layout'>
//       <div className="menu column column-30"><div className="section-border"><PanelLeft /></div></div>
//       <div className="main column column-40"><div className="section-border"><PanelCenter panelInfoState={panelInfoState} setPanelInfoState={setPanelInfoState} setErrorInfo={setErrorInfo} tagList={tagList} /></div></div>
//       <div className="right column column-30"><div className="section-border"><PanelRight panelInfoState={panelInfoState} setPanelInfoState={setPanelInfoState} /></div></div>
//     </div>
//     <div className="footer"></div>
//   </div>
// </div>


// interface SvgMaskProps {
//   width: number;
//   height: number;
// }

// const SvgMask: React.FC<SvgMaskProps> = ({ width, height }) => (
//   <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
//     <defs>
//       <mask id="mask" x="0" y="0" width="100%" height="100%" maskUnits="userSpaceOnUse">
//         {/* <rect x="0" y="0" width="100%" height="100%" fill="white" /> */}
//         {/* Add your mask shapes here */}
//         <circle cx="50%" cy="50%" r="40%" fill="black" />
//       </mask>
//     </defs>
//   </svg>
// );

// interface ContainerProps {
//   width: number;
//   height: number;
// }

// const Container: React.FC<ContainerProps> = ({ width, height }) => (
//   <span style={{ position: 'relative', width, height }}>
//     <SvgMask width={width} height={height} />
//     {/* Your content goes here */}
//     <span style={{ backgroundColor: 'blue', width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}></span>
//   </span>
// );

// background-color: #111111;
// color: red;

// const mainColors: React.CSSProperties = {
//   backgroundColor: childPanelInfoState.mainPanelInfo.styles.backgroundColor,
//   color: childPanelInfoState.mainPanelInfo.styles.fontColor,
// };
// const dangerButton: React.CSSProperties = {
//   backgroundColor: childPanelInfoState.mainPanelInfo.styles.fontColor,
// };

{/* <div className="overflow-auto section-border"
            style={{
              width: `${childPanelInfoState.mainPanelInfo.left.width - 50}px`
            }}>
            <pre>{JSON.stringify(childPanelInfoState.mainPanelInfo.header.jsonMessage, null, 2)}</pre>
          </div>

          <span className="width-spacer-10" />
          {childPanelInfoState.mainPanelInfo.header.message !== undefined && <div className="overflow-auto section-border animatid-div animate"
            style={{
              width: `${childPanelInfoState.mainPanelInfo.center.width - 50}px`
            }}>
            <div dangerouslySetInnerHTML={{ __html: sanitizeHTMLString }} />
          </div>} */}


{/* <div className="fhc-logo-mode" onClick={closeLogoMode}></div> */ }
{/* <div className="grid-container">
        <div className="header"><PanelTop panelInfoState={panelInfoState} setPanelInfoState={setPanelInfoState} /></div>
        <div className="menu"><PanelLeft /></div>
        <div className="main"><PanelCenter panelInfoState={panelInfoState} setPanelInfoState={setPanelInfoState} /></div>
        <div className="right"><PanelRight panelInfoState={panelInfoState} setPanelInfoState={setPanelInfoState} /></div>
        <div className="footer"></div>
      </div> */}

// Implement event handlers like `buttonPress`, `closeLogoMode`, and the missing logic as needed.
