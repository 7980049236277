import React, { useState } from 'react';
import FileUpload from './FileUpload';
import ImageList from './ImageList';
import { IChildPanelInfoProps } from '../../../types/main';
import FileUpload2 from './FileUpload2';
import FileList2 from './FileList2';

const Upload: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  const [refresh, setRefresh] = useState(false);
  const [showUpload, setShowUpload] = useState(false);

  // Function passed to the first child to trigger a refresh
  const triggerRefresh = () => {
    setRefresh((prev) => !prev);  // Toggle state to force a refresh
  };


  {/* <FirstChild triggerRefresh={triggerRefresh} />
      <SecondChild refresh={refresh} /> */}
  return (
    <div>
      {/* <FileUpload />
            <ImageList childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} /> */}
      <FileList2 childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} refresh={refresh} />

      {/* style={{ position: "absolute", margin: "5px", right: 0, top: 0, backgroundColor: childPanelInfoState.mainPanelInfo.styles.dangerButtonColor }} */}
      <button onClick={() => setShowUpload((prev) => !prev)}>Delete</button>
      {showUpload &&
        <FileUpload2 childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} triggerRefresh={triggerRefresh} />}
      {/* <FileList childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} /> */}
    </div>



  );
};

export default Upload;
