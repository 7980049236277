// import React from 'react';
// import { Chart, ArcElement, Tooltip } from 'chart.js';
// import { PolarArea } from 'react-chartjs-2';

// Chart.register(ArcElement, Tooltip);

// // Notes and their corresponding frequencies
// const notes = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'B#'];
// const frequencies = [
//   261.63,  // C
//   277.18,  // C#
//   293.66,  // D
//   311.13,  // D#
//   329.63,  // E
//   349.23,  // F
//   369.99,  // F#
//   392.00,  // G
//   415.30,  // G#
//   440.00,  // A
//   466.16,  // A#
//   493.88,  // B
//   523.25   // B#
// ];

// const RadialBarChart: React.FC<{ currentFreq: number }> = ({ currentFreq }) => {
//   const tolerance = 5; // Adjust this for more/less precision

//   const data = {
//     labels: notes,
//     datasets: [
//       {
//         data: frequencies.map((freq) => Math.abs(currentFreq - freq) <= tolerance ? 0 : Math.abs(currentFreq - freq)),
//         backgroundColor: [
//           '#FF6384', '#36A2EB', '#FFCE56', '#4CAF50', '#FF5722', '#673AB7',
//           '#FF8C00', '#00FF00', '#00CED1', '#FF1493', '#1E90FF', '#FFD700', '#7CFC00',
//         ],
//         hoverBackgroundColor: [
//           '#FF6384', '#36A2EB', '#FFCE56', '#4CAF50', '#FF5722', '#673AB7',
//           '#FF8C00', '#00FF00', '#00CED1', '#FF1493', '#1E90FF', '#FFD700', '#7CFC00',
//         ],
//         borderWidth: 2,
//       },
//     ],
//   };

//   return (
//     <div>
//       <h3>Radial Bar Chart</h3>
//       <PolarArea data={data} />
//       <p>Current Frequency: {currentFreq.toFixed(2)} Hz</p>
//     </div>
//   );
// };

// export default RadialBarChart;


import React from 'react';
import { Chart, ArcElement, Tooltip } from 'chart.js';
import { PolarArea } from 'react-chartjs-2';

Chart.register(ArcElement, Tooltip);

// const notes = ['E', 'A', 'D', 'G', 'B', 'e'];
// const frequencies = [82.41, 110, 146.83, 196, 246.94, 329.63];


// const notes = ['D', 'D#','E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'B#', 'C', 'C#', 'D', 'D#','e','e#'];
// const frequencies = [82.41, 110, 146.83, 196, 246.94, 329.63];

// // // Notes and their corresponding frequencies
// const notes = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'B#'];
// const frequencies = [
//   261.63,  // C
//   277.18,  // C#
//   293.66,  // D
//   311.13,  // D#
//   329.63,  // E
//   349.23,  // F
//   369.99,  // F#
//   392.00,  // G
//   415.30,  // G#
//   440.00,  // A
//   466.16,  // A#
//   493.88,  // B
//   523.25   // B#
// ];


// Frequencies based on E = 82.41 Hz for lower octave and e = 329.63 Hz for higher octave

// const notes = ['D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'B#', 'C', 'C#', 'D', 'D#','e','f'];

// const frequencies = [
//   73.42,  // D
//   77.78,  // D#
//   82.41,  // E
//   87.31,  // F
//   92.50,  // F#
//   98.00,  // G
//   103.83, // G#
//   110.00, // A
//   116.54, // A#
//   123.47, // B
//   130.81, // B#
//   138.59, // C
//   146.83, // C#
//   155.56, // D (next octave)
//   164.81, // D#
//   329.63, // e
//   349.23  // f (F in higher octave)
// ];

// const notes = ['D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'B#', 'C', 'C#', 'D', 'D#', 'e', 'f'];

// const frequencies = [
//   73.42,  // D (lower octave)
//   77.78,  // D#
//   82.41,  // E
//   87.31,  // F
//   92.50,  // F#
//   98.00,  // G
//   103.83, // G#
//   110.00, // A
//   116.54, // A#
//   123.47, // B
//   130.81, // B#
//   138.59, // C
//   146.83, // C#
//   155.56, // D (next octave)
//   164.81, // D#
//   329.63, // e (E in higher octave)
//   349.23  // f (F in higher octave)
// ];

const notes = [
    'D2', 'D#2', 'E2', 'F2', 'F#2', 'G2', 'G#2', 'A2', 'A#2', 'B2', 'C3', 'C#3', 'D3', 'D#3', 'E3',
    'F3', 'F#3', 'G3', 'G#3', 'A3', 'A#3', 'B3', 'C4', 'C#4', 'D4', 'D#4', 'E4',
    'F4', 'F#4', 'G4', 
  ];
  
  const frequencies = [
    73.42,  // D (lower octave)
    77.78,  // D#
    82.41,  87.31,  92.50,  98.00, 103.83, 110.00, 116.54, 123.47, 130.81, 138.59, 146.83, 155.56, 164.81,
    174.61, 185.00, 196.00, 207.65, 220.00, 233.08, 246.94, 261.63, 277.18, 293.66, 311.13, 329.63,
    349.23, 369.99, 392.00, 
  ];

  const backgroundColor = [
    '#FF6384',   // D2
    'lightgray', // D#2
    '#36A2EB',   // E2
    '#FFCE56',   // F2
    'lightgray', // F#2
    '#4CAF50',   // G2
    'lightgray', // G#2
    '#FF5722',   // A2
    'lightgray', // A#2
    '#673AB7',   // B2
    '#FF8C00',   // C3
    'lightgray', // C#3
    '#00FF00',   // D3
    'lightgray', // D#3
    '#00CED1',   // E3
    '#FF1493',   // F3
    'lightgray', // F#3
    '#1E90FF',   // G3
    'lightgray', // G#3
    '#FFD700',   // A3
    'lightgray', // A#3
    '#7CFC00',   // B3
    '#FF6384',   // C4
    'lightgray', // C#4
    '#36A2EB',   // D4
    'lightgray', // D#4
    '#FFCE56',   // E4
    '#4CAF50',   // F4
    'lightgray', // F#4
    '#FF5722'    // G4
  ];
  

//   backgroundColor: [
//     '#FF6384', '#36A2EB', '#FFCE56', '#4CAF50', '#FF5722', '#673AB7',
//     '#FF8C00', '#00FF00', '#00CED1', '#FF1493', '#1E90FF', '#FFD700', '#7CFC00',
//   ],
  

    // 'G#4', 'A4', 'A#4', 'B4', 'C5', 'C#5', 'D5', 'D#5', 'E5'
    // 415.30, 440.00, 466.16, 493.88, 523.25, 554.37, 587.33, 622.25, 659.26


const RadialBarChart: React.FC<{ currentFreq: number }> = ({ currentFreq }) => {
  const data = {
    labels: notes,
    datasets: [
      {
        data: frequencies.map((freq) => Math.abs(currentFreq - freq)),
        // backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4CAF50', '#FF5722', '#673AB7'],
        backgroundColor: backgroundColor,
      },
    ],
  };

  return (
    <div>
      <h3>Radial Bar Chart</h3>
      <PolarArea data={data} />
      <p>Current Frequency: {currentFreq} Hz</p>
    </div>
  );
};

export default RadialBarChart;
