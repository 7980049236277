import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { HourData, ISelectionData } from './Temperature';
import { IChildPanelInfo } from '../../../types/main';
import { formateDateTime, formateDateTimeNoYear } from '../../helpers/mainHelpers';

interface VisualizationProps {
  hoursData: HourData[];
  selectionDataState: ISelectionData;
  width: number;
  childPanelInfoState: IChildPanelInfo;
  setChildPanelInfoState: React.Dispatch<React.SetStateAction<IChildPanelInfo>>;
}

export const DotLine: React.FC<VisualizationProps> = (
  { hoursData,
    selectionDataState,
    width,
    childPanelInfoState,
    setChildPanelInfoState }) => {
  const chartContainer = useRef<HTMLDivElement | null>(null);
  //
  const showPointInfo = (colors: string[], hd: HourData, j: number) => {
    return `
      <span style='color: ${colors[0]}'>
        Date: ${formateDateTime(hd.time)}<br />
        Temperature: ${hd.sensorInfo[j].sensorData.temperature}<br />
        Humidity: ${hd.sensorInfo[j].sensorData.humidity}
        </span>
        `;
  }

  useEffect(() => {
    if (!chartContainer.current || hoursData.length === 0) return;

    // Adjusted margins
    const margin = { top: 20, right: 40, bottom: 120, left: 40 };
    const height = 450 - margin.top - margin.bottom; // Increase height

    const svg = d3.select(chartContainer.current).html('').append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const x = d3.scaleLinear().domain([0, Math.min(hoursData.length - 1, selectionDataState.maxDataPoints - 1)]).range([0, width]);
    const yTemperature = d3.scaleLinear().domain([0, 100]).range([height, 0]);
    const yHumidity = d3.scaleLinear().domain([0, 100]).range([height, 0]);

    // Custom scale function to handle undefined values
    const customScale = (value: number | undefined, scale: d3.ScaleLinear<number, number>) => {
      return value !== undefined ? scale(value) : 0; // Adjust this based on your requirements
    };

    const temperaturePoints = svg.selectAll('.temperature-point')
      .data(hoursData.slice(0, selectionDataState.maxDataPoints))
      .enter().append('g')
      .attr('class', 'temperature-point-group');

    const humidityPoints = svg.selectAll('.humidity-point')
      .data(hoursData.slice(0, selectionDataState.maxDataPoints))
      .enter().append('g')
      .attr('class', 'humidity-point-group');

    for (let i = 0; i < hoursData.length; i++) {
      const sensorInfo = hoursData[i].sensorInfo;
      const day = hoursData[i].day;
      const hour = hoursData[i].hour;
      const time = hoursData[i].time;

      for (let j = 0; j < sensorInfo.length; j++) {
        const sensorData = sensorInfo[j].sensorData;
        const sensorName = sensorInfo[j].sensorName;
        const colors = sensorInfo[j].colors;
        const temperature = sensorInfo[j].sensorData.temperature;
        const humidity = sensorInfo[j].sensorData.humidity;

        // Line generators
        const lineTemperature = d3.line<any>()
          .x((d, i) => x(i))
          .y((d) => customScale(d.sensorInfo[j].sensorData.temperature, yTemperature));

        const lineHumidity = d3.line<any>()
          .x((d, i) => x(i))
          .y((d) => customScale(d.sensorInfo[j].sensorData.humidity, yHumidity));

        // Draw temperature lines
        svg.append('path')
          .data([hoursData.slice(0, selectionDataState.maxDataPoints)])
          .attr('class', `temperature-line-${i}-${j}`)
          .attr('d', lineTemperature)
          .style('fill', 'none')
          .style('stroke', colors[0]);

        // Draw humidity lines
        svg.append('path')
          .data([hoursData.slice(0, selectionDataState.maxDataPoints)])
          .attr('class', `humidity-line-${i}-${j}`)
          .attr('d', lineHumidity)
          .style('fill', 'none')
          .style('stroke', colors[1]);

        // Draw temperature circles (scatter plot) with tooltips
        temperaturePoints.append('circle')
          .filter(d => d.sensorInfo[j].sensorData.temperature !== undefined) // Filter out undefined values
          .attr('class', 'temperature-point')
          .attr('cx', (d, i) => x(i))
          .attr('cy', d => customScale(d.sensorInfo[j].sensorData.temperature, yTemperature))
          .attr('r', 2)
          .style('fill', sensorInfo[j].colors[0]) // Change color as needed
          .on('mouseover', (event: React.MouseEvent<SVGCircleElement, MouseEvent>, d) => {
            // const info = `Date: ${d.day} - Time: ${d.hour}:00<br>Temperature: ${d.sensorInfo[j].sensorData.temperature}<br>Humidity: ${d.sensorInfo[j].sensorData.humidity}`;
            const info = showPointInfo(colors, d, j);
            setChildPanelInfoState(prevState => ({
              ...prevState,
              mainPanelInfo: {
                ...prevState.mainPanelInfo,
                header: {
                  ...prevState.mainPanelInfo.header,
                  message: info
                }
              }
            }));
          });

        // Draw humidity circles (scatter plot) with tooltips
        humidityPoints.append('circle')
          .filter(d => d.sensorInfo[j].sensorData.humidity !== undefined) // Filter out undefined values
          .attr('class', 'humidity-point')
          .attr('cx', (d, i) => x(i))
          .attr('cy', d => customScale(d.sensorInfo[j].sensorData.humidity, yHumidity))
          .attr('r', 2)
          .style('fill', sensorInfo[j].colors[0]) // Change color as needed
          .on('mouseover', (event: React.MouseEvent<SVGCircleElement, MouseEvent>, d) => {
            // const info = `Day: ${d.day} - Time: ${d.hour}:00<br>Temperature: ${d.sensorInfo[j].sensorData.temperature}<br>Humidity: ${d.sensorInfo[j].sensorData.humidity}`;
            const into = showPointInfo(colors, d, j);
            setChildPanelInfoState(prevState => ({
              ...prevState,
              mainPanelInfo: {
                ...prevState.mainPanelInfo,
                header: {
                  ...prevState.mainPanelInfo.header,
                  message: into
                }
              }
            }));
          });
      }
    }

    // Add x-axis
    svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom<number>(x)
        .ticks(Math.min(hoursData.length, selectionDataState.maxDataPoints))
        .tickFormat((d: number) => `${formateDateTimeNoYear(hoursData[d].time)} - ${hoursData[d].hour}:00`)
      )
      .selectAll('text') // Select all x-axis text elements
      .style('text-anchor', 'start')
      .attr('transform', 'rotate(45)'); // Rotate the text elements

    // Add y-axes
    svg.append('g').call(d3.axisLeft<number>(yTemperature));
    svg.append('g')
      .attr('transform', `translate(${width}, 0)`)
      .call(d3.axisRight<number>(yHumidity));

  }, [width, hoursData, selectionDataState.maxDataPoints, setChildPanelInfoState]);

  return (
    <div>
      <div ref={chartContainer} style={{ width: '100%', height: '400px' }} />
      {/* <div style={{ width: '100%', height: '50px' }}>{hoverInfo}</div> */}
    </div>
  );
};
