import React from 'react';
import { SensorChart } from './sensor-chart/SensorChart';
import { IChildPanelInfoProps } from '../../../types/main';

// [{`friendly_name`:`Z-Wave Stick`,`state`:`ready`,`node_id`:1,`neighbors`:`32,33,34,35,36,7,8,11,12,24,25`,`last_updated`:`2021-04-09T05:42:49.312586+00:00`},{`friendly_name`:`Green Multisensor 6`,`state`:`ready`,`node_id`:7,`neighbors`:`1`,`last_updated`:`2021-04-13T09:38:40.853820+00:00`},{`friendly_name`:`Dining room repeater`,`state`:`ready`,`node_id`:8,`neighbors`:`32,1,33,34,35,36,9,11,24,25`,`last_updated`:`2021-04-09T05:44:26.439847+00:00`},{`friendly_name`:`Kitchen Repeater`,`state`:`ready`,`node_id`:9,`neighbors`:`33,8,11,24,25`,`last_updated`:`2021-04-09T05:44:26.468379+00:00`},{`friendly_name`:`Office Mulitisensor 6`,`state`:`ready`,`node_id`:11,`neighbors`:`32,1,33,34,35,8,9,24,25`,`last_updated`:`2021-04-13T09:33:54.074560+00:00`},{`friendly_name`:`Office light plug`,`state`:`ready`,`node_id`:12,`neighbors`:`32,1,34,33,35,25`,`last_updated`:`2021-04-13T09:38:40.303894+00:00`},{`friendly_name`:`Garden Lights 2`,`state`:`ready`,`node_id`:19,`neighbors`:`undefined`,`last_updated`:`2021-04-13T01:00:21.003527+00:00`},{`friendly_name`:`Kitchen Plug`,`state`:`dead`,`node_id`:23,`neighbors`:`undefined`,`last_updated`:`2021-04-09T05:42:46.624717+00:00`},{`friendly_name`:`Dining room Multisensor 6`,`state`:`ready`,`node_id`:24,`neighbors`:`1,33,34,35,8,9,11`,`last_updated`:`2021-04-13T09:04:11.612302+00:00`},{`friendly_name`:`Outdoor Motion Sensor`,`state`:`sleeping`,`node_id`:25,`neighbors`:`1,33,8,9,11,12`,`last_updated`:`2021-04-13T09:20:59.272291+00:00`},{`friendly_name`:`Outside Plug 2`,`state`:`ready`,`node_id`:33,`neighbors`:`1,34,35,36,8,9,11,12,24,25`,`last_updated`:`2021-04-09T05:44:26.650166+00:00`},{`friendly_name`:`Kitchen Monitor Plug`,`state`:`ready`,`node_id`:34,`neighbors`:`1,33,35,36,8,11,12,24`,`last_updated`:`2021-04-13T05:20:27.053063+00:00`},{`friendly_name`:`AEON Labs ZW100 MultiSensor 6`,`state`:`dead`,`node_id`:36,`neighbors`:`undefined`,`last_updated`:`2021-04-09T05:43:18.448550+00:00`}]
export const dataName = "Tue, 13 Apr 2021 09:41:59 GMT";
export const networkData = [
    {
        "friendly_name": "Z-Wave Stick",
        "state": "ready",
        "node_id": 1,
        "neighbors": "32,33,34,35,36,7,8,11,12,24,25",
        "last_updated": "2021-04-09T05:42:49.312586+00:00"
    },
    {
        "friendly_name": "Green Multisensor 6",
        "state": "ready",
        "node_id": 7,
        "neighbors": "1",
        "last_updated": "2021-04-13T09:38:40.853820+00:00"
    },
    {
        "friendly_name": "Dining room repeater",
        "state": "ready",
        "node_id": 8,
        "neighbors": "32,1,33,34,35,36,9,11,24,25",
        "last_updated": "2021-04-09T05:44:26.439847+00:00"
    },
    {
        "friendly_name": "Kitchen Repeater",
        "state": "ready",
        "node_id": 9,
        "neighbors": "33,8,11,24,25",
        "last_updated": "2021-04-09T05:44:26.468379+00:00"
    },
    {
        "friendly_name": "Office Mulitisensor 6",
        "state": "ready",
        "node_id": 11,
        "neighbors": "32,1,33,34,35,8,9,24,25",
        "last_updated": "2021-04-13T09:33:54.074560+00:00"
    },
    {
        "friendly_name": "Office light plug",
        "state": "ready",
        "node_id": 12,
        "neighbors": "32,1,34,33,35,25",
        "last_updated": "2021-04-13T09:38:40.303894+00:00"
    },
    {
        "friendly_name": "Garden Lights 2",
        "state": "ready",
        "node_id": 19,
        "neighbors": "undefined",
        "last_updated": "2021-04-13T01:00:21.003527+00:00"
    },
    {
        "friendly_name": "Kitchen Plug",
        "state": "dead",
        "node_id": 23,
        "neighbors": "undefined",
        "last_updated": "2021-04-09T05:42:46.624717+00:00"
    },
    {
        "friendly_name": "Dining room Multisensor 6",
        "state": "ready",
        "node_id": 24,
        "neighbors": "1,33,34,35,8,9,11",
        "last_updated": "2021-04-13T09:04:11.612302+00:00"
    },
    {
        "friendly_name": "Outdoor Motion Sensor",
        "state": "sleeping",
        "node_id": 25,
        "neighbors": "1,33,8,9,11,12",
        "last_updated": "2021-04-13T09:20:59.272291+00:00"
    },
    {
        "friendly_name": "Outside Plug 2",
        "state": "ready",
        "node_id": 33,
        "neighbors": "1,34,35,36,8,9,11,12,24,25",
        "last_updated": "2021-04-09T05:44:26.650166+00:00"
    },
    {
        "friendly_name": "Kitchen Monitor Plug",
        "state": "ready",
        "node_id": 34,
        "neighbors": "1,33,35,36,8,11,12,24",
        "last_updated": "2021-04-13T05:20:27.053063+00:00"
    },
    {
        "friendly_name": "AEON Labs ZW100 MultiSensor 6",
        "state": "dead",
        "node_id": 36,
        "neighbors": "undefined",
        "last_updated": "2021-04-09T05:43:18.448550+00:00"
    }
]



export type ISensor = {
    friendly_name?: string;
    state?: string;
    node_id?: number;
    neighbors?: string | undefined;
    last_updated?: string;
    index?: number;
    x?: number;
    y?: number;
    vx?: number;
    vy?: number;
    fx?: number | null;
    fy?: number | null;
  };

const SensorTable: React.FC<{ sensorData: ISensor[] }> = ({ sensorData }) => {
    return (
        <table>
            <thead>
                <tr>
                    <th>Friendly Name</th>
                    <th>State</th>
                    <th>Node ID</th>
                    <th>Neighbors</th>
                    <th>Last Updated</th>
                </tr>
            </thead>
            <tbody>
                {sensorData.map((sensor) => (
                    <tr key={sensor.node_id}>
                        <td>{sensor.friendly_name}</td>
                        <td>{sensor.state}</td>
                        <td>{sensor.node_id}</td>
                        <td>{sensor.neighbors}</td>
                        <td>{sensor.last_updated}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export const Network: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
    const sensorData: ISensor[] = networkData;
  
    return (
      <div>
        <h1>Sensor Data</h1>
        <SensorChart sensorData={sensorData} childPanelInfoState={childPanelInfoState} />
        <SensorTable sensorData={sensorData} />
      </div>
    );
  };
