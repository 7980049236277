import React, { useState } from 'react';

interface Factors {
  factor1: boolean;
  factor2: boolean;
  factor3: boolean;
}

interface ScoreCalculatorProps {
  goalNumber: number;
}

export const ScoreCalculator: React.FC<ScoreCalculatorProps> = ({ goalNumber }) => {
  const [factors, setFactors] = useState<Factors>({
    factor1: false,
    factor2: false,
    factor3: false,
  });

  const calculateScore = (): number => {
    let score = 0;

    if (factors.factor1) {
      // Adjust score based on factor 1 importance
      score += 10;
    }

    if (factors.factor2) {
      // Adjust score based on factor 2 importance
      score += 5;
    }

    if (factors.factor3) {
      // Adjust score based on factor 3 importance
      score += 3;
    }

    // Calculate the absolute difference between the goal and the score
    const difference = Math.abs(goalNumber - score);

    // The score is the goal minus the difference
    return goalNumber - difference;
  };

  const handleCheckboxChange = (factor: keyof Factors) => {
    setFactors((prevFactors) => ({
      ...prevFactors,
      [factor]: !prevFactors[factor],
    }));
  };

  return (
    <div>
      <h2>Score Calculator</h2>
      <p>Goal Number: {goalNumber}</p>

      <div>
        <label>
          <input
            type="checkbox"
            checked={factors.factor1}
            onChange={() => handleCheckboxChange('factor1')}
          />
          Factor 1
        </label>
      
        <label>
          <input
            type="checkbox"
            checked={factors.factor2}
            onChange={() => handleCheckboxChange('factor2')}
          />
          Factor 2
        </label>
      
        <label>
          <input
            type="checkbox"
            checked={factors.factor3}
            onChange={() => handleCheckboxChange('factor3')}
          />
          Factor 3
        </label>
      </div>

      <p>Your Score: {calculateScore()}</p>
    </div>
  );
};



// import React, { useState } from 'react';

// interface ScoreCalculatorProps {
//   goalNumber: number;
// }

// const ScoreCalculator: React.FC<ScoreCalculatorProps> = ({ goalNumber }) => {
//   const [factors, setFactors] = useState({
//     factor1: false,
//     factor2: false,
//     factor3: false,
//   });

//   const calculateScore = (): number => {
//     let score = 0;

//     if (factors.factor1) {
//       // Adjust score based on factor 1 importance
//       score += 10;
//     }

//     if (factors.factor2) {
//       // Adjust score based on factor 2 importance
//       score += 5;
//     }

//     if (factors.factor3) {
//       // Adjust score based on factor 3 importance
//       score += 3;
//     }

//     // Calculate the absolute difference between the goal and the score
//     const difference = Math.abs(goalNumber - score);

//     // The score is the goal minus the difference
//     return goalNumber - difference;
//   };

//   const handleCheckboxChange = (factor: string) => {
//     setFactors((prevFactors) => ({
//       ...prevFactors,
//       [factor]: !prevFactors[factor],
//     }));
//   };

//   return (
//     <div>
//       <h2>Score Calculator</h2>
//       <p>Goal Number: {goalNumber}</p>

//       <div>
//         <label>
//           <input
//             type="checkbox"
//             checked={factors.factor1}
//             onChange={() => handleCheckboxChange('factor1')}
//           />
//           Factor 1
//         </label>
//       </div>

//       <div>
//         <label>
//           <input
//             type="checkbox"
//             checked={factors.factor2}
//             onChange={() => handleCheckboxChange('factor2')}
//           />
//           Factor 2
//         </label>
//       </div>

//       <div>
//         <label>
//           <input
//             type="checkbox"
//             checked={factors.factor3}
//             onChange={() => handleCheckboxChange('factor3')}
//           />
//           Factor 3
//         </label>
//       </div>

//       <p>Your Score: {calculateScore()}</p>
//     </div>
//   );
// };

// export default ScoreCalculator;