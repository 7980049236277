import React, { useEffect, useState } from 'react';
import { IChildPanelInfoProps } from '../../../../types/main';

interface appColors {
  fontColor: string;
  backgroundColor: string;
  borderColor: string;
}

const ColorPicker: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  const [fontColor, setFontColor] = useState<string>(childPanelInfoState.mainPanelInfo.styles.fontColor);
  const [backgroundColor, setBackgroundColor] = useState<string>(childPanelInfoState.mainPanelInfo.styles.backgroundColor);
  const [borderColor, setBorderColor] = useState<string>(childPanelInfoState.mainPanelInfo.styles.borderColor);
  const [listOfColors, setListOfColors] = useState<appColors[]>([]);

  const saveColorSet = () => {
    const newColors: appColors = {
      fontColor: fontColor,
      backgroundColor: backgroundColor,
      borderColor: borderColor,
    };
    setListOfColors([...listOfColors, newColors]);
  };

  const selectColorSet = (index:number) => {
    if (childPanelInfoState !== undefined) {
      const infoState = { ...childPanelInfoState };
      infoState.mainPanelInfo.styles.backgroundColor = listOfColors[index].backgroundColor;
      setBackgroundColor(listOfColors[index].backgroundColor);
      infoState.mainPanelInfo.styles.fontColor = listOfColors[index].fontColor;
      setFontColor(listOfColors[index].fontColor);
      infoState.mainPanelInfo.styles.borderColor = listOfColors[index].borderColor;
      setBorderColor(listOfColors[index].borderColor);
      setChildPanelInfoState(infoState);
    }
  };

  const deleteColorSet = (index:number) => {
    
  };

  useEffect(() => {
    if (childPanelInfoState !== undefined) {
      const infoState = { ...childPanelInfoState };
      infoState.mainPanelInfo.styles.backgroundColor = backgroundColor;
      setChildPanelInfoState(infoState);
    }
  }, [backgroundColor]);
  useEffect(() => {
    if (childPanelInfoState !== undefined) {
      const infoState = { ...childPanelInfoState };
      infoState.mainPanelInfo.styles.fontColor = fontColor;
      setChildPanelInfoState(infoState);
    }
  }, [fontColor]);
  useEffect(() => {
    if (childPanelInfoState !== undefined) {
      const infoState = { ...childPanelInfoState };
      infoState.mainPanelInfo.styles.borderColor = borderColor;
      setChildPanelInfoState(infoState);
    }
  }, [borderColor]);

  useEffect(() => {
    const defaultColorList = [{
      "fontColor": "#94b6ff",
      "backgroundColor": "#1e2236",
      "borderColor": "#96c3ff"
    },
    {
      "fontColor": "#ff0000",
      "backgroundColor": "#000000",
      "borderColor": "#ff0000"
    },
    {
      "fontColor": "#fff",
      "backgroundColor": "#1e2236",
      "borderColor": "#96c3ff"
    }]
    setListOfColors(defaultColorList);
  }, []);

  return (
    <>
      <table>
        <tbody>
          <tr>
            <td className='column-button'>

            </td>
            <td>
              Background Color:
            </td>
            <td>
              <input type="color" value={backgroundColor} onChange={(e) => { setBackgroundColor(e.target.value) }} />
            </td>
            <td>
              Font Color:
            </td>
            <td>
              <input type="color" value={fontColor} onChange={(e) => { setFontColor(e.target.value) }} />
            </td>
            <td>
              Border Color:
            </td>
            <td>
              <input type="color" value={borderColor} onChange={(e) => { setBorderColor(e.target.value) }} />
            </td>
            <td className='column-button'>
              <button title="Save" onClick={(e) => { saveColorSet() }} />
            </td>
          </tr>
          {listOfColors.map((colors, index) => (
            <tr>
              <td className='column-button'>
                <button title="Select" onClick={(e) => { selectColorSet(index) }} />
              </td>
              <td>
                Background Color:
              </td>
              <td >
                <div style={{ backgroundColor: colors.backgroundColor, width: 64, height:32 }}></div>
              </td>
              <td>
                Font Color:
              </td>
              <td>
                <div style={{ backgroundColor: colors.fontColor, width: 64, height:32 }}></div></td>
              <td>
                Border Color:
              </td>
              <td>
                <div style={{ backgroundColor: colors.borderColor, width: 64, height:32 }}></div></td>
              <td className='column-button'>
                <button title="Delete" style={{backgroundColor: childPanelInfoState.mainPanelInfo.styles.dangerButtonColor}} className='dangerButtonColor' onClick={(e) => { deleteColorSet(index) }} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ColorPicker;
