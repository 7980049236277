import React from 'react';
import { useState } from "react";
import { calculateTotalAmountByPerson, peopleLookUp } from "../../helpers/moneyHelpers";
import { IMoney } from "../../../types/money";

interface IResultByPersonProps {
  moneyList: IMoney[];
}

export const ResultsByPerson: React.FC<IResultByPersonProps> = ({ moneyList }) => {
  const { totalAmountByPerson, personWhoOwes, difference } = calculateTotalAmountByPerson(moneyList);

  return <div>
    <h2>Results by Person</h2>
    <div>
      {peopleLookUp(Number(personWhoOwes))}: <span className="highlight-section">${difference.toLocaleString()}</span>
    </div>
    
    {Object.keys(totalAmountByPerson).map((personId) => (
      <div key={personId}>
        {peopleLookUp(Number(personId))}: <span className="highlight-section">${totalAmountByPerson[Number(personId)].toLocaleString()}</span>
      </div>
    ))}
  </div>
}