import React, { ChangeEvent, useEffect, useState } from 'react';
import './song-list.scss';
import { IDataReturn, IDataForAPI, IPostData, fetchPostsByTags, handleAdd, handleDelete, handleUpdate } from '../../../radApi';
import { v4 as uuidv4 } from 'uuid';
import { handleReorder, onGetOrderData, postOrderReturn, updateOrderedPosts } from '../../../helpers/orderHelpers';
import { onError } from '../../../helpers/mainHelpers';
import { IChildPanelInfoProps } from '../../../../types/main';

export interface IMusicData {
  postId: number;
  index: number;
  title?: string;
  id: string;
  key: string;
  chordProgression: string;
  chordsRepeat: boolean;
  capo: number;
  notes: string;
  artist: string;
  songName: string;
  parts: {
    intro: boolean;
    verse: boolean;
    preChorus: boolean;
    chorus: boolean;
    bridge: boolean;
    outro: boolean;
  };
  created?: string;
  updated?: string;
  ordinal?: number;
}

const defaultListItem: IMusicData = {
  postId: 0,
  index: 0,
  title: '',
  id: '',
  key: '',
  chordProgression: '',
  chordsRepeat: false,
  capo: 0,
  notes: '',
  artist: '',
  songName: '',
  parts: {
    intro: false,
    verse: false,
    preChorus: false,
    chorus: false,
    bridge: false,
    outro: false,
  },
  ordinal: 1,
};

const SongList: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  const dataForAPI: IDataForAPI = {
    userId: childPanelInfoState.userInfo.id,
    key: childPanelInfoState.userInfo.key,
    tagIds: [6, 9],
    status: 1,
    encryptData: childPanelInfoState.mainPanelInfo.system.encryptData
  }

  // const apiTags = [6];
  const [allPosts, setAllPosts] = useState<IPostData[]>([]);
  const [editModeOn, setEditModeOn] = useState<boolean>(false);
  // const [editId, setEditId] = useState<number>(0);
  const [originalData, setOriginalData] = useState<IMusicData[]>([]);
  const [listOfPostIds, setListOfPostIds] = useState<number[]>([]);

  // const [sortedTableData, setSortedTableData] = useState<IMusicData[]>([]);
  const [formData, setFormData] = useState<IMusicData | undefined>(undefined);
  const [formDataOrder, setFormDataOrder] = useState<number>(0);
  const [orderedIds, setOrderedIds] = useState<number[]>([]);//215, 149, 152, 155, 156, 157
  const [orderPostId, setOrderPostId] = useState<number | undefined>(undefined);

  const onAdd = (title: string, newEntry: any) => {
    const dataForAPIUpdated = { ...dataForAPI, title: title, formData: newEntry };
    handleAdd(dataForAPIUpdated).then((postData: IDataReturn) => {
      if (postData.success) {
        onGetData();
        setFormData(undefined);
      } else {
        onError({
          childPanelInfoState: childPanelInfoState,
          setChildPanelInfoState: setChildPanelInfoState,
          friendly: 'Error Adding',
          error: { errors: postData.errors, requestOptions: postData.requestOptions, variables: postData.variables }
        });
      }
    })
      .catch((error: any) => {
        onError({
          childPanelInfoState: childPanelInfoState,
          setChildPanelInfoState: setChildPanelInfoState,
          friendly: 'Error Adding',
          error: error
        });
      });
  };

  const onUpdate = (postId: number) => {
    if (formData !== undefined) {
      const title = `${formData.songName} by ${formData.artist} in ${formData.key}`;
      const updatedForm = {
        ...formData,
        title: title,
        updated: new Date().toLocaleString()
      };
      const dataForAPIUpdated = { ...dataForAPI, postId: postId, title: title, formData: updatedForm };
      handleUpdate(dataForAPIUpdated).then((postData: IDataReturn) => {
        if (postData.success) {
          onGetData();
          // replaceOrdinal();
          setFormData(undefined);
        } else {
          onError({
            childPanelInfoState: childPanelInfoState,
            setChildPanelInfoState: setChildPanelInfoState,
            friendly: 'Error Updating',
            error: { errors: postData.errors, requestOptions: postData.requestOptions, variables: postData.variables }
          });
        }
      })
        .catch((error) => {
          onError({
            childPanelInfoState: childPanelInfoState,
            setChildPanelInfoState: setChildPanelInfoState,
            friendly: 'Error Updating',
            error: error
          });
        });
    }
  };

  const onDelete = (postId: number) => {
    const dataForAPIUpdated = { ...dataForAPI, postId: postId };
    handleDelete(dataForAPIUpdated).then((postData: IDataReturn) => {
      console.log('delete', postData);
      if (postData.success) {
        onGetData();
      } else {
        onError({
          childPanelInfoState: childPanelInfoState,
          setChildPanelInfoState: setChildPanelInfoState,
          friendly: 'Error Updating',
          error: { errors: postData.errors, requestOptions: postData.requestOptions, variables: postData.variables }
        });
      }
    })
      .catch((error) => {
        onError({
          childPanelInfoState: childPanelInfoState,
          setChildPanelInfoState: setChildPanelInfoState,
          friendly: 'Error Updating',
          error: error
        });
      });
  };

  const onGetData = async () => {
    const dataForAPIUpdated = { ...dataForAPI };
    const postData: IDataReturn = await fetchPostsByTags(dataForAPIUpdated);
    if (postData.success) {
      const posts = postData.posts as IPostData[];
      setAllPosts(posts);
      const data = postData.data as IMusicData[];
      setOriginalData(data);
      const listOfPostIds = postData.listOfPostIds as number[];
      setListOfPostIds(listOfPostIds as number[]);

      console.log('1 dataForAPI',dataForAPI);
      console.log('2 listOfPostIds',listOfPostIds);
      const { portId, orderedId } = await onGetOrderData(dataForAPI, listOfPostIds as number[]);
      setOrderedIds(orderedId);
      setOrderPostId(portId);
    } else {
      onError({
        childPanelInfoState: childPanelInfoState,
        setChildPanelInfoState: setChildPanelInfoState,
        friendly: 'Error Getting',
        error: { errors: postData.errors, requestOptions: postData.requestOptions, variables: postData.variables }
      });
    }
  }

  // List Order
  // Color Scheme
  // Song Info
  // Notes
  // Money
  // Todo
  // Temp
  // Invoice

  useEffect(() => {
    console.log('--->useEffect fired<---');
    onGetData();
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData !== undefined) {
      const title = `${formData.songName} by ${formData.artist} in ${formData.key}`;
      const updatedForm = {
        ...formData,
        id: uuidv4(),
        title: title,
        created: new Date().toLocaleString(),
        updated: "",
      };
      console.log('handleSubmit', title);
      onAdd(`${title}`, updatedForm);
    }
  };

  const onEdit = (postId: number, index: number) => {
    const rowData: IMusicData = originalData.find((item) => {
      return item.postId == postId;
    }) as IMusicData;
    setFormData(rowData);
    setFormDataOrder(index + 1);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (formData) {
      if (e.target.type === 'checkbox') {
        if (e.target.name === "chordsRepeat") {
          setFormData({
            ...formData,
            chordsRepeat: (e.target as HTMLInputElement).checked,
          });
        } else {
          setFormData({
            ...formData,
            parts: {
              ...formData.parts,
              [e.target.name]: (e.target as HTMLInputElement).checked,
            },
          });
        }
      } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    }
  };

  const createNew = () => {
    setFormData(defaultListItem as IMusicData);
  }

  const close = () => {
    setFormData(undefined);
  }

  const handleOrderChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (formData) {
      const value = parseInt(e.target.value);
      if (value > 0 && value <= orderedIds.length) {
        setFormDataOrder(value);// this is for the form, so that it can be changed
        await handleReorder(dataForAPI, orderPostId, orderedIds, formData.postId, value - 1);
        const { portId, orderedId } = await onGetOrderData(dataForAPI, listOfPostIds as number[]);
        setOrderedIds(orderedId);
        setOrderPostId(portId);
      }
    }
  };

  return (
    <div className="song-list">
      <div className='spacer-10' />
      {(originalData !== undefined && originalData.length > 0) &&
        <table border={1}>
          <tbody>
            {Object.values(orderedIds).map((id, index) => {

              const rowData = originalData.find((item) => {
                return item.postId == id
              }
              ) as IMusicData;

              return (
                <>
                  {rowData && <tr key={rowData.id}>
                    <td className='column-button'>
                      <button title="Edit" onClick={() => onEdit(rowData.postId, index)}>Edit</button>
                    </td>
                    <td className="column-center">
                      {index + 1}
                    </td>
                    <td>{rowData.postId}</td>
                    <td>{rowData.key}</td>
                    <td>{rowData.songName}</td>
                    <td>{rowData.artist}</td>
                    <td>{rowData.chordProgression}</td>
                    <td>{rowData.chordsRepeat ? "Repeats" : ""}</td>
                    <td>{rowData.capo > 0 ? rowData.capo : ""}</td>
                    <td className='column-button'>
                      <button style={{ backgroundColor: childPanelInfoState.mainPanelInfo.styles.dangerButtonColor }} className='dangerButtonColor' title="Delete" onClick={() => onDelete(rowData.postId as number)}>Delete</button>
                    </td></tr>}
                </>
              );
            })}
          </tbody>
        </table>
      }
      <div className='spacer-10' />

      <button title="New" onClick={createNew}>New</button>
      {formData !== undefined && <button title="Close" onClick={close} className='right' style={{ backgroundColor: childPanelInfoState.mainPanelInfo.styles.dangerButtonColor }}>New</button>}

      {formData !== undefined && <div>
        <div className='spacer-10' />
        <form onSubmit={handleSubmit}>
          <table className='no-padding'>
            <tbody>
              <tr>
                <td style={{ width: '33%' }}>
                  <label>
                    Order:
                    <input
                      type="number"
                      name="ordinal"
                      value={formDataOrder}
                      onChange={handleOrderChange}
                    />
                  </label>
                </td>
                <td style={{ width: '33%' }}>
                  <label>
                    Artist:
                    <input
                      type="text"
                      name="artist"
                      value={formData.artist}
                      onChange={handleChange}
                    />
                  </label>
                </td>
                <td style={{ width: '33%' }}>
                  <label>
                    Song Name:
                    <input
                      type="text"
                      name="songName"
                      value={formData.songName}
                      onChange={handleChange}
                    />
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label>
                    Key:
                    <input type="text" name="key" value={formData.key} onChange={handleChange} />
                  </label>
                </td>
                <td>
                  <label className='chord-progression'>
                    Chord Progress:
                    <input
                      type="text"
                      name="chordProgression"
                      value={formData.chordProgression}
                      onChange={handleChange}
                    />
                  </label>
                </td>
                <td>
                  <label className='chord-repeats'>
                    Repeat:
                    <input
                      type="checkbox"
                      name="chordsRepeat"
                      checked={formData.chordsRepeat}
                      onChange={handleChange}
                    />
                  </label>
                  <label className='chord-repeats'>
                    Capo:
                    <input
                      type="number"
                      name="capo"
                      value={formData.capo}
                      onChange={handleChange}
                    />
                  </label>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <table>
                    <tbody>
                      <tr>
                        <th style={{ width: '16.5%' }}>Intro</th>
                        <th style={{ width: '16.5%' }}>Verse</th>
                        <th style={{ width: '16.5%' }}>Pre-Chor</th>
                        <th style={{ width: '16.5%' }}>Chorus</th>
                        <th style={{ width: '16.5%' }}>Bridge</th>
                        <th style={{ width: '16.5%' }}>Outro</th>
                      </tr>
                      <tr>
                        <td><input
                          type="checkbox"
                          name="intro"
                          checked={formData.parts.intro}
                          onChange={handleChange}
                        /></td>
                        <td><input
                          type="checkbox"
                          name="verse"
                          checked={formData.parts.verse}
                          onChange={handleChange}
                        /></td>
                        <td><input
                          type="checkbox"
                          name="preChorus"
                          checked={formData.parts.preChorus}
                          onChange={handleChange}
                        /></td>
                        <td><input
                          type="checkbox"
                          name="chorus"
                          checked={formData.parts.chorus}
                          onChange={handleChange}
                        /></td>
                        <td><input
                          type="checkbox"
                          name="bridge"
                          checked={formData.parts.bridge}
                          onChange={handleChange}
                        /></td>
                        <td ><input
                          type="checkbox"
                          name="outro"
                          checked={formData.parts.outro}
                          onChange={handleChange}
                        /></td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <label>
            Notes:
            <textarea name="notes" value={formData.notes} onChange={handleChange} />
          </label>
          <div className='spacer-10' />
          {(formData.postId !== 0) ?
            <button type="button" title="Edit" onClick={() => onUpdate(formData.postId)}>Edit</button>
            :
            <button type="submit" title="Add">Add</button>
          }
        </form>
      </div>
      }


    </div>
  );
};

export default SongList;



// // App.tsx
// import React, { useState } from 'react';
// import './song-list.scss';

// interface MusicData {
//   key: string;
//   chordProgression: string;
//   capo: string;
//   notes: string;
//   artist: string;
//   songName: string;
// }

// const SongList: React.FC = () => {
//   const [formData, setFormData] = useState<MusicData>({
//     key: '',
//     chordProgression: '',
//     capo: '',
//     notes: '',
//     artist: '',
//     songName: '',
//   });

//   const [musicList, setMusicList] = useState<MusicData[]>([]);

//   const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = (e: React.FormEvent) => {
//     e.preventDefault();
//     setMusicList([...musicList, formData]);
//     setFormData({
//       key: '',
//       chordProgression: '',
//       capo: '',
//       notes: '',
//       artist: '',
//       songName: '',
//     });
//   };

//   const handleEdit = (index: number) => {
//     const editedItem = musicList[index];
//     setFormData(editedItem);
//     setMusicList(musicList.filter((_, i) => i !== index));
//   };

//   return (
//     <div className="song-list">
//       <ul>
//         {musicList.map((item, index) => (
//           <li key={index}>
//             {item.key} - {item.songName} by {item.artist}
//             <button onClick={() => handleEdit(index)}>Edit</button>
//           </li>
//         ))}
//       </ul>
//       <form onSubmit={handleSubmit}>
//         <table>
//           <tbody>
//             <tr>
//               <td><label>
//                 Artist:
//                 <input type="text" name="artist" value={formData.artist} onChange={handleChange} />
//               </label></td>
//               <td><label>
//                 Song Name:
//                 <input type="text" name="songName" value={formData.songName} onChange={handleChange} />
//               </label></td>
//             </tr>
//             <tr>
//               <td><label>
//                 Key:
//                 <input type="text" name="key" value={formData.key} onChange={handleChange} />
//               </label></td>
//               <td><label>
//                 Chord Progression:
//                 <input type="text" name="chordProgression" value={formData.chordProgression} onChange={handleChange} />
//               </label></td>
//             </tr>
//           </tbody>
//         </table>
//         <label>
//           Notes:
//           <textarea name="notes" value={formData.notes} onChange={handleChange} />
//         </label>
//         <button type="submit">Add to List</button>
//       </form>
//     </div>
//   );
// };

// export default SongList;
