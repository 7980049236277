import React, { useState, useEffect } from 'react';

const DateTime: React.FC = () => {
      const [dateTime, setDateTime] = useState(new Date());
    
      useEffect(() => {
        const intervalId = setInterval(() => {
          setDateTime(new Date());
        }, 60000);
    
        // Cleanup the interval when the component is unmounted
        return () => clearInterval(intervalId);
      }, []);
    
      const formatTime = (date: Date): string => {
        const options: Intl.DateTimeFormatOptions = {
          hour: 'numeric',
          minute: 'numeric',
        //   second: 'numeric',
        };
        return date.toLocaleTimeString(undefined, options);
      };
    
      const formatDate = (date: Date): string => {
        const options: Intl.DateTimeFormatOptions = {
          month: 'short',
          day: 'numeric',
        //   year: 'numeric',
        };
        return date.toLocaleDateString(undefined, options);
      };
    
      return (
        <div style={{fontSize:28,textAlign:'center'}}>{formatDate(dateTime)} {formatTime(dateTime)}</div>
      );
    };
    
export default DateTime;
