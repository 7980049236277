// LoginForm.tsx

import React, { useState } from 'react';
import "./login-form.scss";
import { IUserInfo } from '../../../types/main';

interface LoginFormProps {
  onLogin: (userInfo: IUserInfo) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin }) => {
  const [error, setError] = useState<string | null>(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [cryptoKey, setCryptoKey] = useState(null);
  const [isUsernameEntered, setIsUsernameEntered] = useState(false);
  const baseLink = 'https://exec.artificial.opalstacked.com';
  const logInLink1 = `${baseLink}/api/login/`;//'https://exec.artificial.opalstacked.com/api/login/';
  const logInLink2 = `${baseLink}/api/login2/`;//'https://exec.artificial.opalstacked.com/api/login2/';
  const logOutLink = `${baseLink}/api/logout/`;//'https://exec.artificial.opalstacked.com/api/login2/';

  const handleUsernameSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    login1(username, password);
    // call api here
  };

  const handleLoginSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    login2(username, password);
    // Call the onLogin function with the entered username and password
  };

  const login1 = async (username: string, password: string) => {
    try {
      const response = await fetch(logInLink1, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        // First step successful, you may proceed to the second step
        const responseData = await response.json();
        console.log(responseData);

        setIsUsernameEntered(true);
      } else {
        const errorData = await response.json();
        console.error(errorData.message);
        setError(errorData.message);
        // Handle error, show message, etc.
      }
    } catch (error) {
      console.error('An error occurred during login:', error);
      // Handle error, show message, etc.
    }
  };

  const login2 = async (username: string, password: string) => {
    try {
      const response = await fetch(logInLink2, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      console.error(data);

      if (response.ok && data.responseId === 0 && data.key) {

        // Assuming encodedKey is the Base64-encoded key from Django
        // const encodedKey = "eW91cl9iaW5hcnlfa2V5X3ZhbHVl";
        // "omC+6+lj3+aP3Y04oA9Zb+pEHg2sCfMuhWbd5Ag5ebk="
        const encodedKey = data.key;
        // Decode the Base64 string to bytes
        const decodedBytes = Uint8Array.from(atob(encodedKey), (c) => c.charCodeAt(0));
        // Convert the bytes to a CryptoKey
        window.crypto.subtle.importKey(
          'raw',
          decodedBytes,
          { name: 'AES-GCM' },
          false,
          ['encrypt', 'decrypt']
        ).then((cryptoKey) => {
          // Now you have a CryptoKey, you can use it for encryption or decryption with AES-GCM

          // Set the obtained key in the component state
          // setCryptoKey(data.key);

          //we need more complex ids from the database
          const userInfo: IUserInfo = {
            username: username,
            id: data.userId,
            key: cryptoKey
          }
          onLogin(userInfo);


          console.log('CryptoKey:', cryptoKey);
        });

      } else if (data.responseId === 2) {
        // const errorData = await response.json();
        // console.error(errorData.message);
        setError('Invalid username or password');
        // Handle error, show message, etc.

        setIsUsernameEntered(false);
        setUsername('');
        setPassword('');
      } else {
        // const errorData = await response.json();
        // console.error(errorData.message);
        setError('Invalid username or password');
        // Handle error, show message, etc.

        setIsUsernameEntered(false);
        setUsername('');
        setPassword('');
      }
    } catch (error) {
      console.error('An error occurred during login:', error);
      // Handle error, show message, etc.
    }
  };

  // logOutLink
  const logOut = async () => {
    try {
      const response = await fetch(logOutLink, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok && data.key) {
        // // Set the obtained key in the component state
        // setCryptoKey(data.key);

        // //we need more complex ids from the database
        // const id = "1";
        // onLogin(username, id);
        setIsUsernameEntered(false);
        setUsername('');
        setPassword('');
      } else {
        const errorData = await response.json();
        console.error(errorData.message);
        setError(errorData.message);
        // Handle error, show message, etc.

        setIsUsernameEntered(false);
        setUsername('');
        setPassword('');
      }
    } catch (error) {
      console.error('An error occurred during login:', error);
      // Handle error, show message, etc.
    }
  };

  const override = ():void => {
    login2('will','p@ssw0rd');
  }

  return (
    <div className='login-form section-border' >

      {error && <div style={{ color: 'red' }}>{error}</div>}
      {!isUsernameEntered ? (
        <form onSubmit={handleUsernameSubmit}>
          <label>
            User Name2:
            <input
              type="text"
              value={username} autoFocus
              onChange={(e) => setUsername(e.target.value)}
            />
          </label>
          <button type="submit">Next</button>
          <span className='width-spacer-10' />
          <button type="button" className='round' onClick={override}>override</button>
        </form>
      ) : (
        <form onSubmit={handleLoginSubmit}>
          <label>
            Password:
            <input
              type="password"
              value={password} autoFocus
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <button type="submit">Login</button>
        </form>
      )}
    </div>
  );
};

export default LoginForm;



// const login = async (username: string, password: string) => {
//   try {
//     const response = await fetch('your-api-endpoint/login/', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ username, password }),
//     });

//     if (response.ok) {
//       // First step successful, you may proceed to the second step
//       const responseData = await response.json();
//       console.log(responseData);

//       // Make another request or navigate to the second step
//       // Example:
//       // const response2 = await fetch('your-api-endpoint/second-step-login/', {
//       //   method: 'POST',
//       //   headers: {
//       //     'Content-Type': 'application/json',
//       //   },
//       //   body: JSON.stringify({ additionalData }),
//       // });

//       // Handle the response for the second step
//     } else {
//       const errorData = await response.json();
//       console.error(errorData.message);
//       setError('Invalid username or password');
//       // Handle error, show message, etc.
//     }
//   } catch (error) {
//     console.error('An error occurred during login:', error);
//     // Handle error, show message, etc.
//   }
// };

// // Example usage:
// login('yourUsername', 'yourPassword');

