import React from 'react';
import { IChildPanelInfoProps } from "../../../types/main";
import SongList from "./song-list/SongList";

export const MusicList: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
    
    return (
      <div>
        <SongList childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
      </div>
    );
  };
