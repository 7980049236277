import { v4 as uuidv4 } from  'uuid';

export interface IPostData {
  id: number;
  tags: number[];
  html: string;
  by: number;
  status: number;
  title: string;
  featured: boolean;
  data?: Record<string, any>;
  created?: string;
}

export interface IRequestOptions {
  method: string;
  headers: {
      'Content-Type': string;
  };
  body: string;
}

// errors{message: "Syntax Error: Expected ':', found String ':7,'.", locations: Array(1)}
export interface IDataReturn {
  success?: boolean;
  message?: string;
  posts?: IPostData[];
  data?: any[];
  errors?: any[];
  requestOptions?: IRequestOptions;
  variables?: any;
  listOfPostIds?: number[];
}

export interface IDataForAPI {
  postId?: number;
  userId?: number;
  title?: string;
  formData?: any;
  tagIds?: number[];
  status?: number;
  key?: CryptoKey;
  getForDate?: string;
  encryptData: boolean;
}

// Function to generate a random encryption key
export async function generateKey() {
  return await crypto.subtle.generateKey(
    { name: 'AES-GCM', length: 256 },
    true,
    ['encrypt', 'decrypt']
  );
}

// Function to generate a random Initialization Vector (IV)
function generateRandomIV(): Uint8Array {
  return crypto.getRandomValues(new Uint8Array(12)); // Use 12 bytes (96 bits) for the IV
}

// Function to encrypt a string
export async function encryptText(text: string, key: CryptoKey): Promise<string> {
  const encodedText = new TextEncoder().encode(text);
  const iv = generateRandomIV();
  const ciphertext = await encryptWithIV(encodedText, key, iv);

  // Encode IV and ciphertext as Base64 for easy storage
  const ivBase64 = arrayBufferToBase64(iv);
  const ciphertextBase64 = arrayBufferToBase64(ciphertext);

  return `${ivBase64}:${ciphertextBase64}`;
}

// Higher-level function for encryption with IV
async function encryptWithIV(data: Uint8Array, key: CryptoKey, iv: Uint8Array): Promise<ArrayBuffer> {
  // Include the IV in the encryption parameters
  const encryptionParams: AesGcmParams = {
    name: 'AES-GCM',
    iv: iv,
  };

  return await crypto.subtle.encrypt(encryptionParams, key, data);
}

// Helper function to convert ArrayBuffer to Base64
function arrayBufferToBase64(buffer: ArrayBuffer): string {
  const bytes = new Uint8Array(buffer);
  let binary = '';

  for (let i = 0; i < bytes.length; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return btoa(binary);
}

// Function to decrypt a combined Base64-encoded string back to the original string
async function decryptText(combinedBase64: string, key: CryptoKey): Promise<string | undefined> {
  try {
    // Decode Base64
    const [ivBase64, ciphertextBase64] = combinedBase64.split(':');
    const iv = new Uint8Array(atob(ivBase64).split('').map(c => c.charCodeAt(0)));
    const ciphertext = new Uint8Array(atob(ciphertextBase64).split('').map(c => c.charCodeAt(0)));

    // Decrypt using the extracted IV and ciphertext
    const decryptedArrayBuffer = await crypto.subtle.decrypt({ name: 'AES-GCM', iv: iv }, key, ciphertext);

    // Use TextDecoder to properly decode the decryptedArrayBuffer into text
    const decryptedText = new TextDecoder().decode(decryptedArrayBuffer);

    let parsedDecryptedText;
    try {
      parsedDecryptedText = JSON.parse(decryptedText)
    } catch (error) {
      //
      parsedDecryptedText = decryptedText
    }

    const decryptedTextCorrected = typeof parsedDecryptedText === 'object'? JSON.stringify(parsedDecryptedText, null, 2) : parsedDecryptedText

    // console.log('Decryption:', {
    //   combinedBase64,
    //   key,
    //   ivBase64,
    //   ciphertextBase64,
    //   iv,
    //   decryptedText: decryptedTextCorrected,
    //   decryptedArrayBuffer,
    //   decryptedTextType: typeof parsedDecryptedText,
    //   decryptedTextLength: parsedDecryptedText.length,
    //   decryptedTextHex: Array.from(new Uint8Array(decryptedArrayBuffer), byte => byte.toString(16).padStart(2, '0')).join(''),
    // });
    
    return parsedDecryptedText;
  } catch (error) {
    // console.error('Decryption Error:', error);
    return undefined;
    // throw error; // Re-throw the error to handle it at a higher level
  }
}

export const apiLink = 'https://exec.artificial.opalstacked.com';
export const graphqlLink = apiLink+'/graphql/';

export const handleDelete = async (dataForAPI:IDataForAPI): Promise<IDataReturn> => {
  const requestOptions: IRequestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
          mutation {
            deletePost(postId: ${dataForAPI.postId}) {
              success
            }
          }
        `,
    }),
  }
  try {
    // const deletePostId = posts[invoiceId].id;
    // Returns: {"data":{"deletePost":{"success":true}}}
    const response = await fetch(graphqlLink, requestOptions);
    const responseData = await response.json();
    if (responseData.errors) {
      return { success: false, errors: responseData.errors, requestOptions:requestOptions, variables:{deletePostId:dataForAPI.postId} };
    } else {
      const success = responseData.data?.deletePost?.success;
      return { success: success };
    }
  } catch (error: any) {
    console.error(`Error deleting post: ${error.message}`);
    return { success: false, errors: [error], requestOptions:requestOptions, variables:{deletePostId:dataForAPI.postId} };
  };
};
//title: string, formData: any, tagIds: number[]


export const handleAdd = async ( dataForAPI:IDataForAPI ): Promise<IDataReturn> => {
  let encryptedText = '';
  let encryptedTitle= '';
  let copiedData = {
    ...dataForAPI.formData,
    id: uuidv4(),
    // created: new Date(dataForAPI.formData.created).toLocaleString(),
    created: new Date().toLocaleString(),
    updated:  "",
    title: dataForAPI.title,
  };
  if (dataForAPI.key&&dataForAPI.encryptData) {
  //   if (true) {
  //     // encryptedText = JSON.stringify(copiedData);
  //     // encryptedTitle = dataForAPI.title as string;
  //   } else {
      encryptedText = await encryptText(JSON.stringify(copiedData), dataForAPI.key);
      encryptedTitle = await encryptText(dataForAPI.title as string, dataForAPI.key);
  //   }
  // } else {
  //   // updatedData = JSON.stringify(dataForAPI.formData);
  }
  const variables = {
    tags: dataForAPI.tagIds, //[1, 2],  // Replace with your tag IDs
    html: "",//JSON.stringify({ encryptedData: encryptedText }),
    by: dataForAPI.userId,       // Replace with the user ID
    status: dataForAPI.status,   // Replace with the status ID
    title: dataForAPI.encryptData?JSON.stringify({encryptedData: encryptedTitle}):dataForAPI.title, //`${formData.to} ${formData.date}`,
    featured: true,
    data: dataForAPI.encryptData?JSON.stringify({encryptedData: encryptedText}):JSON.stringify(copiedData),
    slug: "",
    url: ""
  };
  // Returns {"data":{"createPost":{"post":{"id":"49","title":"New Todo"}}}}
  const requestOptions: IRequestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
          mutation CreatePost($tags: [ID], $html: String!, $by: ID!, $status: ID!, $title: String!, $featured: Boolean!, $data: JSONString, $slug: String, $url: String) {
            createPost(tags: $tags, html: $html, by: $by, status: $status, title: $title, featured: $featured, data: $data, slug: $slug, url: $url) {
              post {
                id
                title
                # Add other fields you want to retrieve
              }
            }
          }
        `,
      variables,
    }),
  };
  try {
    // Make the request
    const response = await fetch(graphqlLink, requestOptions);
    const responseData = await response.json();
    // return { success: true, data: responseData.data.createPost.post };
    if (responseData.errors) {
      return { success: false, errors: responseData.errors};
    } else {
      return { success: true, data: responseData.data.createPost.post, requestOptions:requestOptions, variables:variables };
    }
  } catch (error) {
    return { success: false, errors: [error], requestOptions:requestOptions, variables:variables };
  }
};

export const handleUpdate = async (dataForAPI:IDataForAPI): Promise<IDataReturn> => {
  let copiedData = {
    ...dataForAPI.formData,
    updated: new Date().toLocaleString(),
    title: dataForAPI.title,
  };
  
  let encryptedText = '';
  let encryptedTitle= '';
  if (dataForAPI.key&&dataForAPI.encryptData) {
    encryptedText = await encryptText(JSON.stringify(copiedData), dataForAPI.key);
    encryptedTitle = await encryptText(dataForAPI.title as string, dataForAPI.key);
  // } else {
  //   // updatedData = JSON.stringify(dataForAPI.formData);
  }
  const variables = {
    postId: dataForAPI.postId,
    tags: dataForAPI.tagIds, //[1, 2],  // Replace with your tag IDs
    html: "",//JSON.stringify({ encryptedData: encryptedText }),
    by: dataForAPI.userId,       // Replace with the user ID
    status: dataForAPI.status,   // Replace with the status ID
    title: dataForAPI.encryptData?JSON.stringify({encryptedData: encryptedTitle}):dataForAPI.title, //`${formData.to} ${formData.date}`,
    featured: true,
    data: dataForAPI.encryptData?JSON.stringify({encryptedData: encryptedText}):JSON.stringify(copiedData),
    slug: "",
    url: ""
  };

  // console.log('variables-->',variables);

  const requestOptions: IRequestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
        mutation UpdatePost($postId: ID!, $tags: [ID], $html: String!, $by: ID!, $status: ID!, $title: String!, $featured: Boolean!, $data: JSONString, $slug: String, $url: String) {
          updatePost(postId: $postId, tags: $tags, html: $html, by: $by, status: $status, title: $title, featured: $featured, data: $data, slug: $slug, url: $url) {
            post {
              id
              title
              # Include other post fields as needed
            }
          }
        }
      `,
      variables: variables,
    }),
  };

  try {
    const response = await fetch(graphqlLink, requestOptions);
    const responseData = await response.json();
    if (responseData.errors) {
      return { success: false, errors: responseData.errors, requestOptions: requestOptions, variables: variables};
    } else {
      return { success: true };
    }
  } catch (error) {
    return { success: false, errors: [error], requestOptions:requestOptions, variables: variables };
  }
};

export const fetchPostsByTagsAndDate = async (dataForAPI: IDataForAPI): Promise<IDataReturn> => {
  const requestOptions: IRequestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
        query {
          getPosts(date: "${dataForAPI.getForDate}", tagIds: ${JSON.stringify(dataForAPI.tagIds)}, byId: ${JSON.stringify(dataForAPI.userId)}) {
            id
            title
            data
            created
            html
          }
        }
      `,
    }),
  };

  try {
    const response = await fetch(graphqlLink, requestOptions);
    const responseData = await response.json();
    const posts = responseData.data.getPosts as IPostData[];
    const listOfPostIds: number[] = [];
    const decryptedDataArray = await Promise.all(
      posts.map(async (item,index) => {
        // console.log('_____________item______________',item);
        if (typeof item.data === 'string') {
          listOfPostIds.push(item.id);
          const itemData = JSON.parse(item.data);
          // console.log('BEFORE', itemData);
          let decryptedData:any;

          if (itemData.encryptedData) {
            if (dataForAPI.key) {
              try {
                decryptedData = await decryptText(itemData.encryptedData, dataForAPI.key);
                // console.log('********> itemData.encryptedData (decrypted)', itemData, itemData.encryptedData, decryptedData);

                if (decryptedData===undefined) {
                  decryptedData = JSON.stringify(itemData.encryptedData);
                }
              } catch (error) {
                console.error('* Decryption error', error);
                // decryptedData = itemData;
              }
            }
          } else {
            decryptedData = itemData;
            // console.log('* itemData', itemData);
          }
          decryptedData = decryptedData as any;
          decryptedData.index = index;
          decryptedData.postId = item.id;
          // console.log('AFTER', decryptedData);
          return decryptedData;
        }

        return null; // or handle the case where data is not a string
      })
    );

    if (responseData.errors) {
      // console.log('1',decryptedDataArray);
      return { success: false, errors: responseData.errors, requestOptions, variables: { tagIds: dataForAPI.tagIds } };
    } else {
      // console.log('2',decryptedDataArray);
      return { success: true, posts, data: decryptedDataArray, listOfPostIds };
    }
  } catch (error) {
    // console.log('3');
    return { success: false, errors: [error], requestOptions, variables: { tagIds: dataForAPI.tagIds } };
  }
};

export const fetchPostsByTags = async (dataForAPI: IDataForAPI): Promise<IDataReturn> => {
  const requestOptions: IRequestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
        query {
          getPosts(tagIds: ${JSON.stringify(dataForAPI.tagIds)}, byId: ${JSON.stringify(dataForAPI.userId)}) {
            id
            title
            data
            created
            html
          }
        }
      `,
    }),
  };

  try {
    const response = await fetch(graphqlLink, requestOptions);
    const responseData = await response.json();
    const posts = responseData.data.getPosts as IPostData[];
    const listOfPostIds: number[] = [];
    const decryptedDataArray = await Promise.all(
      posts.map(async (item,index) => {
        // console.log('_____________item______________',item);
        if (typeof item.data === 'string') {
          listOfPostIds.push(item.id);
          const itemData = JSON.parse(item.data);
          // console.log('BEFORE', itemData);
          let decryptedData:any;

          if (itemData.encryptedData) {
            if (dataForAPI.key) {
              try {
                decryptedData = await decryptText(itemData.encryptedData, dataForAPI.key);
                // console.log('********> itemData.encryptedData (decrypted)', itemData, itemData.encryptedData, decryptedData);

                if (decryptedData===undefined) {
                  decryptedData = JSON.stringify(itemData.encryptedData);
                }
              } catch (error) {
                console.error('* Decryption error', error);
                // decryptedData = itemData;
              }
            }
          } else {
            decryptedData = itemData;
            // console.log('* itemData', itemData);
          }
          decryptedData = decryptedData as any;
          decryptedData.index = index;
          decryptedData.postId = item.id;
          // console.log('AFTER', decryptedData);
          return decryptedData;
        }

        return null; // or handle the case where data is not a string
      })
    );

    if (responseData.errors) {
      // console.log('1',decryptedDataArray);
      return { success: false, errors: responseData.errors, requestOptions, variables: { tagIds: dataForAPI.tagIds } };
    } else {
      // console.log('2',decryptedDataArray);
      return { success: true, posts, data: decryptedDataArray, listOfPostIds };
    }
  } catch (error) {
    // console.log('3');
    return { success: false, errors: [error], requestOptions, variables: { tagIds: dataForAPI.tagIds } };
  }
};

// // Function to decrypt a Base64-encoded string back to the original string
// async function decryptText(combinedBase64: string, key: CryptoKey): Promise<string> {
//   // Split combinedBase64 into IV and ciphertext parts
//   const [ivBase64, ciphertextBase64] = combinedBase64.split(':');
//   const iv = new Uint8Array(Array.from(atob(ivBase64), (c) => c.charCodeAt(0)));
//   const ciphertext = new Uint8Array(Array.from(atob(ciphertextBase64), (c) => c.charCodeAt(0)));

//   // Decrypt using the extracted IV and ciphertext
//   return decryptWithIV(ciphertext, key, iv);
// }



// Higher-level function for decryption with IV
async function decryptWithIV(ciphertext: Uint8Array, key: CryptoKey, iv: Uint8Array): Promise<string> {
  // Include the IV in the decryption parameters
  const decryptionParams: AesGcmParams = {
    name: 'AES-GCM',
    iv: iv,
  };

  const decryptedArrayBuffer = await crypto.subtle.decrypt(decryptionParams, key, ciphertext);
  const decryptedText = new TextDecoder().decode(decryptedArrayBuffer);
  return decryptedText;
}


// // Function to generate a random Initialization Vector (IV)
// function generateRandomIV(): Uint8Array {
//   return crypto.getRandomValues(new Uint8Array(12)); // Use 12 bytes (96 bits) for the IV
// }

// // Function to encrypt a string
// export async function encryptText(text: string, key: CryptoKey): Promise<ArrayBuffer> {
//   const encodedText = new TextEncoder().encode(text);
//   const iv = generateRandomIV();

//   // Include the IV in the encryption parameters
//   const encryptionParams: AesGcmParams = {
//     name: 'AES-GCM',
//     iv: iv,
//   };

//   return await crypto.subtle.encrypt(encryptionParams, key, encodedText);
// }

// // Function to decrypt an ArrayBuffer back to a string
// async function decryptText(ciphertext: ArrayBuffer, key: CryptoKey, iv: Uint8Array): Promise<string> {
//   // Include the IV in the decryption parameters
//   const decryptionParams: AesGcmParams = {
//     name: 'AES-GCM',
//     iv: iv,
//   };

//   const decryptedArrayBuffer = await crypto.subtle.decrypt(decryptionParams, key, ciphertext);
//   const decryptedText = new TextDecoder().decode(decryptedArrayBuffer);
//   return decryptedText;
// }


// // Function to encrypt a string
// export async function encryptText(text: string, key: CryptoKey): Promise<ArrayBuffer> {
//   const encodedText = new TextEncoder().encode(text);
//   return await crypto.subtle.encrypt({ name: 'AES-GCM' }, key, encodedText);
// }

// // Function to decrypt an ArrayBuffer back to a string
// async function decryptText(ciphertext: ArrayBuffer, key: CryptoKey): Promise<string> {
//   const decryptedArrayBuffer = await crypto.subtle.decrypt({ name: 'AES-GCM' }, key, ciphertext);
//   const decryptedText = new TextDecoder().decode(decryptedArrayBuffer);
//   return decryptedText;
// }








// // Example usage
// async function example() {
//   // Generate encryption key
//   const encryptionKey = await generateKey();

//   // String to encrypt
//   const originalText = 'This is a secret message.';

//   // Encrypt the string
//   const ciphertext = await encryptText(originalText, encryptionKey);

//   // Decrypt the string
//   const decryptedText = await decryptText(ciphertext, encryptionKey);

//   console.log('Original Text:', originalText);
//   console.log('Decrypted Text:', decryptedText);
// }

// example();









// useEffect(() => {
//   fetchPostsByTags();
// }, []);


  // mutation {
  //   createPost(
  //     tags: [1, 2]  # Replace with actual tag IDs
  //     html: "<p>Your HTML content here</p>"
  //     by: 1  # Replace with the actual user ID
  //     status: 1  # Replace with the actual status ID
  //     title: "Your Post Title"
  //     featured: true
  //     slug: "your-post-slug"  # Provide a non-null value for the "slug" field
  //     url: "your-post-url"  # Provide a value for the "url" field if needed
  //   ) {
  //     post {
  //       id
  //       title
  //       slug
  //       # Include other fields you want to retrieve
  //     }
  //   }
  // }


  

      // console.log('requestOptions', JSON.stringify({
      //   query: `
      //     mutation CreatePost($tags: [ID], $html: String!, $by: ID!, $status: ID!, $title: String!, $featured: Boolean!, $data: JSONString) {
      //       createPost(tags: $tags, html: $html, by: $by, status: $status, title: $title, featured: $featured, data: $data) {
      //         post {
      //           id
      //           title
      //           # Add other fields you want to retrieve
      //         }
      //       }
      //     }
      //   `,
      //   variables,
      // }));

// export const Invoice: React.FC = () => {
//   const currentDate = new Date();
//   const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
//   const formattedDate = new Intl.DateTimeFormat('en-US', options).format(currentDate);
//   const defaultFormData: IInvoice = {
//     from: 'Artificial Business LLC',
//     fromAddressLineOne: '',
//     fromAddressLineTwo: '',
//     to: '',
//     toAddressLineOne: '',
//     toAddressLineTwo: '',
//     logo: 'https://artificialbusiness.com/static/images/NewHome/abclogo.png',
//     number: 0,
//     date: formattedDate,
//     items: [],
//     notes: 'Thank you for your business!',
//     terms: 'Payment due in 30 days',
//     taxPercent: 0,
//     total: 0,
//   };
//   const [postData, setPostData] = useState<PostData>({
//     id: 0,
//     tags: [],
//     html: '',
//     by: 0, // user ID
//     status: 0, // status ID
//     title: '',
//     featured: false,
//     data: {},
//   });

  // const [selectedData, setSelectedData] = useState<IInvoice>(defaultFormData);
  // const [invoices, setInvoicesData] = useState<IInvoice[]>([]);
  // const [posts, setPostsData] = useState<PostData[]>([]);