import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import Stats from 'three/examples/jsm/libs/stats.module';

interface Props {
  height: number;
  width: number;
  data: { x: number; y: number; z: number }[];
}

export const ATest004: React.FC<Props> = ({ height, width, data }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const sceneRef = useRef<THREE.Scene | null>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const scene = new THREE.Scene();
    sceneRef.current = scene;

    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    camera.position.z = .5; // Increased the z position to back the camera up

    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(width, height);
    containerRef.current.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    const geometry = createPointCloudGeometry(data);
    const material = new THREE.PointsMaterial({ size: 0.01, color: 0xff0000 });
    const points = new THREE.Points(geometry, material);
    scene.add(points);

    const stats = new Stats();
    containerRef.current.appendChild(stats.dom);

    const animate = () => {
      requestAnimationFrame(animate);

      points.rotation.x += 0.01;
      points.rotation.y += 0.01;

      renderer.render(scene, camera);
      stats.update();
    };

    // animate();

    const onWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', onWindowResize);

    return () => {
      window.removeEventListener('resize', onWindowResize);
      if (rendererRef.current) {
        rendererRef.current.dispose();
        rendererRef.current = null;
      }
      if (containerRef.current) {
        containerRef.current.innerHTML = '';
      }
    };
  }, [data, height, width]);

  const createPointCloudGeometry = (data: { x: number; y: number; z: number }[]): THREE.BufferGeometry => {
    const geometry = new THREE.BufferGeometry();
    const vertices: number[] = []; // Explicitly declare the type of vertices
    data.forEach(point => {
      vertices.push(point.x, point.y, point.z);
    });
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    return geometry;
  };

  return <div ref={containerRef} />;
};
